import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const ValidationService = {
    list,
    show,
    save,
    remove
}

function list() {
    return Request('validations', 'GET')
        .then(ErrorHandler)
        .then(
            validations => {
                return validations;
            }
        );
}

function show(id) {
    return Request('validations/' + id, 'GET')
        .then(ErrorHandler)
        .then(
            validation => {
                return validation;
            }
        );
}

function remove(id) {
    return Request('validations/' + id, 'DELETE')
        .then(ErrorHandler)
        .then(
            deleted => {
                return deleted;
            }
        );
}

function save(data) {
    let method = "POST";
    let endpoint = "validations";
    if (data.id) {
        method = "PUT";
        endpoint += "/" + data.id;
    }

    const categories = [];
    if (data.categories) {
        data.categories.map((category) => {
            categories.push(category.id);
        })
        data.categories = categories;
    }

    return Request(endpoint, method, data)
        .then(ErrorHandler)
        .then(
            validation => {
                return validation;
            }
        )
}
import Leaflet from 'leaflet';
import moment from 'moment';
import {Link} from 'react-router-dom'
import {FloorItemActions} from "../../actions/FloorItemActions";

export const LeafletMap = {
    initMap,
    getMap,
    // createAnonymousMarker,
    icon,
    createMarkers,
    popup
}

var leafletMap = undefined;
var markersGroup = undefined;
var parentFunctions;
function initMap(options, functions) {
    parentFunctions = functions;
    if (options.url !== "" && !leafletMap) {
        console.log(-Math.abs(options.h)/2, -Math.abs(options.w)/2)
        leafletMap = new Leaflet.map('map', {
            minZoom: 4,
            maxZoom: 6,
            center: [-25, 32],
            zoom: 4,
            crs: Leaflet.CRS.Simple
        })

        leafletMap.zoomControl.setPosition("bottomright")

        var southWest = leafletMap.unproject([0, options.h], leafletMap.getMaxZoom()-1);
        var northEast = leafletMap.unproject([options.w, 0], leafletMap.getMaxZoom()-1);
        var bounds = new Leaflet.LatLngBounds(southWest, northEast);

        Leaflet.imageOverlay(options.url, bounds).addTo(leafletMap);
    }

    return leafletMap;
}

function getMap() {
    return leafletMap;
}

// function createAnonymousMarker(item) {
//     console.log(map.getCenter())
//     const marker = new Leaflet.Marker(
//         map.getCenter(),
//         {
//             icon: icon(item.category.image),
//             draggable: true
//         }
//     );
//     marker.on('dragend', function(e) {
//         console.log(e.target.getLatLng())
//     });
//     marker.addTo(map)
// }

function icon(floorItem) {
    const icon =  new Leaflet.Icon({
        iconUrl: "/img/items/icons/" + floorItem.item.category.image,
        className: 'status-' + floorItem.status
    });

    return icon;
}

function createMarkers(floorItems, functions, dispatch) {
    if (!markersGroup) {
        markersGroup = new Leaflet.LayerGroup();
    }

    markersGroup.eachLayer((layer) => {
        markersGroup.removeLayer(layer);
    })

    floorItems.map((floorItem) => {
        let marker = new Leaflet.Marker(
            {lat: floorItem.lat, lng: floorItem.lng},
            {
                icon: icon(floorItem),
                draggable: true,
            }
        )
        marker.addTo(markersGroup);

        marker.on('dragend', function(e) {
            const newPosition = e.target.getLatLng();
            floorItem = {
                ...floorItem,
                lat: newPosition.lat,
                lng: newPosition.lng
            }
            e.target.setLatLng(newPosition);
            functions.updatePosition(floorItem)
        });
        marker.bindPopup(popup(floorItem, functions, dispatch))
        // marker.on('click', function() {
        //     dispatch(FloorItemActions.show(floorItem.id))
        // });
    })
    console.log(markersGroup.getLayers())
    markersGroup.addTo(leafletMap);
}

function popup(floorItem, functions, dispatch) {
    const popup = new Leaflet.popup();
    const htmlNode = document.createElement('div');
    const content = `
        <div class="item-popup-container mb-2" style="min-width: 200px;">
            <div class="row">
                <div class="col-12">
                    <h5>${floorItem.item.name}</h5>
                    <div>Installato: ${moment(floorItem.installation_date).locale("it").format('MMM YYYY')}</div>
                    <div>Fabbricato: ${moment(floorItem.manufacturing_date).locale("it").format('MMM YYYY')}</div>
                    <div>Posizionamento: ${floorItem.position}</div>
                    <div>Status: ${floorItem.status}</div>
                </div>
            </div>
        </div>
    `;

    const modifyButton = document.createElement('button');
    modifyButton.classList.add('btn', 'btn-primary', 'btn-sm');
    modifyButton.onclick = () => {
        dispatch(FloorItemActions.show(floorItem.id));
    };
    modifyButton.innerText = 'Modifica';

    const deleteButton = document.createElement('button');
    deleteButton.classList.add('btn', 'btn-danger', 'btn-sm', 'float-right');
    deleteButton.onclick = () => {
        dispatch(FloorItemActions.show(floorItem.id));
    };
    deleteButton.innerText = 'Elimina'

    htmlNode.innerHTML = content;
    htmlNode.appendChild(modifyButton);
    htmlNode.appendChild(deleteButton);

    popup.setContent(htmlNode)

    return popup;
}
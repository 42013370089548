import React, { Component } from 'react';

class ModalBody extends Component {
    render() {
        return(
            <div className={"modal-like-body"}>
                { this.props.children}
            </div>
        );
    }
}

export default ModalBody;
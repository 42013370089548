export function User(state = {}, action) {
    switch(action.type) {
        case "USERS_LIST_REQUEST":
            return {
                loading: true
            }
        case "USERS_LIST_SUCCESS":
            return {
                loading: false,
                users: action.users
            }
        case "USERS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "USER_SAVE_REQUEST":
            return {
                loading: true
            }
        case "USER_SAVE_SUCCESS":
            return {
                loading: false,
                user: action.user,
                success: true
            }
        case "USER_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "USER_ITEM_REQUEST":
            return {
                loading: true
            }
        case "USER_ITEM_SUCCESS":
            return {
                loading: false,
                user: action.user
            }
        case "USER_ITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "USER_REMOVE_REQUEST":
            return {
                loading: true
            }
        case "USER_REMOVE_SUCCESS":
            return {
                loading: false,
                deletedUser: action.deleted.deleted
            }
        case "USER_REMOVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "USER_NEW":
            return {
                user: action.user,
                error: {}
            };
        default:
            return state
    }
}
import {ErrorHandler} from "../helpers/ErrorHandler";
import {Request} from "../helpers/Request";

export const ItemService = {
    list,
    save,
    subItems,
    show,
    remove
}

function save(data) {
    const subItems = [];
    const validations = [];
    if (data.sub_items) {
        data.sub_items.map((subitem) => {
            subItems.push(subitem.id)
        })
    }
    if (data.validations) {
        data.validations.map((validation) => {
            validations.push(validation.id)
        })
    }
    data.sub_items = subItems;
    data.validations = validations;
    return Request('items', 'POST', data)
        .then(ErrorHandler)
        .then(
            item => {
                return item;
            }
        )
}

function list() {
    return Request('items', 'GET')
        .then(ErrorHandler)
        .then(
            items => {
                return items;
            }
        );
}

function show(id) {
    return Request('items/' + id, 'GET')
        .then(ErrorHandler)
        .then(
            item => {
                return item;
            }
        );
}

function remove(id) {
    return Request('items/' + id, 'DELETE')
        .then(ErrorHandler)
        .then(
            item => {
                return item;
            }
        );
}

function subItems() {
    return Request('subitems', 'GET')
        .then(ErrorHandler)
        .then (
            subItems => {
                return subItems;
            }
        )
}
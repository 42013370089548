import React from 'react';
import { connect } from 'react-redux';
import FormAbstract from "../../../abstract/FormAbstract";
import {LocationActions} from "../../../actions/LocationActions";
import {QueryString} from "../../../helpers/QueryString";
import FloorItem from "../../customers/components/FloorItem";
import Intl from "../../../helpers/Intl";
import FormHeader from "../../../components/FormHeader/FormHeader";
import FloorItemForm from "../../customers/components/FloorItemForm";
import LocationItem from "../../customers/components/LocationItem";
import LocationItemForm from "../../customers/components/LocationItemForm";

class LocationStore extends FormAbstract {
    constructor(props) {
        super(props);

        this.state = {
            location: null,
            floors: [],
            showFloorForm: false
        };

        this.model = 'location';

        this.toggleFloorForm = this.toggleFloorForm.bind(this);
        this.toggleLocationForm = this.toggleLocationForm.bind(this);
        this.handleFloorId = this.handleFloorId.bind(this);

        this.functions = {
            toggleFloorForm: this.toggleFloorForm,
            handleFloorId: this.handleFloorId,
            toggleLocationForm: this.toggleLocationForm
        }
    }

    componentWillMount() {
        this.props.dispatch(LocationActions.item(QueryString.get('locationId')));
    }

    toggleFloorForm(e) {
        if (e) {
            e.preventDefault()
        }

        this.setState({
            showFloorForm: !this.state.showFloorForm
        })
    }

    toggleLocationForm(e) {
        if (e) {
            e.preventDefault()
        }

        this.setState({
            showLocationForm: !this.state.showLocationForm
        })
    }

    handleFloorId(floorId) {
        this.setState({
            editFloorId: floorId
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location) {
            this.setState({
                location: nextProps.location
            })
        }
    }

    render() {
        return(
            <div class={"customer-form-container"}>
                <FormHeader
                    title={this.state.location && this.state.location.name.length ? this.state.location.customer.name + " @ " + this.state.location.name : null }
                    subtitle={this.state.location && this.state.location.address ? this.state.location.address.formatted_address : ''}
                    redirectTo={"/dashboard"}
                />
                { this.state.showFloorForm ? <FloorItemForm functions={this.functions} floorId={this.state.editFloorId}/> : null }
                { this.state.showLocationForm ? <LocationItemForm functions={this.functions} locationId={QueryString.get('locationId')}/> : null }
                { this.state.location ? <LocationItem info={this.state.location} functions={this.functions} showFloors={true}/> : null }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { location } = state.Location;

    return {
        location
    }
}

export default connect(mapStateToProps)(LocationStore);


import React, { Component } from 'react';
import { connect } from 'react-redux';
import {CustomerActions} from "../../actions/CustomerActions";
import "./styles.scss";
import {Link} from "react-router-dom";
import {LocationActions} from "../../actions/LocationActions";
import Intl from "../../helpers/Intl";
import {QueryString} from "../../helpers/QueryString";
import PermissionButton from "../../components/PermissionButton/PermissionButton";
import {allow} from "../../helpers/Auth";

class CustomersMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            found: null
        };

        this.requestLocations = this.requestLocations.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
        this.newCustomer = this.newCustomer.bind(this);
        this.searchCustomers = this.searchCustomers.bind(this);
        this.removeCustomer = this.removeCustomer.bind(this);

        this.functions = {
            requestLocations: this.requestLocations,
            editCustomer: this.editCustomer,
            removeCustomer: this.removeCustomer
        }
    }

    componentWillMount() {
        this.props.dispatch(CustomerActions.list())
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customers) {
            this.setState({
                customers: nextProps.customers
            })
        }

        // if (nextProps.customer) {
        //     const customers = this.state.customers;
        //     this.state.customers.map((customer, index) => {
        //         if (customer.id === nextProps.customer) {
        //             customers[index] = nextProps.customer;
        //         }
        //     });
        //
        //     this.setState({
        //         customers: customers
        //     });
        // }
    }

    removeCustomer(customerId) {
        this.props.dispatch(CustomerActions.remove(customerId));
        this.state.customers.map((customer, index) => {
            if (customer.id === customerId) {
                delete(this.state.customers[index]);
            }
        })
    }

    requestLocations(customerId) {
        this.props.dispatch(LocationActions.list(customerId));
    }

    editCustomer(customerId, tab) {
        this.props.dispatch(CustomerActions.item(customerId))
    }

    newCustomer() {
        this.props.dispatch(CustomerActions.empty());
    }

    searchCustomers(e) {
        const { value } = e.target;
        let found = this.state.customers.filter((customer) => {
            return customer.name && (customer.name.toLowerCase().search(value.toLowerCase()) >= 0 || customer.address.formatted_address.toLowerCase().search(value.toLowerCase()) >=0);
        });

        this.setState({
            found: found
        })
    }

    render() {
        return (
            <div class="customers-menu">
                <div className={"customers-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <PermissionButton
                                type={"link"}
                                className={"btn btn-primary btn-sm"}
                                to={"/customers/add"}
                                roles={["SUPER_ADMIN"]}
                                onClick={this.newCustomer}
                            >
                                <i className={"far fa-plus mr-2"}/>
                                { Intl("Nuovo") }
                            </PermissionButton>
                        </div>
                        <div className={"col-7"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type ={"text"} className={"form-control form-control-sm"} placeholder={Intl("Cerca tra i clienti")} onChange={this.searchCustomers}/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Clienti") }</h4>
                        </div>
                    </div>
                </div>
                { (this.state.found && this.state.found.length) || (this.state.customers && this.state.customers.length) ?
                    <CustomerItems customers={this.state.found  ? this.state.found : this.state.customers} profile={this.props.profile} functions={this.functions}/>
                    :
                    <div className={"alert alert-warning flat"}>
                        <i className={"fal fa-info-circle mr-2"}/>
                        There are no customers
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { customers, customer } = state.Customer;
    const { profile } = state.Auth;

    return {
        customers,
        customer,
        profile
    }
}

export default connect(mapStateToProps)(CustomersMenu);

export class CustomerItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: this.props.customers,
            openDeleteConfirm: false
        }

        this.renderCustomers = this.renderCustomers.bind(this);
    }

    renderCustomers() {
        const customers = [];
        this.props.customers.map((customer, index) => {
            customers.push(
                <div className={"clearfix customer-item" + (QueryString.get('customerId') && customer.id === QueryString.get('customerId') ? ' active' : '')} key={index}>
                    <h5>{ customer.name }</h5>
                    {this.props.profile && allow(["SUPER_ADMIN"], this.props.profile.roles) ?
                        <Link to={"#"} onClick={
                            (e) => {
                                this.setState({
                                    showDeleteConfirm: customer.id
                                })
                            }} className={"float-right"}>
                            <i className={"fal fa-trash-alt"}/>
                        </Link>
                        : null
                    }
                    <Link to={"/customers/edit?customerId=" + customer.id} onClick={
                        (e) => {
                            this.props.functions.editCustomer(customer.id)
                        }} className={"float-right mr-2"}>
                        <i className={"fal fa-pencil-alt"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12 pl-0"}>
                            <small>{ customer.address ? customer.address.formatted_address : '' }</small>
                        </div>
                    </div>
                    <div className={"customer-details"}>
                        <span className={"mr-4"}>
                            <i className={"fal fa-building mr-2"}/>
                            <small>{ customer.locations.length }</small>
                        </span>
                        <span className={"mr-2"}>
                            <i className={"fal fa-bell mr-2"}/>
                            <small>{ customer.alarms.length }</small>
                        </span>
                    </div>
                    {this.state.showDeleteConfirm === customer.id && this.props.profile && allow(["SUPER_ADMIN"], this.props.profile.roles) ?
                        <div className={"delete-confirm mt-1"}>
                            <span className={"mr-3"}>{ Intl("Vuoi rimuovere questo cliente?") }</span>
                            <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                })
                            }}>No
                            </button>
                            <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                this.props.functions.removeCustomer(customer.id)
                            }}>Yes</button>
                        </div>
                        : null
                    }
                </div>
            );
        })

        return customers;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customer) {
            this.setState({
                customers: nextProps.customers
            })
        }
    }

    render() {
        return (
            <div className={"customers-menu-wrapper"}>
                { this.renderCustomers() }
            </div>
        );
    }
}
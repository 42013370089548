import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./styles.scss"
import {AuthActions} from "../../actions/AuthActions";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: "",
            email: "",
            password: ""
        };

        this.handleForm = this.handleForm.bind(this);
        this.login = this.login.bind(this);
    }

    render() {
        return (
            <div className={"container login-container"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-4 offset-md-4"}>
                        <div className={"login-wrapper"}>
                            <div className={"text-center mb-3"}>
                                <img src={"/img/neosigean-logo-letters-blue.svg"} height={40}/>
                            </div>
                            <form onSubmit={this.login}>
                                { this.props.error && this.props.error.error ? <div className={"alert alert-danger alert-sm"}>{ this.props.error.error }</div> : null }
                                <div className={"form-group"}>
                                    <label>Account</label>
                                    <input type={"text"} name={"account"} className={"form-control form-control-sm"} onChange={this.handleForm}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>E-mail</label>
                                    <input type={"text"} name={"email"} className={"form-control form-control-sm"} onChange={this.handleForm}/>
                                </div>
                                <div className={"form-group"}>
                                    <label>Password</label>
                                    <input type={"password"} name={"password"} className={"form-control form-control-sm"} onChange={this.handleForm}/>
                                </div>
                                <button type={"submit"} className={"btn btn-primary btn-block btn-sm"}>
                                    Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            this.props.dispatch(AuthActions.profile());
        }
    }

    login(e) {
        e.preventDefault();
        this.props.dispatch(AuthActions.login(this.state));
    }

    handleForm(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }
}

function mapStateToProps(state) {
    const { error, user } = state.Auth;

    return {
        error,
        user
    }
}

export default connect(mapStateToProps)(Login);
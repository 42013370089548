import {ErrorHandler} from "../helpers/ErrorHandler";
import {history} from "../helpers/History";
import {Request} from "../helpers/Request";

export const AuthService = {
    login,
    profile,
    logout,
    settings
};

function login(data) {
    return Request('auth/login', "POST", data)
        .then(ErrorHandler)
        .then(
            user => {
                localStorage.setItem('auth', JSON.stringify(user));
                localStorage.setItem('account', data.account.toLowerCase());
                history.push("/dashboard")
                return user;
            }
        );
}

function profile() {
    return Request('auth/me', "POST")
        .then(ErrorHandler)
        .then(
            user => {
                return user;
            }
        );
}

function logout() {
    return Request('auth/logout', "GET")
        .then(ErrorHandler)
        .then(
            loggedOut => {
                localStorage.removeItem('auth');
                localStorage.removeItem('account');
                history.push("/")
                return loggedOut;
            }
        );
}

function settings() {
    return Request('app/settings', "GET")
        .then(ErrorHandler)
        .then(
            settings => {
                return settings;
            }
        );
}
import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const UserService = {
    list,
    item,
    save,
    remove
}

function list(schedule) {
    return Request('users', 'GET', null, {schedule: schedule})
        .then(ErrorHandler)
        .then(
            users => {
                return users;
            }
        );
}

function item(id) {
    return Request('users/' + id, 'GET')
        .then(ErrorHandler)
        .then(
            user => {
                return user;
            }
        );
}

function remove(id) {
    return Request('users/' + id, 'DELETE')
        .then(ErrorHandler)
        .then(
            deleted => {
                return deleted;
            }
        );
}

function save(data) {
    let method = "POST";
    let endpoint = "users";
    if (data.id) {
        method = "PUT";
        endpoint += "/" + data.id;
    }
    const roles = [];
    if (data.roles) {
        data.roles.map((role) => {
            roles.push(role.id);
        })
        data.roles = roles;
    }

    return Request(endpoint, method, data)
        .then(ErrorHandler)
        .then(
            user => {
                return user;
            }
        );

}
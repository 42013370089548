export function Auth(state = {}, action) {
    switch(action.type) {
        case "AUTH_LOGIN_REQUEST":
            return {
                loading: true
            };
        case "AUTH_LOGIN_SUCCESS":
            return {
                loading: false,
                user: action.user
            };
        case "AUTH_LOGIN_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "AUTH_PROFILE_REQUEST":
            return {
                loading: true
            };
        case "AUTH_PROFILE_SUCCESS":
            return {
                loading: false,
                profile: action.user
            };
        case "AUTH_PROFILE_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
import React from 'react';
import FormAbstract from "../../../abstract/FormAbstract";
import { connect } from 'react-redux';
import moment from "moment";

class DocumentCard extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = 'document'

        this.toggleDeleteConfirm = this.toggleDeleteConfirm.bind(this)

        this.state = {
            document: this.props.document,
            showDeleteConfirm: false
        }

        this.extensionIcons = {
            pdf: 'fa-file-pdf',
            docx: 'fa-file-word'
        };
    }

    toggleDeleteConfirm(e) {
        e.preventDefault();
        this.setState({
            showDeleteConfirm: !this.state.showDeleteConfirm
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.document) {
            this.setState({
                document: nextProps.document
            })
        }
    }

    render() {
        return(
            <div className={"col-2"}>
                {this.state.document ?
                    <div className={"card"}>
                        <div className={"card-body text-center"}>
                            <span className={"d-block mb-2"}>{this.state.document.name}</span>
                            <a href={this.state.document.file_url} title={moment(this.state.document.modified_at).format('DD MMM YYYY')}>
                                <span className={"d-block mb-2"}>
                                    <i className={'fad fa-3x ' + this.extensionIcons[this.state.document.extension]}/>
                                </span>
                            </a>
                        </div>
                        <div className={"card-footer"}>
                            <a href={"#"} className={"mr-2 float-left"}>
                                <i className={"fal fa-download"}/>
                            </a>
                            <a href={"#"} className={"float-right"} onClick={this.toggleDeleteConfirm}>
                                <i className={"fal fa-trash-alt"}/>
                            </a>
                        </div>
                        {this.state.showDeleteConfirm ?
                            <div className={"delete-confirm text-center my-2 mx-2"}>
                                <span className={"d-block"} style={{ fontSize: '0.8rem'}}>Delete document?</span>
                                <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirm: false
                                    })
                                }}>No
                                </button>
                                <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                    this.props.functions.removeCustomer(this.state.document.id)
                                }}>Yes
                                </button>
                            </div>
                            : null
                        }
                    </div>
                    : null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

export default connect(mapStateToProps)(DocumentCard);
import React, { Component } from 'react';
import App from "../../App";
import FloorMenu from "./FloorMenu";
import FloorConfigure from "./scenes/FloorConfigure";
import FloorPrint from "./components/FloorPrint";

class Floor extends Component {
    constructor(props) {
        super(props);

        this.scenes = {
            configure: <FloorConfigure/>,
        }
    }

    componentWillMount() {

    }

    render() {
        return (
            <App menu={<FloorMenu/>}>
                { this.scenes[this.props.match.params.scene] }
            </App>
        );
    }
}

export default Floor;
export function Location(state = {}, action) {
    switch(action.type) {
        case "LOCATIONS_LIST_REQUEST":
            return {
                loading: true,
                customerId: action.customerId
            };
        case "LOCATIONS_LIST_SUCCESS":
            return {
                loading: false,
                locations: action.locations
            };
        case "LOCATIONS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "ALL_LOCATIONS_LIST_REQUEST":
            return {
                loading: true
            };
        case "ALL_LOCATIONS_LIST_SUCCESS":
            return {
                loading: false,
                locations: action.locations
            };
        case "ALL_LOCATIONS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "LOCATION_ITEM_REQUEST":
            return {
                loading: true,
                locationId: action.locationId
            };
        case "LOCATION_ITEM_SUCCESS":
            return {
                loading: false,
                location: action.location
            };
        case "LOCATION_ITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "LOCATION_SAVE_REQUEST":
            return {
                loading: true,
                data: action.data
            };
        case "LOCATION_SAVE_SUCCESS":
            return {
                loading: false,
                success: true,
                location: action.location
            };
        case "LOCATION_SAVE_FAILURE":
            return {
                loading: false,
                success: false,
                error: action.error
            };
        case "LOCATION_REMOVE_REQUEST":
            return {
                loading: true
            };
        case "LOCATION_REMOVE_SUCCESS":
            return {
                loading: false,
                deletedLocation: action.locationId,
            };
        case "LOCATION_REMOVE_FAILURE":
            return {
                loading: false,
                deletedLocation: false,
                error: action.error
            };
        default:
            return state;
    }
}
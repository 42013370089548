import React, { Component } from 'react';
import FormAbstract from "../../../abstract/FormAbstract";
import { connect } from 'react-redux';
import HoursOff from "./HoursOff";
import Availability from "./Availability";
import Dossier from "./Dossier";
import {HoursOffActions} from "../../../actions/HoursOffActions";
import {QueryString} from "../../../helpers/QueryString";

class EmployeeManager extends FormAbstract {
    constructor(props) {
        super(props);
        this.state = {
            daysOff: []
        }
    }

    componentWillMount() {
        this.props.dispatch(HoursOffActions.getByUser(QueryString.get('userId')))
    }

    render() {
        return(
            <div className={"employee-manager p-2"}>
                {/*<Availability/>*/}
                <HoursOff/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { hoursOff } = state.HoursOff;
    return {
        hoursOff
    }
}

export default connect(mapStateToProps)(EmployeeManager)
export function Validation(state = {}, action) {
    switch(action.type) {
        case "VALIDATION_NEW":
            return {
                validation: action.validation,
                error: {}
            };
        case "VALIDATIONS_LIST_REQUEST":
            return {
                loading: true
            }
        case "VALIDATIONS_LIST_SUCCESS":
            return {
                loading: false,
                validations: action.validations
            }
        case "VALIDATIONS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "VALIDATION_ITEM_REQUEST":
            return {
                loading: true
            }
        case "VALIDATION_ITEM_SUCCESS":
            return {
                loading: false,
                validation: action.validation
            }
        case "VALIDATION_ITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "VALIDATION_SAVE_REQUEST":
            return {
                loading: true
            }
        case "VALIDATION_SAVE_SUCCESS":
            return {
                loading: false,
                validation: action.validation,
                success: true
            }
        case "VALIDATION_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "VALIDATION_REMOVE_REQUEST":
            return {
                loading: true
            }
        case "VALIDATION_REMOVE_SUCCESS":
            return {
                loading: false,
                deleted: action.validation.deleted,
                success: true
            }
        case "VALIDATION_REMOVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormHeader from "../../../components/FormHeader/FormHeader";
import Intl from "../../../helpers/Intl";
import {QueryString} from "../../../helpers/QueryString";
import moment from "moment";

class Timeline extends Component {

    constructor(props) {
        super(props);

        this.renderUserOperationsBars = this.renderUserOperationsBars.bind(this);
    }

    renderUserOperationsBars() {
        const usersList = [];
        if (this.props.usersWithEvents) {
            this.props.usersWithEvents.map((user) => {
                usersList.push(<UserOperationsList user={user} key={user.id}/>)
            })
        }

        return usersList;
    }

    render() {
        return(
            <div className="calendar-container">
                <FormHeader title={Intl("Timeline")} redirectTo={"/operations"}/>
                { !QueryString.get('userId') ? this.renderUserOperationsBars() : null }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { usersWithEvents } = state.Operation;
    return {
        usersWithEvents
    }
}

export default connect(mapStateToProps)(Timeline);

export class UserOperationsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTimeline: null
        }

        this.renderBarsWithPercentage = this.renderBarsWithPercentage.bind(this);
        this.renderTimeLine = this.renderTimeLine.bind(this);
        this.toggleTimeline = this.toggleTimeline.bind(this);
    }

    toggleTimeline(calendarId) {
        this.setState({
            showTimeline: this.state.showTimeline === calendarId ? null : calendarId
        })
    }

    renderTimeLine(checkLogs) {
        const logItems = [];
        checkLogs.map((checkLog) => {
            logItems.push(
                <li>
                    <a target="_blank" href="https://www.totoprayogo.com/#">{ checkLog.check.validation.name} { checkLog.check.item.name }</a>
                    <a href="#" className="float-right">{ moment(checkLog.created_at).format('DD MMM YYYY H:m') }</a>
                    <p>{checkLog.notes}</p>
                </li>
            );
        });

        return logItems;
    }

    renderBarsWithPercentage() {
        const bars = [];
        this.props.user.calendars.map((calendar) => {
            console.log(this.state.showTimeline, calendar.id);
            const percentage = Math.floor((calendar.check_logs.length/calendar.location.alarms.length)*100);
            bars.push(
                <div className={"bar-wrapper"}>
                    <span>
                        {calendar.location.customer.name } @ { calendar.location.name }
                    </span>
                    <a href={"#"} className={"ml-2"} onClick={(e) => {
                        e.preventDefault();
                        this.toggleTimeline(calendar.id);
                    }}>
                        { this.state.showTimeline === calendar.id ? <i className={"fal fa-search-minus"}/> : <i className={"fal fa-search-plus"}/> }
                    </a>
                    <small className={"d-block"}>
                        { calendar.location.address.formatted_address }
                    </small>
                    <div className="progress mt-1">
                        <div className="progress-bar" role="progressbar" style={{ width: percentage+"%"}} aria-valuenow={percentage} aria-valuemin="0"
                             aria-valuemax="100">{ percentage ? percentage + "%" : null }</div>
                    </div>
                    { this.state.showTimeline === calendar.id ?
                        <ul className={"timeline"}>
                            { this.renderTimeLine(calendar.check_logs) }
                        </ul>
                        : null }
                </div>
            );
        });

        return bars;
    }

    render() {
        console.log(this.state);
        return (
            <div>
                <h4>{ this.props.user.fullName }</h4>
                { this.renderBarsWithPercentage() }
            </div>
        );
    }
}
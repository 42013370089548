import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FloorItemActions} from "../../../actions/FloorItemActions";
import {QueryString} from "../../../helpers/QueryString";
import {FloorActions} from "../../../actions/FloorActions";
import QRCode from "qrcode.react";

class FloorPrint extends Component {
    constructor(props) {
        super(props);

        this.state = {
            floorItems: null,
            floor: null
        }

        this.renderFloorItems = this.renderFloorItems.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(FloorActions.show(QueryString.get('floorId')));
        this.props.dispatch(FloorItemActions.list(QueryString.get('floorId')));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.floorItems) {
            this.setState({
                floorItems: nextProps.floorItems
            })
        }

        if (nextProps.floor) {
            this.setState({
                floor: nextProps.floor
            })
        }
    }

    renderFloorItems() {
        const floorItems = [];
        if(this.state.floorItems) {
            this.state.floorItems.map((floorItem) => {
                floorItems.push(<FloorItemPrint floorItem={floorItem} key={floorItem.id}/>)
            });
        }

        return floorItems;
    }

    render() {
        return(
            <div className={"floor-print container-fluid"}>
                {this.state.floor ?
                    <React.Fragment>
                        <h1>{this.state.floor.location.name} @ { this.state.floor.location.customer.name }</h1>
                        <h3>{this.state.floor.location.address.formatted_address }</h3>
                        <div>{this.renderFloorItems()}</div>
                    </React.Fragment>
                    : null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { floorItems } = state.FloorItem;
    const { floor } = state.Floor;

    return {
        floor,
        floorItems
    }
}

export default connect(mapStateToProps)(FloorPrint);

export class FloorItemPrint extends Component {
    render() {
        return(
            <React.Fragment>
                <div className={"row mt-5"}>
                    <div className={"col-8"}>
                        <span className={"d-block"}>
                            <img src={"/img/items/icons/" + this.props.floorItem.item.category.image } className={"mr-2"}/>
                            { this.props.floorItem.item.name } @ { this.props.floorItem.position }
                        </span>
                    </div>
                    <div className={"col-4"}>
                        <QRCode value={this.props.floorItem.id} size={64}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
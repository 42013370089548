export function Documents(state = {}, action) {
    switch(action.type) {
        case "DOCUMENTS_LIST_REQUEST":
            return {
                loading: true
            }
        case "DOCUMENTS_LIST_SUCCESS":
            return {
                loading: false,
                documents: action.documents
            }
        case "DOCUMENTS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "DOCUMENT_SAVE_REQUEST":
            return {
                loading: true
            }
        case "DOCUMENT_SAVE_SUCCESS":
            return {
                loading: false,
                documentItem: action.documentItem
            }
        case "DOCUMENT_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}
import {OperationService} from "../services/OperationService";
import {history} from "../helpers/History";

export const OperationActions = {
    addLocationToPlanner,
    save,
    events,
    routes,
    eventsGroupedByUser,
    removeByLocation
}

function addLocationToPlanner(location) {
    return dispatch => {
        dispatch(
            success(location)
        )
        history.push('/operations/add')
    }

    function success(location) { return {type: "ADD_LOCATION_TO_PLANNER", location} }
}

function routes(routes) {
    console.log('ROUTES', routes);
    return dispatch => {
        dispatch(success(routes));
    };
    function success(routes) { return {type: "OPERATION_ROUTES", routes} }
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        OperationService.save(data)
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(failure(error))
                }
            )
    };

    function request(data) { return { type: "OPERATION_SAVE_REQUEST", data} };
    function success(events) { return {type: "OPERATION_SAVE_SUCCESS", events } };
    function failure(error) { return {type: "OPERATION_SAVE_FAILURE", error } };
}

function events(data) {
    return dispatch => {
        dispatch(request(data));
        OperationService.events(data)
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request(data) { return { type: "OPERATION_EVENTS_REQUEST", data} };
    function success(events) { return {type: "OPERATION_EVENTS_SUCCESS", events } };
    function failure(error) { return {type: "OPERATION_EVENTS_FAILURE", error } };
}

function eventsGroupedByUser(data) {
    return dispatch => {
        dispatch(request(data));
        OperationService.eventsGroupedByUser(data)
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request(data) { return { type: "OPERATION_EVENTS_GROUPED_REQUEST", data} };
    function success(events) { return {type: "OPERATION_EVENTS_GROUPED_SUCCESS", events } };
    function failure(error) { return {type: "OPERATION_EVENTS_GROUPED_FAILURE", error } };
}

function removeByLocation(location) {
    return dispatch => {
        dispatch(request())
        OperationService.removeByLocation(location)
            .then(
                deletedOperation => {
                    dispatch(success(deletedOperation))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request(data) { return { type: "OPERATION_REMOVE_REQUEST", data} };
    function success(deletedOperation) { return {type: "OPERATION_REMOVE_SUCCESS", deletedOperation } };
    function failure(error) { return {type: "OPERATION_REMOVE_FAILURE", error } };
}


import {LocationService} from "../services/LocationService";
import {history} from "../helpers/History";
import {FloorService} from "../services/FloorService";

export const LocationActions = {
    all,
    list,
    save,
    item,
    remove
};

function all() {
    return dispatch => {
        dispatch(request());
        LocationService.all()
            .then(
                locations => {
                    dispatch(success(locations));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: "ALL_LOCATIONS_LIST_REQUEST" }; }
    function success(locations) { return { type: "ALL_LOCATIONS_LIST_SUCCESS", locations }; }
    function failure(error) { return { type: "ALL_LOCATIONS_LIST_FAILURE", error }; }
}

function list(customerId) {
    return dispatch => {
        dispatch(request(customerId));
        LocationService.list(customerId)
            .then(
                locations => {
                    dispatch(success(locations));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(customerId) { return { type: "LOCATIONS_LIST_REQUEST", customerId }; }
    function success(locations) { return { type: "LOCATIONS_LIST_SUCCESS", locations }; }
    function failure(error) { return { type: "LOCATIONS_LIST_FAILURE", error }; }
}

function item(locationId) {
    return dispatch => {
        dispatch(request(locationId));
        LocationService.item(locationId)
            .then(
                location => {
                    dispatch(success(location));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(locationId) { return { type: "LOCATION_ITEM_REQUEST", locationId }; }
    function success(location) { return { type: "LOCATION_ITEM_SUCCESS", location }; }
    function failure(error) { return { type: "LOCATION_ITEM_FAILURE", error }; }
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        LocationService.save(data)
            .then(
                location => {
                    dispatch(success(location));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    }

    function request(data) { return { type: "LOCATION_SAVE_REQUEST", data }; }
    function success(location) { return { type: "LOCATION_SAVE_SUCCESS", location }; }
    function failure(error) { return { type: "LOCATION_SAVE_FAILURE", error }; }
}

function remove(locationId) {
    return dispatch => {
        dispatch(request())
        LocationService.remove(locationId)
            .then(
                deleted => {
                    dispatch(success(locationId))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request(data) { return { type: "LOCATION_REMOVE_REQUEST", data }; }
    function success(locationId) { return { type: "LOCATION_REMOVE_SUCCESS", locationId }; }
    function failure(error) { return { type: "LOCATION_REMOVE_FAILURE", error }; }
}
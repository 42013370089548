import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ItemActions} from "../../../actions/ItemActions";
import {CategoryActions} from "../../../actions/CategoryActions";
import Select from "react-select";
import {QueryString} from "../../../helpers/QueryString";
import Link from "react-router-dom/es/Link";
import Input from "../../../components/Input/Input";
import FormAbstract from "../../../abstract/FormAbstract";
import FormHeader from "../../../components/FormHeader/FormHeader";
import {ValidationActions} from "../../../actions/ValidationActions";
import Intl from "../../../helpers/Intl";

class ItemsStore extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "item";

        this.state = {
            categories: [],
            subItems: [],
            validations: [],
            item: {
                name: "",
                code: "",
                category_id: "",
                price: "",
                sub_items: [],
                validations: [],
                checks: [],
                hours_replacement: "",
                minutes_replacement: "",
                hours_maintainance: "",
                minutes_maintainance: "",
                description: ""
            }
        };
    }

    componentWillMount() {
        if (QueryString.get('itemId')) {
            this.props.dispatch(ItemActions.show(QueryString.get('itemId')));
        }
        this.props.dispatch(ValidationActions.list());
        this.props.dispatch(CategoryActions.list());
        this.props.dispatch(ItemActions.subItems());
    }

    render() {
        console.log(this.state)
        return(
            <div className={"item-form-container"}>
                <FormHeader title={this.state.item.id ? this.state.item.name : Intl("Nuovo apparato") } redirectTo={"/items"}/>
                <form onSubmit={this.sendForm}>
                    <div className={"row form-group"}>
                        <div className={"col-3"}>
                            <Input type={"text"} name={"name"} value={this.state.item.name} label={Intl("Nome articolo")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-3"}>
                            <Input type={"text"} name={"code"} value={this.state.item.code} label={Intl("Codice")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-3"}>
                            <label>{ Intl("Tipo") }</label>
                            <Select
                                options={this.state.categories}
                                value={this.state.item.category}
                                isSearchable={true}
                                isMulti={false}
                                getOptionLabel={option => <span><img src={"/img/items/icons/" + option.image} height={22}/> <span className={"pt-5"}>{option.name}</span></span>}
                                getOptionValue={option => option.id}
                                onChange={(item) => {
                                    this.handleSelect(item, 'category_id')
                                }}
                            />
                        </div>
                        <div className={"col-3"}>
                            <Input type={"text"} name={"price"} value={this.state.item.price} label={Intl("Prezzo")}
                                   error={this.state.error} onChange={this.handleForm} prepend prependText={"€"}/>
                        </div>
                    </div>
                    <div className={"row form-group"}>
                        <div className={"col-6"}>
                            <label>{ Intl("Parti") }</label>
                            <Select
                                options={this.state.subItems}
                                value={this.state.item.sub_items}
                                isSearchable={true}
                                isMulti={true}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                onChange={(items) => {
                                    this.handleMultipleSelect(items, 'sub_items')
                                }}
                            />
                            { this.state.error && this.state.error['subitem_id'] ? <p className={"error"}>{this.state.error['subitem_id']}</p> : "" }
                        </div>
                        <div className={"col-6"}>
                            <label>{ Intl("Controlli") }</label>
                            <Select
                                options={this.state.validations}
                                value={this.state.item.validations}
                                isSearchable={true}
                                name={"check_id"}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                isMulti={true}
                                onChange={(items) => {
                                    this.handleMultipleSelect(items, 'validations')
                                }}
                            />
                            { this.state.error && this.state.error['validation_id'] ? <p className={"error"}>{this.state.error['validation_id']}</p> : "" }
                        </div>
                    </div>
                    <div className={"row form-group"}>
                        <div className={"col-6"}>
                            <label>{ Intl("Tempo di sostituzine") }</label>
                            <div className={"input-group"}>
                                <div className={"input-group-prepend"}>
                                    <span className={"input-group-text"}>
                                        <i className={"fal fa-clock"}/>
                                    </span>
                                </div>
                                <input type={"number"} className={"form-control"} placeholder={Intl("Ore")} name={"hours_replacement"} value={this.state.item.hours_replacement} onChange={this.handleForm}/>
                                <input type={"number"} className={"form-control"} placeholder={Intl("Minuti")} name={"minutes_replacement"} value={this.state.item.minutes_replacement} onChange={this.handleForm}/>
                                { this.state.error && this.state.error['hours_replacement'] ? <p className={"error"}>{this.state.error['hours_replacement']}</p> : "" }
                            </div>
                        </div>
                        <div className={"col-6"}>
                            <label>{ Intl("Tempo di manutenzione") }</label>
                            <div className={"input-group"}>
                                <div className={"input-group-prepend"}>
                                    <span className={"input-group-text"}>
                                        <i className={"fal fa-clock"}/>
                                    </span>
                                </div>
                                <input type={"number"} className={"form-control"} placeholder={Intl("Ore")} name={"hours_maintainance"} defaultValue={this.state.item.hours_maintainance} onChange={this.handleForm}/>
                                <input type={"number"} className={"form-control"} placeholder={Intl("Minuti")} name={"minutes_maintainance"} defaultValue={this.state.item.minutes_maintainance} onChange={this.handleForm}/>
                                { this.state.error && this.state.error['hours_maintainance'] ? <p className={"error"}>{this.state.error['hours_maintainance']}</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <label>{ Intl("Descrizione") }</label>
                        <textarea name={"description"} className={"form-control"} rows={5} value={this.state.item.description} onChange={this.handleForm}/>
                    </div>
                    <div className={"form-group"}>
                        <Link to={"/items"} className={"btn btn-secondary btn-flat"}>
                            <i className={"fal fa-arrow-left mr-2"}/>
                            { Intl("Annulla") }
                        </Link>
                        <button className={"btn btn-primary btn-flat float-right"}>
                            <i className={"fal fa-check mr-2"}/>
                            { Intl("Salva") }
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.categories) {
            this.setState({
                categories: nextProps.categories
            })
        }

        if (nextProps.validations) {
            this.setState({
                validations: nextProps.validations
            })
        }

        if (nextProps.error) {
            if (nextProps.error instanceof Promise) {
                nextProps.error.then(
                    errorMessages => {
                        this.setState({
                            error: errorMessages
                        })
                    }
                )
            } else {
                this.setState({
                    error: nextProps.error
                })
            }
        }

        if (nextProps.subItems) {
            const subItems = [];
            nextProps.subItems.map((subItem) => {
                subItems.push({
                    id: subItem.id,
                    name: subItem.name
                })
            });
            this.setState({
                subItems: subItems
            })
        }

        if (nextProps.item) {
            const item = nextProps.item;
            item.hours_replacement = Math.floor(item.replacement_time/60);
            item.minutes_replacement = item.replacement_time%60;
            item.hours_maintainance = Math.floor(item.maintainance_time/60);
            item.minutes_maintainance = item.maintainance_time%60;
            this.setState({
                item: item
            })
        }

        if (typeof nextProps.success !== "undefined") {
            this.props.dispatch(ItemActions.list());
        }
    }
}

function mapStateToProps(state) {
    const { item, subItems, error, success } = state.Item;
    const { validations } = state.Validation;
    const { categories } = state.Category;

    return {
        item,
        subItems,
        validations,
        categories,
        error,
        success
    }
}

export default connect(mapStateToProps)(ItemsStore);
import React, { Component } from 'react';
import { styles } from './styles.scss'
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import {UserActions} from "../../actions/UserActions";
import {QueryString} from "../../helpers/QueryString";
import Intl from '../../helpers/Intl'

class UserMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            found: []
        }

        this.editUser = this.editUser.bind(this);
        this.removeUser = this.removeUser.bind(this)
        this.newUser = this.newUser.bind(this);
        this.searchUsers = this.searchUsers.bind(this);

        this.functions = {
            editUser: this.editUser,
            removeUser: this.removeUser
        }
    }

    searchUsers(e) {
        const { value } = e.target;
        let found = this.state.users.filter((user) => {
            return user.fullName && ( user.fullName.toLowerCase().search(value.toLowerCase()) >= 0);
        });

        this.setState({
            found: found
        })
    }

    componentWillMount() {
        this.props.dispatch(UserActions.list());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            this.setState({
                users: nextProps.users
            })
        }

        if (nextProps.deletedUser === true) {
            this.props.dispatch(UserActions.list())
        }
    }

    editUser(userId) {
        this.props.dispatch(UserActions.item(userId))
    }

    removeUser(userId) {
        this.props.dispatch(UserActions.remove(userId));
    }

    newUser(e) {
        this.props.dispatch(UserActions.empty());
    }

    render() {
        return(
            <div className="users-menu">
                <div className={"users-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <Link to={"/users/add"} className={"btn btn-primary btn-sm"} onClick={this.newUser}>
                                <i className={"far fa-plus mr-2"}/>
                                { Intl("Nuovo") }
                            </Link>
                        </div>
                        <div className={"col-7"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type={"text"} className={"form-control form-control-sm"} onChange={this.searchUsers}
                                           placeholder={ Intl("Cerca...") }/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Risorse umane") }</h4>
                        </div>
                    </div>
                </div>
                <UserItems users={this.state.found.length ? this.state.found : this.state.users} profile={this.props.profile} functions={this.functions}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, deletedUser } = state.User;
    const { profile } = state.Auth;

    return {
        users,
        deletedUser,
        profile
    }
}

export default connect(mapStateToProps)(UserMenu);

export class UserItems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: this.props.users,
            showDeleteConfirm: null
        }

        this.renderRoles = this.renderRoles.bind(this);
    }

    renderRoles(user) {
        const roles = [];
        if (user) {
            user.roles.map((role) => {
                roles.push(<small key={user.id + role.id} className={"badge badge-primary py-1 px-2 mr-2"}>{ Intl(role.name) }</small>);
            });
        }
        return roles;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            this.setState({
                users: nextProps.users
            })
        }
    }

    renderUsers() {
        const users = [];
        this.state.users.map((user, index) => {
            users.push(
                <div className={"user-item" + (QueryString.get('userId') === user.id ? ' active' : '')} key={index}>
                    <h5>
                        <i className={"fas fa-square mr-2"} style={{ color: user.color }}/>
                        { user.fullName } { this.props.profile && user.id === this.props.profile.id ? <small>({Intl("Tu")})</small> : null }
                    </h5>
                    <Link to={"/users/edit?userId=" + user.id} onClick={
                        () => {
                            this.setState({
                                showDeleteConfirm: user.id
                            })
                        }} className={"float-right"}>
                        <i className={"far fa-trash-alt"}/>
                    </Link>
                    <Link to={"/users/edit?userId=" + user.id} onClick={
                        (e) => {
                            this.props.functions.editUser(user.id)
                        }} className={"float-right mr-2"}>
                        <i className={"far fa-pencil-alt"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            { this.renderRoles(user) }
                        </div>
                    </div>
                    <div className={"user-details"}>
                        <span class="mr-4">
                            <i className={'fal fa-mobile-android-alt mr-2'}/>
                            <small>{ user.mobile }</small>
                        </span>
                        <span className="mr-4">
                            <i className={'fal fa-envelope mr-2'}/>
                            <small>{user.email}</small>
                        </span>
                    </div>
                    {this.state.showDeleteConfirm === user.id ?
                        <div className={"delete-confirm mt-1"}>
                            <span className={"mr-3"}>Do you want to delete this user?</span>
                            <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                })
                            }}>No
                            </button>
                            <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                this.props.functions.removeUser(user.id)
                            }}>Yes</button>
                        </div>
                        : null
                    }
                </div>
            );
        })

        return users;
    }

    render() {
        return (
            <div class="users-menu-wrapper">
                { this.renderUsers() }
            </div>
        );
    }
}
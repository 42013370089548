import {ErrorHandler} from "../helpers/ErrorHandler";
import {Request} from "../helpers/Request";

export const CustomerService = {
    list,
    save,
    item,
    remove
};

function list() {
    return Request('customer', "GET")
        .then(ErrorHandler)
        .then(
            customers => {
                return customers;
            }
        );
}

function item(id) {
    const endpoint = 'customer/'+id;

    return Request(endpoint, "GET")
        .then(ErrorHandler)
        .then(
            customer => {
                return customer;
            }
        );
}

function remove(id) {
    const endpoint = 'customer/'+id;

    return Request(endpoint, "DELETE")
        .then(ErrorHandler)
        .then(
            customer => {
                return customer;
            }
        );
}

function save(data) {
    let method = "POST";
    let endpoint = "customer";
    if (data.id) {
        method = "PUT";
        endpoint += "/" + data.id;
    }

    console.log(JSON.stringify(data));

    return Request(endpoint, method, data)
        .then(ErrorHandler)
        .then(
            customer => {
                return customer;
            }
        );

}
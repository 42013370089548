import React, { Component } from 'react';
import {Link} from "react-router-dom";

class SubitemsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subitems: [],
            showDeleteConfirm: false
        }

        this.renderItems = this.renderItems.bind(this);
    }

    renderItems() {
        const items = [];
        this.state.subitems.map((subitem, index) => {
            items.push(
                <div className={"item-item"} key={index}>
                    <h5>
                        { subitem.name }
                    </h5>
                    <Link to={"#"} onClick={
                        (e) => {
                            e.preventDefault();
                            this.setState({
                                showDeleteConfirm: subitem.id
                            })
                        }} className={"float-right"}>
                        <i className={"far fa-trash-alt"}/>
                    </Link>
                    <Link to={"/subitems/edit?subitemId=" + subitem.id} onClick={
                        (e) => {
                            this.props.functions.editItem(subitem.id)
                        }} className={"float-right mr-2"}>
                        <i className={"far fa-pencil-alt"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <small>{ subitem.description }</small>
                        </div>
                    </div>
                    <div className={"item-details"}>
                        <span className={"mr-4"}>
                            € <small>{ subitem.price }</small>
                        </span>
                    </div>
                    {this.state.showDeleteConfirm === subitem.id ?
                        <div className={"delete-confirm mt-1"}>
                            <span className={"mr-3"}>Do you want to delete this item?</span>
                            <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                })
                            }}>No
                            </button>
                            <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                this.props.functions.deleteItem(subitem.id)
                            }}>Yes</button>
                        </div>
                        : null
                    }
                </div>
            );
        })

        return items;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.subitems && nextProps.subitems.length) {
            this.setState({
                subitems: nextProps.subitems
            })
        }
    }

    render() {
        console.log(this.state)
        return (
            <div className={"subitems-menu-wrapper"}>
                { this.state.subitems && this.state.subitems.length ?
                    this.renderItems()
                    :
                    <div className={"alert alert-warning flat"}>
                        <i className={"fal fa-info-circle mr-2"}/>
                        There are no parts yet
                    </div>
                }
            </div>
        );
    }
}

export default SubitemsList;
export function Floor(state = {}, action) {
    switch(action.type) {
        case "FLOORS_LIST_REQUEST":
            return {
                loading: true
            };
        case "FLOORS_LIST_SUCCESS":
            return {
                loading: false,
                locationId: action.data.locationId,
                floors: action.data.floors
            };
        case "FLOORS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "FLOOR_ITEM_REQUEST":
            return {
                loading: true
            };
        case "FLOOR_ITEM_SUCCESS":
            return {
                loading: false,
                floor: action.floorItem
            };
        case "FLOOR_ITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "FLOOR_SAVE_REQUEST":
            return {
                loading: true
            };
        case "FLOOR_SAVE_SUCCESS":
            return {
                loading: false,
                success: true,
                floor: action.floor
            };
        case "FLOOR_SAVE_FAILURE":
            return {
                loading: false,
                success: false,
                error: action.error
            };
        case "FLOOR_REMOVE_REQUEST":
            return {
                loading: true
            };
        case "FLOOR_REMOVE_SUCCESS":
            return {
                loading: false,
                deletedFloor: action.floorId,
            };
        case "FLOOR_REMOVE_FAILURE":
            return {
                loading: false,
                deletedFloor: false,
                error: action.error
            };
        default:
            return state;
    }
}
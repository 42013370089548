export function Subitem(state = {}, action) {
    switch(action.type) {
        case "SUBITEM_SAVE_REQUEST":
            return {
                loading: true
            };
        case "SUBITEM_SAVE_SUCCESS":
            return {
                loading: false,
                subitem: action.subitem,
                success: true
            };
        case "SUBITEM_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "SUBITEMS_LIST_REQUEST":
            return {
                loading: true
            };
        case "SUBITEMS_LIST_SUCCESS":
            return {
                loading: false,
                subitems: action.subitems
            };
        case "SUBITEMS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "SUBITEM_REQUEST":
            return {
                loading: true
            };
        case "SUBITEM_SUCCESS":
            return {
                loading: false,
                subitem: action.subitem
            };
        case "SUBITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "SUBITEM_REMOVE_REQUEST":
            return {
                loading: true
            };
        case "SUBITEM_REMOVE_SUCCESS":
            return {
                loading: false,
                removedSubitem: action.subitem.removed,
                success: true
            };
        case "SUBITEM_REMOVE_FAILURE":
            return {
                loading: false,
                error: action.error,
                success: false
            };
        default:
            return state;
    }
}
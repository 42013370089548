import React, { Component } from 'react';
import App from "../../App";
import AdminMenu from "./AdminMenu";

class Admin extends Component {
    constructor(props) {
        super(props);

        this.scenes = {
            users: {
                add: null
            }
        };
    }

    render() {
        return(
            <App menu={<AdminMenu/>}>
                { this.scenes[this.props.match.params.scene] }
            </App>
        );
    }
}

export default Admin;
import React, { Component } from 'react';
import App from "../../App";
import ItemsMenu from "./ItemsMenu";
import ItemsStore from "./scenes/ItemsStore";
import SubitemStore from "./scenes/SubitemStore";

class Items extends Component {

    constructor(props) {
        super(props);
        console.log(this.props)
        this.scenes = {
            items: {
                add: <ItemsStore item={undefined}/>,
                edit: <ItemsStore/>
            },
            subitems: {
                add: <SubitemStore subitem={undefined}/>,
                edit: <SubitemStore/>
            }
        }

        this.module = this.props.location.pathname.split("/")[1]
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.module = nextProps.location.pathname.split("/")[1];
    }

    render() {
        return (
            <App menu={<ItemsMenu tab={this.module}/>}>
                { this.scenes[this.module][this.props.match.params.scene] }
            </App>
        );
    }
}

export default Items;
export function Role(state = {}, action) {
    switch(action.type) {
        case "ROLES_LIST_REQUEST":
            return {
                loading: true
            }
        case "ROLES_LIST_SUCCESS":
            return {
                loading: false,
                roles: action.roles
            }
        case "ROLES_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "ROLE_SAVE_REQUEST":
            return {
                loading: true
            }
        case "ROLE_SAVE_SUCCESS":
            return {
                loading: false,
                role: action.role
            }
        case "ROLE_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "ROLE_ITEM_REQUEST":
            return {
                loading: true
            }
        case "ROLE_ITEM_SUCCESS":
            return {
                loading: false,
                role: action.role
            }
        case "ROLE_ITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
import {AuthService} from "../services/AuthService";

export const AppActions = {
    goTo,
    toggleLocationForm,
    settings
};

function goTo(menuItem, referrer) {
    let query = "";
    if (menuItem.params) {
        query = "?";
        Object.keys(menuItem.params).map((paramKey, index) => {
            query += paramKey + "=" + menuItem.params[paramKey];
            if (index !== menuItem.params.length) {
                query += "&";
            }
        });
    }
    return dispatch => {
        dispatch(load({menuItem, referrer}));
        window.history.pushState("","", "/" + menuItem.page + query);
    }

    function load(navInfo) {
        return {
            type: "APP_NAVIGATION",
            navInfo
        };
    }
}

function toggleLocationForm(locationId) {
    return dispatch => {
        dispatch( toggle(locationId) )
    }

    function toggle(locationId) {
        return {
            type: "TOGGLE_LOCATION_FORM",
            locationId: locationId
        }
    }
}

function settings() {
    return dispatch => {
        dispatch(request());
        AuthService.settings()
            .then(
                settings => {
                    dispatch(success(settings))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request() { return { type: "APP_SETTINGS_REQUEST" }; }
    function success(settings) { return { type: "APP_SETTINGS_SUCCESS", settings }; }
    function failure(error) { return { type: "APP_SETTINGS_FAILURE", error }; }
}
import {ErrorHandler} from "../helpers/ErrorHandler";
import {Request} from "../helpers/Request";

export const FloorItemService = {
    list,
    save,
    show
}

function save(data) {
    let method = "POST";
    let endpoint = "floorItems";
    if (data.id) {
        method = "PUT";
        endpoint += "/" + data.id;
    }
    return Request(endpoint, method, data)
        .then(ErrorHandler)
        .then(
            floorItem => {
                return floorItem;
            }
        )
}

function list(floorId) {
    return Request('floorItems', 'GET', null, {floorId: floorId})
        .then(ErrorHandler)
        .then(
            floorItems => {
                return floorItems;
            }
        );
}

function show(id) {
    return Request('floorItems/' + id, 'GET')
        .then(ErrorHandler)
        .then(
            floorItem => {
                return floorItem;
            }
        );
}
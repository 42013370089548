import React, { Component } from 'react';

class InvoicesItems extends Component {
    render() {
        return (
            <div>No invoices yet.</div>
        );
    }
}

export default InvoicesItems;
import React, { Component } from 'react';
import {Link} from "react-router-dom";
import "./styles.scss";

class FormHeader extends Component {
    render() {
        return(
            <div className={"form-header mb-4 mt-0"}>
                <div className={"row"}>
                    <div className={"col-10"}>
                        <h4>{ this.props.title }</h4>
                        <h6>{ this.props.subtitle ? this.props.subtitle : ' ' }</h6>
                    </div>
                    <div className={"col-2 text-right"}>
                        <Link to={this.props.redirectTo}>
                            <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-times fa-stack-1x fa-inverse"}/>
                                </span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormHeader;
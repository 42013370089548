import React, { Component } from 'react';
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {ValidationActions} from "../../../actions/ValidationActions";
import {ValueConverter} from "../../../helpers/ValueConverter";
import {QueryString} from "../../../helpers/QueryString";

class ValidationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validations: [],
            showDeleteConfirm: null
        }

        this.renderValidations = this.renderValidations.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(ValidationActions.list());
    }

    renderValidations() {
        const validations = [];
        this.state.validations.map((validation, index) => {
            validations.push(
                <div className={"validation-item"} key={index}>
                    <h5>
                        { validation.name }
                    </h5>
                    <Link to={"#"} onClick={
                        (e) => {
                            e.preventDefault();
                            this.setState({
                                showDeleteConfirm: validation.id
                            })
                        }} className={"float-right"}>
                        <i className={"far fa-trash-alt"}/>
                    </Link>
                    <Link to={"/validations/edit?validationId=" + validation.id} onClick={
                        (e) => {
                            this.props.functions.editItem(validation.id)
                        }} className={"float-right mr-2"}>
                        <i className={"far fa-pencil-alt"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <small>{ validation.description }</small>
                        </div>
                    </div>
                    <div className={"validation-details"}>
                        <span className={"mr-4"}>
                            <small>{ ValueConverter.toYearsAndMonths(validation.check_period, true) }</small>
                        </span>
                    </div>
                    {this.state.showDeleteConfirm === validation.id ?
                        <div className={"delete-confirm mt-1"}>
                            <span className={"mr-3"}>Do you want to delete this validation?</span>
                            <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                })
                            }}>No
                            </button>
                            <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                this.props.functions.deleteValidation(validation.id)
                            }}>Yes</button>
                        </div>
                        : null
                    }
                </div>
            );
        })

        return validations;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.validations && nextProps.validations.length) {
            this.setState({
                validations: nextProps.validations
            })
        }

        if (nextProps.deleted === true) {
            this.props.dispatch(ValidationActions.list());
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.validations || !nextProps.validations.length) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <div className={"validations-menu-wrapper"}>
                { this.state.validations && this.state.validations.length ?
                    this.renderValidations()
                    :
                    <div className={"alert alert-warning flat"}>
                        <i className={"fal fa-info-circle mr-2"}/>
                        There are no validations yet
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { validations, deleted } = state.Validation;

    return {
        validations,
        deleted
    }
}

export default connect(mapStateToProps)(ValidationsList);
import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const OperationService = {
    save,
    events,
    eventsGroupedByUser,
    removeByLocation
}

function save(data) {
    return Request('operation', 'POST', data)
        .then(ErrorHandler)
        .then(
            events => {
                return events;
            }
        )
}

function events(data) {
    return Request('operation', 'GET', null, data)
        .then(ErrorHandler)
        .then(
            events => {
                return events
            }
        )
}

function eventsGroupedByUser(data) {
    return Request('operation/grouped', 'GET', null, data)
        .then(ErrorHandler)
        .then(
            events => {
                return events
            }
        )
}

function removeByLocation(location) {
    return Request('operation/delete', 'DELETE', null, {locationId: location.id})
        .then(ErrorHandler)
        .then(
            deletedOperation => {
                return deletedOperation
            }
        )
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormAbstract from "../../abstract/FormAbstract";
import moment from "moment";
import {OperationActions} from "../../actions/OperationActions";
import {QueryString} from "../../helpers/QueryString";
import {Link} from "react-router-dom";
import Intl from "../../helpers/Intl";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {LocationDetails, OperatorDetails} from "./OperationsMenu";
import {UserActions} from "../../actions/UserActions";
import {GMaps} from "../../plugins/gmaps";
import {LocationActions} from "../../actions/LocationActions";
import {opera} from "leaflet/src/core/Browser";

class OperationsPlanning extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "operation";

        this.state = {
            users: [],
            selectedOperator: null,
            plannedLocations: [],
            selectedLocations: [],
            daySelected: moment().toDate(),
            orderedLocations: []
        };

        this.saveOperation = this.saveOperation.bind(this);
        this.handleDay = this.handleDay.bind(this);
        this.handleOperator = this.handleOperator.bind(this);
        this.renderSelectedLocations = this.renderSelectedLocations.bind(this);
        this.resetSelection = this.resetSelection.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(UserActions.list(moment().format("YYYY-MM-DD")))
    }

    componentDidMount() {
        GMaps.removeItinerary()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            const users = nextProps.users.sort((a, b) => {
                return (a.workload > b.workload) ? 1 : -1;
            });
            if (QueryString.get('userId')) {
                users.map((user) => {
                    if (user.id === QueryString.get('userId') && !this.state.selectedOperator) {
                        this.setState({
                            selectedOperator: user
                        })
                    }
                });
            }
            this.setState({
                users: users
            })
        }

        if (nextProps.routes) {
            const allLocations = this.state.plannedLocations.concat(this.state.selectedLocations);
            const locations = [];
            console.log(nextProps.routes);
            nextProps.routes.routes[0].waypoint_order.map((locationOrder) => {
                locations.push({
                    location: allLocations[locationOrder],
                    duration: nextProps.routes.routes[0].legs[locationOrder].duration
                })
            });

            this.setState({
                orderedLocations: locations
            });
        }

        if (nextProps.events && this.state.selectedOperator) {
            const locations = [];
            if (!nextProps.events.length) {
                this.setState({
                    plannedLocations: []
                });
            }
            if (nextProps.events[this.state.selectedOperator.id]) {
                nextProps.events[this.state.selectedOperator.id].map((operationEvent) => {
                    if (!this.isDuplicateLocation(operationEvent.location, this.state.selectedLocations)) {
                        locations.push(operationEvent.location);
                    }
                })

                this.setState({
                    plannedLocations: locations
                });
            }

            const allLocations = locations.concat(this.state.selectedLocations);

            GMaps.createItinerary(this.state.selectedOperator, allLocations, this.props.dispatch)
        }

        if (nextProps.location) {
            const locations = this.state.selectedLocations;
            let locationExists = false;
            locations.map((plannedLocation) => {
                if (plannedLocation.id === nextProps.location.id) {
                    locationExists = true;
                }
            });
            if (!locationExists) {
                locations.unshift(nextProps.location);
                this.setState({
                    selectedLocations: locations
                })
            }

            const allLocations = this.state.plannedLocations.concat(locations);

            GMaps.createItinerary(this.state.selectedOperator, allLocations, this.props.dispatch)
        }

        if (typeof nextProps.deletedOperation !== "undefined") {
            this.props.dispatch(LocationActions.all());
        }
    }

    isDuplicateLocation(location, selectedLocations) {
        let isDuplicated = false;
        selectedLocations.map((selectedLocation) => {
            if (selectedLocation.id === location.id) {
                isDuplicated = true;
            }
        })

        return isDuplicated;
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if ((nextState.selectedOperator !== this.state.selectedOperator) || (nextState.daySelected !== this.state.daySelected)) {
            GMaps.removeItinerary()
            this.props.dispatch(OperationActions.events({
                user_id: nextState.selectedOperator ? nextState.selectedOperator.id : null,
                schedule: moment(nextState.daySelected).format('YYYY-MM-DD')
            }));
        }
    }

    resetSelection() {
        this.setState({
            selectedOperator: null,
            daySelected: new Date()
        });
    }

    render() {
        return (
            <div className="operations-menu">
                <div className={"operations-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <button className={"btn btn-primary btn-sm"} onClick={this.saveOperation}>
                                <i className={"far fa-check mr-2"}/>
                                { Intl("Salva") }
                            </button>
                        </div>
                        <div className={"col-4"}>
                            <Link to={"/operations/add"} className={'btn btn-primary btn-sm'} onClick={this.resetSelection}>
                                <i className={'fal fa-eraser mr-1'}/>
                                { Intl("Rimuovi selezione") }
                            </Link>
                        </div>
                        <div className={"col-5 text-right"}>
                            <Link to={"/operations"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-map-marker-alt fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                            <Link to={"/operations/calendar"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-calendar-check fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                            <Link to={"/dashboard"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Pianificazione") }</h4>
                        </div>
                    </div>
                </div>
                <div className="date-picker-container">
                    <DatePicker
                        selected={this.state.daySelected}
                        onChange={this.handleDay}
                        inline
                        minDate={moment().toDate()}
                    />
                </div>
                <div className={"resource-selector"}>
                    <Select
                        value={this.state.selectedOperator}
                        placeholder={Intl("Seleziona operatore")}
                        getOptionLabel={option => <OperatorDetails key={option.id} operator={option}/>}
                        getOptionValue={option => option.id}
                        options={this.state.users}
                        isSearchable={true}
                        isMulti={false}
                        onChange={this.handleOperator}
                    />
                </div>
                <div className={"selected-locations"}>
                    <div className={"clearfix"}>
                        <div className={"module-menu-header"}>
                            <div className="row">
                                <div className={"col-12"}>
                                    <h4>{ Intl("Sedi selezionate") }: { this.state.orderedLocations.length }</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"selected-locations-wrapper"}>
                        { this.state.orderedLocations.length ?
                            this.renderSelectedLocations() :
                            <div className="selected-location-item">
                                <span>{ Intl("Nessuna sede selezionata") }</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    handleDay(day) {
        this.setState({
            daySelected: day,
        })

        this.props.dispatch(UserActions.list(moment(day).format("YYYY-MM-DD")))
    }

    handleOperator(operator) {
        this.setState({
            selectedOperator: operator
        })
    }

    renderSelectedLocations() {
        const locations = [];
        this.state.orderedLocations.map((location) => {
            locations.push(<LocationDetails key={location.id} location={location}/>);
        })

        return locations;
    }

    saveOperation(e) {
        e.preventDefault();
        const data = {
            user_id: this.state.selectedOperator ? this.state.selectedOperator.id : null,
            locations: this.state.orderedLocations,
            schedule: moment(this.state.daySelected).format('YYYY-MM-DD')
        }

        this.props.dispatch(OperationActions.save(data));
    }
}

function mapStateToProps(state) {
    const { users } = state.User;
    const { events, location, routes, deletedOperation, operationSaved } = state.Operation;
    return {
        users,
        events,
        location,
        routes,
        deletedOperation,
        operationSaved
    }
}

export default connect(mapStateToProps)(OperationsPlanning);
import { Request } from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";
import moment from "moment";
export const HoursOffService = {
    getByUser,
    save,
    remove
};

function getByUser(userId) {
    return Request('hours-off', 'GET', null, {userId})
        .then(ErrorHandler)
        .then(
            hoursOff => {
                return hoursOff
            }
        )
}

function save(data) {
    return Request('hours-off', 'POST', data)
        .then(ErrorHandler)
        .then(
            hoursOff => {
                return hoursOff
            }
        )
}

function remove(id, userId) {
    return Request('hours-off/' + id, 'DELETE', null, {userId})
        .then(ErrorHandler)
        .then(
            hoursOff => {
                return hoursOff
            }
        )
}
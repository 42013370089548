import {ErrorHandler} from "../helpers/ErrorHandler";
import {Request} from "../helpers/Request";

export const CategoryService = {
    list
}

function list() {
    return Request('categories', 'GET')
        .then(ErrorHandler)
        .then(
            categories => {
                return categories;
            }
        );
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FloorActions} from "../../../actions/FloorActions";
import {QueryString} from "../../../helpers/QueryString";
import FloorConfigureItemForm from "../components/FloorConfigureItemForm";
import {FloorItemActions} from "../../../actions/FloorItemActions";
import {LeafletMap} from "../../../plugins/Leaflet/Leaflet";
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import ReactToPrint from "react-to-print";

class FloorConfigure extends Component {
    constructor(props) {
        super(props);

        this.state = {
            floor: {
                image_url: "",
                image_h: 0,
                image_w: 0,
            },
            floorItems: [],
            floorItem: {},
            selectedMapPosition: {
                lat: 0,
                lng: 0
            },
            showFloorItemForm: false
        }

        this.toggleFloorItemForm = this.toggleFloorItemForm.bind(this);
        this.renderFloorItems = this.renderFloorItems.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this)
        this.updatePosition = this.updatePosition.bind(this)
        this.openModifyModal = this.openModifyModal.bind(this)

        this.functions = {
            toggleFloorItemForm: this.toggleFloorItemForm,
            onContextMenu: this.onContextMenu,
            updatePosition: this.updatePosition,
            openModifyModal: this.openModifyModal
        }
    }

    componentWillMount() {
        this.props.dispatch(FloorActions.show(QueryString.get('floorId')))
        this.props.dispatch(FloorItemActions.list(QueryString.get('floorId')))
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.floor) {
            this.setState({
                floor: nextProps.floor
            })
            const options = {
                url: nextProps.floor.image_url,
                h: nextProps.floor.image_h,
                w: nextProps.floor.image_w,
            };
            LeafletMap.initMap(options, this.functions);
        }

        if (nextProps.floorItems) {
            this.setState({
                floorItems: nextProps.floorItems,
                showFloorItemForm: false,
            })
        }

        if (nextProps.floorItem) {
            if (this.state.floorItems) {
                this.state.floorItems.map((floorItem, index) => {
                    if (floorItem.id === nextProps.floorItem.id) {
                        // this.state.floorItems[index] = nextProps.floorItem;
                        delete this.state.floorItems[index]
                    }
                })

                if (nextProps.floorItem.id) {
                    this.state.floorItems.push(nextProps.floorItem)
                }
            }
            this.setState({
                showFloorItemForm: nextProps.success ? false : true,
                floorItem: nextProps.floorItem,
            })
        }
    }

    openModifyModal(floorItem) {
        console.log(floorItem)
    }

    onContextMenu(e) {
        this.setState({
            selectedPosition: {
                lat: e.latlng.lat,
                lng: e.latlng.lng
            }
        })
    }

    componentDidMount() {
        LeafletMap.initMap({
            url: this.state.floor.image_url,
            h: this.state.floor.image_h,
            w: this.state.floor.image_w
        });
    }

    toggleFloorItemForm(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            showFloorItemForm: !this.state.showFloorItemForm
        })
    }

    renderFloorItems() {
        if (LeafletMap.getMap()) {
            LeafletMap.createMarkers(this.state.floorItems, this.functions, this.props.dispatch);
        }
    }

    updatePosition(floorItem) {
        this.props.dispatch(FloorItemActions.save(floorItem))
    }

    render() {
        this.renderFloorItems()
        return (
            <React.Fragment>
                <div id={"map"}></div>
                { this.state.showFloorItemForm ? <FloorConfigureItemForm floorItem={this.state.floorItem} floorId={this.state.floor.id} functions={this.functions} selectedPosition={this.state.selectedPosition}/> : "" }
            </React.Fragment>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.floorItem) {
            return true;
        }

        if (!nextProps.floorItems) {
            return false;
        }

        return true;
    }
}

function mapStateToProps(state) {
    const { floor } = state.Floor;
    const { floorItem, floorItems, success } = state.FloorItem;

    return {
        floor,
        floorItem,
        floorItems,
        success
    }
}

export default connect(mapStateToProps)(FloorConfigure);
import React, { Component } from 'react';
import {CustomerActions} from "../actions/CustomerActions";
import {LocationActions} from "../actions/LocationActions";
import {FloorActions} from "../actions/FloorActions";
import {ItemActions} from "../actions/ItemActions";
import {FloorItemActions} from "../actions/FloorItemActions";
import {SubitemActions} from "../actions/SubitemActions";
import {ValidationActions} from "../actions/ValidationActions";
import {UserActions} from "../actions/UserActions";
import moment from "moment";
import {HoursOffActions} from "../actions/HoursOffActions";
import {DocumentActions} from "../actions/DocumentActions";

class FormAbstract extends Component {
    constructor(props) {
        super(props);

        this.model = undefined;

        this.actions = {
            customer: CustomerActions,
            location: LocationActions,
            floor: FloorActions,
            item: ItemActions,
            floorItem: FloorItemActions,
            subitem: SubitemActions,
            validation: ValidationActions,
            user: UserActions,
            hoursOff: HoursOffActions,
            document: DocumentActions
        };

        this.handleForm = this.handleForm.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.handleMultipleSelect = this.handleMultipleSelect.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleDate = this.handleDate.bind(this);
    }

    handleForm(e) {
        const { name, value, files } = e.target;
        const objectName = name.split(".");
        if (objectName.length === 2) {
            if (files && Array.from(files).length) {
                this.setState({
                    [this.model]: {
                        ...this.state[this.model],
                        [objectName[0]]: {
                            ...this.state[this.model][objectName[0]],
                            [objectName[1]]: files[0]
                        }
                    }
                })
            } else {
                this.setState({
                    [this.model]: {
                        ...this.state[this.model],
                        [objectName[0]]: {
                            ...this.state[this.model][objectName[0]],
                            [objectName[1]]: value
                        }
                    }
                });
            }
        } else {
            if (files && Array.from(files).length) {
                this.setState({
                    [this.model]: {
                        ...this.state[this.model],
                        [name]: files[0]
                    }
                })
            } else {
                this.setState({
                    [this.model]: {
                        ...this.state[this.model],
                        [name]: value
                    }
                });
            }
        }
    }

    handleDate(day, field) {
        this.setState({
            [this.model]: {
                ...this.state[this.model],
                [field]: day
            }
        })
    }

    handleMultipleSelect(selectedValues, fieldName) {
        const values = [];
        console.log(selectedValues)
        if (selectedValues) {
            selectedValues.map((selectedValue) => {
                values.push(selectedValue);
            })
        }
        this.setState({
            [this.model]: {
                ...this.state[this.model],
                [fieldName]: values
            }
        })
    }

    handleSelect(selectedValue, fieldName) {
        this.setState({
            [this.model]: {
                ...this.state[this.model],
                [fieldName]: selectedValue.id
            }
        })
    }

    setAddress(address, inputName) {
        const objectName = inputName.split(".");
        if (objectName.length === 2) {
            this.setState({
                [this.model]: {
                    ...this.state[this.model],
                    [objectName[0]]: {
                        ...this.state[this.model][objectName[0]],
                        [objectName[1]]: address
                    }
                }
            });
        } else {
            this.setState({
                [this.model]: {
                    ...this.state[this.model],
                    [inputName]: address
                }
            })
        }
    }

    sendForm(e) {
        if (e) {
            e.preventDefault();
        }
        console.log(this.state[this.model]);
        this.props.dispatch(this.actions[this.model].save(this.state[this.model]));
    }
}

export default FormAbstract;
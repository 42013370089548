import {Auth} from "./Auth";
import {settings} from "../settings";

export function Request(endpoint, method, data, params, headers = {}, type, language) {
    if (!language) {
        language = 'it';
    }
    let url = settings.apiUrl + endpoint;
    if (params) {
        let paramsString = "";
        Object.keys(params).map((param, index) => {
            if (params[param]) {
                if (!index) {
                    paramsString += param + "=" + params[param];
                } else {
                    paramsString += "&" + param + "=" + params[param];
                }
            }
        });
        url += "?" + paramsString;
    }

    const account = localStorage.getItem('account') || data.account.toLowerCase();

    headers = {
        ...Auth(),
        ...headers,
        'x-mobile-origin': account + settings.originSuffix,
        'X-Localization': language,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    let formData = undefined;
    if (data) {
        formData = JSON.stringify(data);
    }

    if (type === "multipart" && data) {
        delete(headers["Content-Type"]);

        formData = new FormData();
        Object.keys(data).map((key) => {
            if (data[key] && data[key].size) {
                formData.set(key, data[key], data[key].name);
            } else {
                formData.set(key, data[key]);
            }
        });
    }

    let requestOptions = {
        method: method,
        headers: headers
    };

    if (formData) {
        requestOptions["body"] = formData;
    }


    return fetch(url, requestOptions);
}
import React, { Component } from 'react';

class ModalHeader extends Component {
    render() {
        return(
            <div className={"modal-like-header"}>
                { this.props.prependIcon ?
                    <img src={this.props.prependIcon} height={25} className="mr-2"/>
                    : ''
                }
                <span className={"modal-header-title"}>{ this.props.title }</span>
            </div>
        );
    }
}

export default ModalHeader;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from "../../../components/Input/Input";
import {LocationActions} from "../../../actions/LocationActions";
import FormAbstract from "../../../abstract/FormAbstract";
import Intl from "../../../helpers/Intl";

class LocationItemForm extends FormAbstract {
    constructor(props) {
        super(props);
        this.model = "location";
        this.state = {
            location: {
                customer_id: this.props.customerId,
                name: "",
                address: "",
                electronic_forbidden: false
            }
        }

        this.setAddress = this.setAddress.bind(this);
        this.handleForm = this.handleForm.bind(this);
        this.sendForm = this.sendForm.bind(this)
    }

    componentWillMount() {
        if (this.props.locationId) {
            this.props.dispatch(LocationActions.item(this.props.locationId))
        }
    }

    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.success !== "undefined") {
            if (nextProps.success === true) {
                this.props.functions.toggleLocationForm();
            }
            this.props.dispatch(LocationActions.list(nextProps.location.customer_id));
        }

        if (nextProps.location) {
            this.setState({
                location: nextProps.location
            });
        }
    }

    render() {
        console.log(this.state)
        return(
            <div className={"modal-container"}>
                <div className={"modal-like-wrapper"}>
                    <div className={"modal-like-header"}>
                        { this.state.location.id ? Intl("Modifica") + " " + this.state.location.name : Intl("Nuova sede") }
                    </div>
                    <div className={"modal-like-body"}>
                        <Input type={"text"} name={"name"} label={Intl("Nome sede")} value={this.state.location.name} onChange={this.handleForm}/>
                        <Input type={"addressAutocomplete"} name={"address"} label={Intl("Indirizzo")} setAddress={this.setAddress} value={this.state.location.address}/>
                        <Input type={"checkbox"} name={"electronic_forbidden"} placeholder={Intl("Apparati elettronici vietati")} value={this.state.location.electronic_forbidden} onChange={() => {
                            this.setState({
                                location: {
                                    ...this.state.location,
                                    electronic_forbidden: !this.state.location.electronic_forbidden
                                }
                            })
                        }}/>
                    </div>
                    <div className={"modal-like-footer"}>
                        <button className={"btn btn-sm btn-secondary"} onClick={this.props.functions.toggleLocationForm}>Annulla</button>
                        <button className={"btn btn-sm btn-primary float-right"} onClick={this.sendForm}>Salva</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { location, success } = state.Location;

    return {
        location,
        success
    }
}

export default connect(mapStateToProps)(LocationItemForm);
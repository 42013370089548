import {CustomerService} from "../services/CustomerService";

export const CustomerActions = {
    list,
    item,
    empty,
    save,
    remove
};

function list() {
    return dispatch => {
        dispatch(request());
        CustomerService.list()
            .then(
                customers => {
                    dispatch(success(customers));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "CUSTOMERS_LIST_REQUEST" }; }
    function success(customers) { return { type: "CUSTOMERS_LIST_SUCCESS", customers }; }
    function failure(error) { return { type: "CUSTOMERS_LIST_FAILURE", error }; }
}

function empty() {
    const customer = {
        id: undefined,
        name: "",
        email: "",
        phone: "",
        fax: "",
        vat: "",
        vat_number: "",
        website: "",
        discount: "",
        address: undefined,
        notes: "",
        siteManager: {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            mobile: "",
            address: undefined
        },
        locations: []
    };

    return dispatch => {
        dispatch(success(customer))
    }

    function success(customer) { return { type: "CUSTOMER_NEW", customer }; }
}

function item(id) {
    return dispatch => {
        dispatch(request());
        CustomerService.item(id)
            .then(
                customer => {
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "CUSTOMER_ITEM_REQUEST" }; }
    function success(customer) { return { type: "CUSTOMER_ITEM_SUCCESS", customer }; }
    function failure(error) { return { type: "CUSTOMER_ITEM_FAILURE", error }; }
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        CustomerService.save(data)
            .then(
                customer => {
                    dispatch(success(customer));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    }

    function request(data) { return { type: "CUSTOMER_SAVE_REQUEST", data }; }
    function success(customer) { return { type: "CUSTOMER_SAVE_SUCCESS", customer }; }
    function failure(error) { return { type: "CUSTOMER_SAVE_FAILURE", error }; }
}

function remove(id) {
    return dispatch => {
        dispatch(request());
        CustomerService.remove(id)
            .then(
                customer => {
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "CUSTOMER_REMOVE_REQUEST" }; }
    function success(customer) { return { type: "CUSTOMER_REMOVE_SUCCESS", customer }; }
    function failure(error) { return { type: "CUSTOMER_REMOVE_FAILURE", error }; }
}
import React, { Component } from 'react';
import Input from "../Input/Input";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

class Modal extends Component {
    render() {
        return(
            <div className={"modal-container"}>
                <div className={"modal-like-wrapper"}>
                    {this.props.children ? this.props.children :
                        <React.Fragment>
                            <ModalHeader title={this.props.title}/>
                            <ModalBody>
                                { this.props.bodyContent }
                            </ModalBody>
                            <ModalFooter>
                                { this.props.footerContent }
                            </ModalFooter>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default Modal;
import React, { Component } from 'react';
import App from "../../App";
import LocationsMenu from "./LocationsMenu";
import LocationStore from "./scenes/LocationStore";

class Locations extends Component {

    constructor(props) {
        super(props);
        this.scenes = {
            edit: <LocationStore/>
        }
    }

    render() {
        return (
            <App menu={<LocationsMenu/>} moduleRoles={["SUPER_ADMIN", "PLANNER"]}>
                { this.scenes[this.props.match.params.scene] }
            </App>
        );
    }
}

export default Locations;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./styles.scss"
import {Link} from "react-router-dom";
import {AuthActions} from "../../actions/AuthActions";
import {OperationActions} from "../../actions/OperationActions";
import moment from "moment";
import Intl from "../../helpers/Intl";
class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this)

        this.state = {
            profile: {}
        }
    }

    componentWillMount() {
        this.props.dispatch(OperationActions.events({
            schedule: moment().format("YYYY-MM-DD")
        }));
    }

    logout(e) {
        e.preventDefault();
        this.props.dispatch(AuthActions.logout())
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile) {
            this.setState({
                profile: nextProps.profile
            })
        }

        if (nextProps.user && this.props.profile) {
            if (nextProps.user.id === this.props.profile.id) {
                this.setState({
                    profile: nextProps.user
                })
            }
        }
    }

    render() {
        return(
            <React.Fragment>
                <div className={"container"}>
                    <div className={"search-bar-wrapper"}>
                        <div className={"row"}>
                            <div className={"col-2 text-center"}>
                                <div className="search-bar-actions">
                                    <Link to={"#"} className={"menu-bars"} onClick={this.props.functions.toggleMenu}>
                                        <i className={"far fa-bars"}/>
                                    </Link>
                                </div>
                            </div>
                            <div className={"col-8 no-padding-left"}>
                                <Link to={"#"} className={"auth-info"} style={{ paddingTop: '2px', color: "#fff", textDecoration: "none", fontSize: "1.3rem", display: "block" }}>
                                    { this.state.profile ? this.state.profile.fullName : "" }
                                    <span className={"float-right notifications"} style={{ paddingTop: "2px", position: 'relative'}}>
                                        <span className={"badge badge-warning"}>2</span>
                                        <i className={"far fa-bell"}/>
                                    </span>
                                </Link>
                            </div>
                            <div className={"col-2 bordered-left no-padding-left text-center"}>
                                <div class="search-bar-actions">
                                    <a href={"#"} className={"logout"} onClick={this.logout}>
                                        <i className={"fas fa-sign-out"}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.props.moduleMenu ? this.props.moduleMenu : <DefaultMenu locations={this.props.locations} events={this.props.events}/> }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { locations } = state.Location;
    const { events } = state.Operation;
    const { profile } = state.Auth;
    const { user } = state.User;

    return {
        locations,
        events,
        profile,
        user
    }
}

export default connect(mapStateToProps)(Dashboard);

export class DefaultMenu extends Component {
    constructor(props) {
        super(props);

        this.locationsStatus = this.locationsStatus.bind(this);
        this.countEvents = this.countEvents.bind(this);
    }

    countEvents() {
        let countEvents = 0;
        if (this.props.events) {
            Object.keys(this.props.events).map((userId) => {
                countEvents += this.props.events[userId].length
            })
        }

        return countEvents;
    }

    locationsStatus(status) {
        let count = 0;
        if (this.props.locations) {
            this.props.locations.map((location) => {
                if (location.status === status) {
                    count++;
                }
            });
        }

        return count;
    }

    render() {
        return (
            <React.Fragment>
                <div className={"container"}>
                    <div className={"dashboard-info"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className="list-group">
                                    <Link to="/operations/calendar" className="list-group-item list-group-item-action">
                                        <span className={"badge badge-primary mr-2"}>{this.countEvents() }</span> { Intl('Interventi pianificati per oggi') }
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container"}>
                    <div className={"quick-link-status-wrapper"}>
                        <div className={"row"}>
                            <div className={"col-4 text-center"}>
                                <div className={"quick-link-status"}>
                                    <a href={"#"} className={"status-ok"}>
                                          <span className={"fa-stack"}>
                                              <i className={"fad fa-check-circle fa-stack-2x"}/>
                                          </span>
                                        <p className={"quick-link-status-caption"}>
                                            <b>{ this.locationsStatus("ok") }</b> { Intl('Sedi')}
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <div className={"col-4 text-center"}>
                                <div className={"quick-link-status"}>
                                    <a href={"#"} className={"status-warning"}>
                                          <span className={"fa-stack"}>
                                              <i className={"fad fa-exclamation-triangle fa-stack-2x"}/>
                                          </span>
                                        <p className={"quick-link-status-caption"}>
                                            <b>{ this.locationsStatus("warning") }</b>  { Intl('Sedi')}
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <div className={"col-4 text-center"}>
                                <div className={"quick-link-status"}>
                                    <a href={"#"} className={"status-danger"}>
                                        <span className={"fa-stack"}>
                                            <i className={"fad fa-bell fa-stack-2x"}/>
                                        </span>
                                        <p className={"quick-link-status-caption"}>
                                            <b>{ this.locationsStatus("danger") }</b>  { Intl('Sedi')}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
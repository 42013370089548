import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import DatePicker from 'react-datepicker';
import Select from "react-select";
import {UserActions} from "../../actions/UserActions";
import {GMaps} from "../../plugins/gmaps";
import moment from "moment";
import {OperationActions} from "../../actions/OperationActions";
import {ValueConverter} from "../../helpers/ValueConverter";
import {QueryString} from "../../helpers/QueryString";
import Intl from '../../helpers/Intl';

class OperationsMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            daySelected: new Date(),
            selectedLocations: [],
            plannedLocations: [],
            selectedOperator: null,
            events: []
        };

        this.handleDay = this.handleDay.bind(this);
        this.renderSelectedLocations = this.renderSelectedLocations.bind(this);
        this.handleOperator = this.handleOperator.bind(this);
        this.saveOperation = this.saveOperation.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.resetSelection = this.resetSelection.bind(this);
    }

    componentWillMount() {
        const params = QueryString.all();
        var schedule
        if ((schedule = params.schedule)) {
            this.setState({
                daySelected: moment(params.schedule).toDate()
            });
        } else {
            schedule = moment().format("YYYY-MM-DD");
        }

        const data = {
            user_id: params.userId,
            schedule: schedule
        };

        this.props.dispatch(OperationActions.events(data));
    }

    componentDidMount() {
        this.props.dispatch(UserActions.list(moment(this.state.daySelected).format('YYYY-MM-DD')));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            const users = nextProps.users.sort((a, b) => {
                return (a.workload > b.workload) ? 1 : -1;
            });
            if (QueryString.get('userId')) {
                users.map((user) => {
                   if (user.id === QueryString.get('userId') && !this.state.selectedOperator) {
                       this.setState({
                           selectedOperator: user
                       })
                   }
                });
            }
            this.setState({
                users: users
            })
        }

        if (nextProps.events) {
            const events = [];
            const locations = [];
            Object.keys(nextProps.events).map((userId) => {
                nextProps.events[userId].map((operationEvent) => {
                    locations.push(operationEvent.location);
                    events.push(operationEvent);
                });
            });
            this.setState({
                events: events,
                plannedLocations: locations
            });
            GMaps.createPlanningOverview(events)
        }
    }

    renderSelectedLocations() {
        const locations = [];
        console.log(this.state.plannedLocations)
        this.state.plannedLocations.map((location) => {
            locations.push(<LocationDetails key={location.id} location={location}/>)
        });

        return locations;
    }

    resetSelection() {
        this.setState({
            selectedOperator: null,
            plannedLocations: [],
            daySelected: moment().toDate()
        })
        this.props.dispatch(OperationActions.events({schedule: moment().format('YYYY-MM-DD')}));
    }

    render() {
        console.log(this.state.selectedOperator)
        return (
            <div className="operations-menu">
                <div className={"operations-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <Link to={"/operations/add"} className={"btn btn-primary btn-sm"}>
                                <i className={"far fa-check mr-2"}/>
                                { Intl("Nuovo") }
                            </Link>
                        </div>
                        <div className={"col-5"}>
                            <Link to={"/operations"} className={'btn btn-primary btn-sm'} onClick={this.resetSelection}>
                                <i className={'fal fa-eraser mr-1'}/>
                                { Intl("Rimuovi selezione") }
                            </Link>
                        </div>
                        <div className={"col-4 text-right"}>
                            <Link to={"/operations/calendar"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-calendar-check fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                            <Link to={"/dashboard"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Operations planned") }</h4>
                        </div>
                    </div>
                </div>
                <div className="date-picker-container">
                    <DatePicker
                        selected={this.state.daySelected}
                        onChange={this.handleDay}
                        inline
                    />
                </div>
                <div className={"resource-selector"}>
                    <Select
                        value={this.state.selectedOperator}
                        placeholder={Intl("Seleziona operatore")}
                        getOptionLabel={option => <OperatorDetails key={option.id} operator={option}/>}
                        getOptionValue={option => option.id}
                        options={this.state.users}
                        isSearchable={true}
                        isMulti={false}
                        onChange={this.handleOperator}
                    />
                </div>
                <div className={"selected-locations"}>
                    <div className={"clearfix"}>
                        <div className={"module-menu-header"}>
                            <div className="row">
                                <div className={"col-12"}>
                                    <h4>{ Intl("Sedi selezionate") }: { this.state.plannedLocations.length }</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"selected-locations-wrapper"}>
                        { this.state.plannedLocations.length ?
                            this.renderSelectedLocations() :
                            <div className="selected-location-item">
                                <span>{ Intl("Nessuna sede selezionata") }</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    getEvents(data) {
        this.props.dispatch(OperationActions.events(data))
    }

    handleOperator(value) {
        this.setState({
            selectedOperator: value
        })

        this.getEvents({
            user_id: value.id,
            schedule: moment(this.state.daySelected).format("YYYY-MM-DD")
        })
    }

    handleDay(day) {
        this.setState({
            daySelected: day,
        })
        this.props.dispatch(UserActions.list(moment(day).format('YYYY-MM-DD')));
        this.getEvents({
            user_id: this.state.selectedOperator ? this.state.selectedOperator.id : null,
            schedule: moment(day).format("YYYY-MM-DD")
        })
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextState.events.toString() !== this.state.events.toString()) {
            GMaps.createPlanningOverview(nextState.events);
        }
    }

    saveOperation(e) {
        e.preventDefault();
        const data = {
            user_id: this.state.selectedOperator ? this.state.selectedOperator.id : null,
            locations: this.state.plannedLocations,
            schedule: moment(this.state.daySelected).format('YYYY-MM-DD')
        }

        this.props.dispatch(OperationActions.save(data))
    }
}

function mapStateToProps(state) {
    const { users } = state.User;
    const { location, events, routes } = state.Operation;
    return {
        users,
        location,
        events,
        routes
    }
}

export default connect(mapStateToProps)(OperationsMenu);

export class OperatorDetails extends Component {
    render() {
        const timePercentage = ValueConverter.percentage(this.props.operator.workload, 480);
        return (
            <div className={"operator"}>
                <span className={"d-block"}>
                    <i className={"fas fa-square mr-2"} style={{color: this.props.operator.color }}/>
                    { this.props.operator.fullName }
                </span>
                <small className={"mr-2"}>
                    <i className={"fal fa-mobile-alt mr-1"}/>
                    { this.props.operator.mobile }
                </small>
                <small className={"mr-2"}>
                    <i className={"fal fa-envelope mr-1"}/>
                    { this.props.operator.email }
                </small>
                <span className="mr-4">

                </span>
                <div className="progress mt-2" style={{ height: 20 }}>
                    <div className="progress-bar" role="progressbar" style={{width: timePercentage + "%"}} aria-valuenow={timePercentage}
                         aria-valuemin="0" aria-valuemax="100">{ ValueConverter.toHoursAndMinutes(this.props.operator.workload, true) }
                    </div>
                </div>
            </div>
        );
    }
}

export class LocationDetails extends Component {
    render() {
        const location = this.props.location.location ? this.props.location.location : this.props.location;
        return (
            <div className={"selected-location-item"}>
                <h5>
                    {location.customer.name} @ {location.name}
                </h5>
                <small className={"d-block"}>
                    { location.address.formatted_address }
                </small>
                <Link to={"/locations/edit?locationId=" + location.id} onClick={
                    (e) => {
                        this.props.functions.editLocation(location.id)
                    }} className={"float-right mr-2"}>
                    <i className={"far fa-trash-alt"}/>
                </Link>
                <div className={"location-details"}>
                    <span className="mr-4">
                        <i className={'fal fa-mobile-android-alt mr-2'}/>
                        <small>{location.customer.phone}</small>
                    </span>
                    <span className="mr-4">
                        <i className={'fal fa-envelope mr-2'}/>
                        <small>{location.customer.email}</small>
                    </span>
                </div>
            </div>
        );
    }
}
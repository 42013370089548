export function Category(state = {}, action) {
    switch(action.type) {
        case "CATEGORIES_LIST_REQUEST":
            return {
                loading: true
            };
        case "CATEGORIES_LIST_SUCCESS":
            return {
                loading: false,
                categories: action.categories
            };
        case "CATEGORIES_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
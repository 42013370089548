import React, { Component } from 'react';
import {Link} from "react-router-dom";

class ItemsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            showDeleteConfirm: null
        }

        this.renderItems = this.renderItems.bind(this);
    }

    renderItems() {
        const items = [];
        this.state.items.map((item, index) => {
            items.push(
                <div className={"item-item"} key={index}>
                    <h5>
                        {item.category ?
                            <img src={"/img/items/icons/" + item.category.image} height={"22"}
                                 className={"float-left mr-2"}/>
                            : null
                        }
                        { item.name }
                    </h5>
                    <Link to={"#"} onClick={
                        (e) => {
                            e.preventDefault();
                            this.setState({
                                showDeleteConfirm: item.id
                            })
                        }} className={"float-right"}>
                        <i className={"far fa-trash-alt"}/>
                    </Link>
                    <Link to={"/items/edit?itemId=" + item.id} onClick={
                        (e) => {
                            this.props.functions.editItem(item.id)
                        }} className={"float-right mr-2"}>
                        <i className={"far fa-pencil-alt"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <small>{ item.description }</small>
                        </div>
                    </div>
                    <div className={"item-details"}>
                        <span className={"mr-4"}>
                            € <small>{ item.price }</small>
                        </span>
                    </div>
                    {this.state.showDeleteConfirm === item.id ?
                        <div className={"delete-confirm mt-1"}>
                            <span className={"mr-3"}>Do you want to delete this item?</span>
                            <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                this.setState({
                                    showDeleteConfirm: false
                                })
                            }}>No
                            </button>
                            <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                this.props.functions.deleteItem(item.id)
                            }}>Yes</button>
                        </div>
                        : null
                    }
                </div>
            );
        })

        return items;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps);
        if (nextProps.items) {
            this.setState({
                items: nextProps.items
            })
        }
    }

    render() {
        console.log(this.state)
        return (
            <div className={"items-menu-wrapper"}>
                { this.state.items && this.state.items.length ?
                    this.renderItems()
                    :
                    <div className={"alert alert-warning flat"}>
                        <i className={"fal fa-info-circle mr-2"}/>
                        There are no items yet
                    </div>
                }
            </div>
        );
    }
}

export default ItemsList;
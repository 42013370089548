import React, { Component } from 'react';
import {Calendar} from "react-calendar";
import FyriScheduler from "../../../components/FyriScheduler/FyriScheduler";
import FormHeader from "../../../components/FormHeader/FormHeader";
import Intl from "../../../helpers/Intl";
class OperationsCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div className="calendar-container">
                <FormHeader title={Intl("Interventi")} redirectTo={"/operations"}/>
                <FyriScheduler/>
            </div>
        );
    }
}

export default OperationsCalendar;
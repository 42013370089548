import React, { Component } from 'react';
import App from "../../App";
import CustomersMenu from "./CustomersMenu";
import CustomerForm from "./components/CustomerForm";
import CustomerStore from "./scenes/CustomerStore";

class Customers extends Component {

    constructor(props) {
        super(props);
        this.scenes = {
            add: <CustomerStore/>,
            edit: <CustomerStore/>
        }
    }

    render() {
        return (
            <App menu={<CustomersMenu/>} moduleRoles={["SUPER_ADMIN", "PLANNER"]}>
                { this.scenes[this.props.match.params.scene] }
            </App>
        );
    }
}

export default Customers;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from "../../../components/Input/Input";
import {FloorActions} from "../../../actions/FloorActions";
import FormAbstract from "../../../abstract/FormAbstract";
import Intl from "../../../helpers/Intl";

class FloorItemForm extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "floor";

        this.state = {
            floor: {
                location_id: this.props.locationId,
                name: "",
                plan: "",
                level: "",
                electronic_forbidden: false
            }
        }
    }

    componentWillMount() {
        if (this.props.floorId) {
            this.props.dispatch(FloorActions.show(this.props.floorId))
        }
    }

    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.success !== "undefined") {
            if (nextProps.success) {
                this.props.dispatch(FloorActions.list(nextProps.floor.location_id));
                this.props.functions.toggleFloorForm();
            }
        }

        if (nextProps.floor) {
            this.setState({
                floor: nextProps.floor
            });
        }

        if (nextProps.error) {
            this.setState({
                error: nextProps.error
            })
        }
    }

    render() {
        return(
            <div className={"modal-container"}>
                <div className={"modal-like-wrapper"}>
                    <div className={"modal-like-header"}>
                        { this.state.floor.id ? Intl("Modifica") + " " + this.state.floor.name : Intl("Nuova planimetria")}
                    </div>
                    <div className={"modal-like-body"}>
                        <Input type={"text"} name={"name"} label={Intl("Nome planimetria")} value={this.state.floor.name} onChange={this.handleForm} error={this.state.error}/>
                        <Input type={"text"} name={"level"} label={Intl("Livello/Piano")} value={this.state.floor.level} onChange={this.handleForm} error={this.state.error}/>
                        <Input type={"file"} name={"plan"} label={Intl("Planimetria")} value={this.state.floor.plan} onChange={this.handleForm} error={this.state.error}/>
                        <Input type={"checkbox"} name={"electronic_forbidden"} placeholder={Intl("Apparati elettronici vietati")} value={this.state.floor.electronic_forbidden} error={this.state.error} onChange={() => {
                            this.setState({
                                floor: {
                                    ...this.state.floor,
                                    electronic_forbidden: !this.state.floor.electronic_forbidden
                                }
                            });
                        }}/>
                        <div className={"row form-group"}>
                            <div className={"col-12 text-center"}>
                                { this.state.floor.id ? <img src={this.state.floor.image_url} height={"300"}/> : "" }
                            </div>
                        </div>
                    </div>
                    <div className={"modal-like-footer"}>
                        <button className={"btn btn-sm btn-secondary"} onClick={this.props.functions.toggleFloorForm}>{ Intl("Annulla") }</button>
                        <button className={"btn btn-sm btn-primary float-right"} onClick={
                            (e) => {
                                this.sendForm(e);
                            }
                        }>{ Intl("Salva") }</button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { floor, success, error } = state.Floor;

    return {
        floor,
        success,
        error
    }
}

export default connect(mapStateToProps)(FloorItemForm);
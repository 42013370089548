import React, { Component } from 'react';
import { connect } from 'react-redux';
import {LocationActions} from "../../actions/LocationActions";

class LocationsMenu extends Component {
    constructor(props) {
        super(props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location) {
            this.props.dispatch(LocationActions.list(nextProps.location.customer_id))
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

function mapStateToProps(state) {
    const { location, locations } = state.Location;

    return {
        location,
        locations
    }
}

export default connect(mapStateToProps)(LocationsMenu);
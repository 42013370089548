import {ErrorHandler} from "../helpers/ErrorHandler";
import {Request} from "../helpers/Request";

export const SubitemService = {
    list,
    save,
    show,
    remove
}

function save(data) {
    return Request('subitems', 'POST', data)
        .then(ErrorHandler)
        .then(
            subitem => {
                return subitem;
            }
        )
}

function list() {
    return Request('subitems', 'GET')
        .then(ErrorHandler)
        .then(
            subitems => {
                return subitems;
            }
        );
}

function show(id) {
    return Request('subitems/' + id, 'GET')
        .then(ErrorHandler)
        .then(
            subitem => {
                return subitem;
            }
        );
}

function remove(id) {
    return Request('subitems/' + id, 'DELETE')
        .then(ErrorHandler)
        .then(
            subitem => {
                return subitem;
            }
        );
}
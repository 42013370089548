import { combineReducers } from 'redux';
import {GmapsApi} from "../components/gmaps/reducers/GmapsApiReducer";
import { App } from "../reducers/AppReducer";
import {Auth} from "../reducers/AuthReducer";
import {Location} from "../reducers/LocationReducer";
import {Floor} from "../reducers/FloorReducer";
import {Item} from "../reducers/ItemReducer";
import {Category} from "../reducers/CategoryReducer";
import {Customer} from "../reducers/CustomerReducer";
import {FloorItem} from "../reducers/FloorItemReducer";
import {Subitem} from "../reducers/SubitemReducer";
import {Validation} from "../reducers/ValidationReducer";
import {User} from "../reducers/User";
import {Role} from "../reducers/Role";
import {Operation} from "../reducers/OperationReducer";
import {HoursOff} from "../reducers/HoursOffReducer";
import {Documents} from "../reducers/DocumentsReducer";

const RootReducer = combineReducers({
    Auth,
    App,
    Customer,
    GmapsApi,
    Location,
    Floor,
    Item,
    Category,
    FloorItem,
    Subitem,
    Validation,
    User,
    Role,
    Operation,
    HoursOff,
    Documents
});

export default RootReducer;
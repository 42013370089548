import React, { Component } from 'react';

class Availability extends Component {
    render() {
        return (
            <form>
                <h4>Availability</h4>
                <div className={"form-group"}>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="1"/>
                        <label className="form-check-label">Mo</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="2"/>
                        <label className="form-check-label">Tu</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="3"/>
                        <label className="form-check-label">We</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="4"/>
                        <label className="form-check-label">Th</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="5"/>
                        <label className="form-check-label">Fr</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="6"/>
                        <label className="form-check-label">Sa</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" value="0"/>
                        <label className="form-check-label">Su</label>
                    </div>
                </div>
            </form>
        );
    }
}

export default Availability;
import {SubitemService} from "../services/SubitemService";
import {ItemService} from "../services/ItemService";

export const SubitemActions = {
    list,
    save,
    show,
    empty,
    remove
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        SubitemService.save(data)
            .then(
                subitem => {
                    dispatch(success(subitem));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }


    function request(data) { return { type: "SUBITEM_SAVE_REQUEST", data} };
    function success(subitem) { return {type: "SUBITEM_SAVE_SUCCESS", subitem } };
    function failure(error) { return {type: "SUBITEM_SAVE_FAILURE", error } };
}

function empty() {
    const subitem = {
        name: "",
        code: "",
        category_id: "",
        price: "",
        sub_subitems: [],
        checks: [],
        replacement_time: 0,
        maintainance_time: 0,
        description: ""
    }

    return dispatch => {
        dispatch(success(subitem));
    }

    function success(subitem) { return {type: "SUBITEM_NEW", subitem } };
}

function list() {
    return dispatch => {
        dispatch(request());
        SubitemService.list()
            .then(
                subitems => {
                    dispatch(success(subitems));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "SUBITEMS_LIST_REQUEST"} };
    function success(subitems) { return {type: "SUBITEMS_LIST_SUCCESS", subitems } };
    function failure(error) { return {type: "SUBITEMS_LIST_FAILURE", error } };
}

function show(id) {
    return dispatch => {
        dispatch(request());
        SubitemService.show(id)
            .then(
                subitem => {
                    dispatch(success(subitem));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "SUBITEM_REQUEST"} };
    function success(subitem) { return {type: "SUBITEM_SUCCESS", subitem } };
    function failure(error) { return {type: "SUBITEM_FAILURE", error } };
}

function remove(id) {
    return dispatch => {
        dispatch(request());
        SubitemService.remove(id)
            .then(
                subitem => {
                    dispatch(success(subitem));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "SUBITEM_REMOVE_REQUEST"} };
    function success(subitem) { return {type: "SUBITEM_REMOVE_SUCCESS", subitem } };
    function failure(error) { return {type: "SUBITEM_REMOVE_FAILURE", error } };
}
export function FloorItem(state = {}, action) {
    switch(action.type) {
        case "FLOOR_ITEMS_LIST_REQUEST":
            return {
                loading: true
            };
        case "FLOOR_ITEMS_LIST_SUCCESS":
            return {
                loading: false,
                floorItems: action.floorItems
            };
        case "FLOOR_ITEMS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "FLOOR_ITEM_NEW":
            return {
                floorItem: action.floorItem,
                error: {}
            };
        case "FLOOR_ITEM_RESOURCE_REQUEST":
            return {
                loading: true
            };
        case "FLOOR_ITEM_RESOURCE_SUCCESS":
            return {
                loading: false,
                floorItem: action.floorItem
            };
        case "FLOOR_ITEM_RESOURCE_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "FLOOR_ITEM_SAVE_REQUEST":
            return {
                loading: true
            };
        case "FLOOR_ITEM_SAVE_SUCCESS":
            return {
                loading: false,
                floorItem: action.floorItem,
                success: true
            };
        case "FLOOR_ITEM_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error,
                success: false
            };
        default:
            return state;
    }
}

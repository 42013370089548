import React, { Component } from 'react';

class InputGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="input-group">
                { !this.props.prepend ? "" :
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            { this.props.prependIconKey ?
                                <i className={"fas fa-" + this.props.prependIconKey}/>
                                :
                                this.props.prependText
                            }
                        </span>
                    </div>
                }
                { this.props.children }
                { !this.props.append ? "" :
                    <div className="input-group-append">
                        <span className="input-group-text">
                            { this.props.appendIconKey ?
                                <i className={"fas fa-" + this.props.appendIconKey}/>
                                :
                                this.props.appendText
                            }
                        </span>
                    </div>
                }
            </div>
        );
    }
}

export default InputGroup;
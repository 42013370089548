import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerForm from "../components/CustomerForm";
import LocationForm from "../components/LocationForm";
import FormHeader from "../../../components/FormHeader/FormHeader";
import {QueryString} from "../../../helpers/QueryString";
import Intl from '../../../helpers/Intl';
import InvoicesItems from "../components/InvoicesItems";
import {AppActions} from "../../../actions/AppActions";
class CustomerStore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "general"
        }

        this.partials = {
            general: <CustomerForm/>,
            locations: <LocationForm/>,
            invoices: <InvoicesItems/>
        }

        this.switchTab = this.switchTab.bind(this);
    }

    componentDidMount() {
        if (QueryString.get('tab')) {
            this.setState({
                tab: QueryString.get('tab')
            })
        } else {
            this.setState({
                tab: 'general'
            })
        }
    }

    switchTab(tab) {
        this.setState({
            tab: tab
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer,
                tab: "general"
            });
            AppActions.goTo({
                page: 'customers/edit',
                params: {
                    customerId: nextProps.customer.id
                }
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return Boolean(nextProps.customer);
    }

    render() {
        return (
            <div className={"customer-form-container customer-form-with-tabs"}>
                <FormHeader
                    title={this.props.customer && this.props.customer.name.length ? this.props.customer.name : Intl("Nuovo cliente") }
                    subtitle={this.props.customer && this.props.customer.address ? this.props.customer.address.formatted_address : ''}
                    redirectTo={"/customers"}
                />
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={"nav-link " + (this.state.tab === "general" ? "active" : "")} href="#" onClick={(e) => {
                            e.preventDefault();
                            this.switchTab("general")
                        }}>{ Intl("Info generali") }</a>
                    </li>
                    { this.props.customer && this.props.customer.id ?
                        <React.Fragment>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.state.tab === "locations" ? "active" : "")} href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.switchTab("locations")
                                }}>{ Intl("Sedi fisiche") }</a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.state.tab === "invoices" ? "active" : "")} href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.switchTab("invoices")
                                }}>{ Intl("Fatture") }</a>
                            </li>
                        </React.Fragment>
                        : null }
                </ul>
                <div className={"form-container"}>
                    { this.partials[this.state.tab] }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { customer } = state.Customer;

    return {
        customer
    }
}

export default connect(mapStateToProps)(CustomerStore);
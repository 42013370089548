import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./styles.scss";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import {ValueConverter} from "../../helpers/ValueConverter";

class FyriScheduler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.events) {
            const events = [];
            Object.keys(nextProps.events).map((userId) => {
                const color = ValueConverter.generateColorCode();
                nextProps.events[userId].map((event, index) => {
                    console.log(event)
                    let transportStart = moment(event.schedule + " 9:00:00").format("YYYY-MM-DD HH:mm");
                    let transportEnd = moment(transportStart).add(event.transport_duration, 'seconds').format("YYYY-MM-DD HH:mm");
                    let start = moment(transportEnd).format("YYYY-MM-DD HH:mm");
                    let end = moment(start).add(event.location.operationEstimatedTime, "minutes").format("YYYY-MM-DD HH:mm");

                    if (index > 0) {
                        transportStart = moment(events[index-1].end)
                        transportEnd = transportStart.add(event.transport_duration, 'seconds');
                        start = transportEnd;
                        end = transportEnd.add(event.event_duration, "minutes");
                    }
                    events.push({
                        title: event.user.fullName + ' drive',
                        start: transportStart,
                        end: transportEnd,
                        color: event.user.color,
                        textColor: '#ffffff',
                    });
                    events.push({
                        title: event.user.fullName + ' ' + event.location.customer.name + '@' + event.location.name,
                        start: start,
                        end: end,
                        color: event.user.color,
                        textColor: '#ffffff',
                        url: "/operations?schedule=" + event.schedule + "&userId=" + userId
                    });
                });
            })
            this.setState({
                events: events
            })
        }
    }

    render() {
        console.log(this.state.events)
        return(
            <div className="calendar-wrapper m-1">
                <FullCalendar
                    defaultView={ this.props.view ? this.props.view : "timeGridWeek"}
                    header={{left: 'title'}}
                    plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                    dateClick={this.handleDayClick}
                    events={this.state.events}
                />
            </div>
        )
    }

    handleDayClick(day) {
        console.log(day)
    }
}

function mapStateToProps(state) {
    const { events } = state.Operation;

    return {
        events
    }
}

export default connect(mapStateToProps)(FyriScheduler);
import React, { Component } from 'react';
import ValidationsList from "./components/ValidationsList";
import {Link} from "react-router-dom";
import Intl from "../../helpers/Intl";
import { connect } from 'react-redux';
import {ValidationActions} from "../../actions/ValidationActions";

class ValidationsMenu extends Component {
    constructor(props) {
        super(props);

        this.edit = this.edit.bind(this);
        this.newValidation = this.newValidation.bind(this);
        this.deleteValidation = this.deleteValidation.bind(this);

        this.functions = {
            editItem: this.edit,
            newItem: this.newValidation,
            deleteValidation: this.deleteValidation
        };
    }

    edit(validationId) {
        this.props.dispatch(ValidationActions.show(validationId))
    }

    newValidation() {
        this.props.dispatch(ValidationActions.empty());
    }

    deleteValidation(validationId) {
        this.props.dispatch(ValidationActions.remove(validationId));
    }

    render() {
        return (
            <div className="validations-menu">
                <div className={"validations-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <Link to={"/validations/add"} className={"btn btn-primary btn-sm"} onClick={this.newValidation}>
                                <i className={"far fa-plus mr-2"}/>
                                { Intl("Nuovo") }
                            </Link>
                        </div>
                        <div className={"col-6"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type={"text"} className={"form-control form-control-sm"}
                                           placeholder={ Intl("Cerca...") }/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Controlli") }</h4>
                        </div>
                    </div>
                </div>
                <ValidationsList functions={this.functions}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(ValidationsMenu);
import {FloorItemService} from "../services/FloorItemService";

export const FloorItemActions = {
    list,
    save,
    show,
    create,

}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        FloorItemService.save(data)
            .then(
                floorItem => {
                    dispatch(success(floorItem));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    }


    function request(data) { return { type: "FLOOR_ITEM_SAVE_REQUEST", data} };
    function success(floorItem) { return {type: "FLOOR_ITEM_SAVE_SUCCESS", floorItem } };
    function failure(error) { return {type: "FLOOR_ITEM_SAVE_FAILURE", error } };
}

function create(data) {
    const floorItem = {
        item_id: data.itemId,
        item: data,
        floor_id: data.floorId,
        position: "",
        installation_date: null,
        last_mantainance_date: null,
        manufacturing_date: null,
        lat: data.lat,
        lng: data.lng

    }

    return dispatch => {
        dispatch(success(floorItem));
    }

    function success(floorItem) { return {type: "FLOOR_ITEM_NEW", floorItem } };
}

function list(floorId) {
    return dispatch => {
        dispatch(request());
        FloorItemService.list(floorId)
            .then(
                floorItems => {
                    dispatch(success(floorItems));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "FLOOR_ITEMS_LIST_REQUEST"} };
    function success(floorItems) { return {type: "FLOOR_ITEMS_LIST_SUCCESS", floorItems } };
    function failure(error) { return {type: "FLOOR_ITEMS_LIST_FAILURE", error } };
}

function show(id) {
    return dispatch => {
        dispatch(request());
        FloorItemService.show(id)
            .then(
                floorItem => {
                    dispatch(success(floorItem));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "FLOOR_ITEM_RESOURCE_REQUEST"} };
    function success(floorItem) { return {type: "FLOOR_ITEM_RESOURCE_SUCCESS", floorItem } };
    function failure(error) { return {type: "FLOOR_ITEM_RESOURCE_FAILURE", error } };
}
import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const LocationService = {
    all,
    list,
    save,
    item,
    remove
};

function list(customerId) {
    return Request("location", "GET", undefined, {customerId: customerId})
        .then(ErrorHandler)
        .then(
            locations => {
                return locations;
            }
        );
}

function all() {
    return Request("location", "GET")
        .then(ErrorHandler)
        .then(
            locations => {
                return locations;
            }
        );
}

function item(locationId) {
    return Request("location/" + locationId, "GET", undefined)
        .then(ErrorHandler)
        .then(
            location => {
                return location;
            }
        );
}

function save(data) {
    let method = "POST";
    let endpoint = "location";
    if (data.id) {
        method = "PUT";
        endpoint += "/" + data.id;
    }

    console.log(JSON.stringify(data));

    return Request(endpoint, method, data)
        .then(ErrorHandler)
        .then(
            location => {
                return location;
            }
        );
}

function remove(locationId) {
    return Request("location/" + locationId, "DELETE")
        .then(ErrorHandler)
        .then(
            deleted => {
                return deleted;
            }
        );
}
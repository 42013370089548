import {DocumentService} from "../services/DocumentService";

export const DocumentActions = {
    list,
    save
}

function list() {
    return dispatch => {
        dispatch(request());
        DocumentService.list()
            .then(
                documents => {
                    dispatch(success(documents))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request() { return { type: "DOCUMENTS_LIST_REQUEST"}; }
    function success(documents) { return { type: "DOCUMENTS_LIST_SUCCESS", documents }; }
    function failure(error) { return { type: "DOCUMENTS_LIST_FAILURE", error }; }
}

function save(data) {
    return dispatch => {
        dispatch(request());
        DocumentService.save(data)
            .then(
                documentItem => {
                    dispatch(success(documentItem))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request() { return { type: "DOCUMENT_SAVE_REQUEST"}; }
    function success(documentItem) { return { type: "DOCUMENT_SAVE_SUCCESS", documentItem }; }
    function failure(error) { return { type: "DOCUMENT_SAVE_FAILURE", error }; }
}
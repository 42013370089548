import React, { Component } from 'react';

class Dossier extends Component {
    render() {
        return(
            <form className={"mt-4"}>
                <h4>Personal dossier</h4>
                <div className={"form-group"}>
                    <button className={"btn btn-primary btn-sm"}>
                        <i className={"fal fa-plus mr-1"}/>
                        Add
                    </button>
                </div>
            </form>
        );
    }
}

export default Dossier;
import React, { Component} from 'react';
import { connect } from 'react-redux';
import { allow } from "../../helpers/Auth";
import {Link} from "react-router-dom";

class PermissionButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    render() {
        return(
            <React.Fragment>
            { this.props.profile && allow(this.props.roles, this.props.profile.roles) ?
                [
                    this.props.type === "link" ?
                        <Link to={this.props.to} onClick={this.handleClick} className={this.props.className}>{ this.props.children }</Link>
                    :
                        <button onClick={this.handleClick} className={this.props.className}>{ this.props.children }</button>
                ]
            : null }
            </React.Fragment>
        );
    }

    handleClick(e) {
        if (this.props.prevent === true) {
            e.preventDefault();
        }
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
}

function mapStateToProps(state) {
    const { profile } = state.Auth;

    return {
        profile
    }
}

export default connect(mapStateToProps)(PermissionButton);
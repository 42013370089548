import React, { Component } from 'react';
import { connect } from 'react-redux';
import {LocationActions} from "../../../actions/LocationActions";
import {QueryString} from "../../../helpers/QueryString";
import LocationItem from "./LocationItem";
import FloorItem from "./FloorItem";
import LocationItemForm from "./LocationItemForm";
import FloorItemForm from "./FloorItemForm";
import {FloorActions} from "../../../actions/FloorActions";
import Intl from "../../../helpers/Intl";

class LocationForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
            showLocationForm: false,
            editLocationId: undefined,
            showFloorForm: false
        }

        this.renderLocations = this.renderLocations.bind(this);
        this.toggleLocationForm = this.toggleLocationForm.bind(this);
        this.toggleFloorForm = this.toggleFloorForm.bind(this);
        this.handleLocationId = this.handleLocationId.bind(this);
        this.handleFloorId = this.handleFloorId.bind(this);
        this.removeFloor = this.removeFloor.bind(this);
        this.removeLocation = this.removeLocation.bind(this);

        this.functions = {
            toggleLocationForm: this.toggleLocationForm,
            toggleFloorForm: this.toggleFloorForm,
            removeFloor: this.removeFloor,
            removeLocation: this.removeLocation,
            handleLocationId: this.handleLocationId,
            handleFloorId: this.handleFloorId
        }
    }

    removeFloor(floorId) {
        this.props.dispatch(FloorActions.remove(floorId));
    }

    removeLocation(locationId) {
        this.props.dispatch(LocationActions.remove(locationId));
    }

    toggleLocationForm(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            showLocationForm: !this.state.showLocationForm
        })
    }

    toggleFloorForm(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            showFloorForm: !this.state.showFloorForm
        })
    }

    handleLocationId(editId) {
        this.setState({
            editLocationId: editId
        })
    }

    handleFloorId(editId) {
        this.setState({
            editFloorId: editId
        })
    }

    componentDidMount() {
        let customerId = QueryString.get('customerId');
        console.log(customerId)
        this.props.dispatch(LocationActions.list(customerId))
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customer) {
            this.setState({
                locations: nextProps.customer.locations
            })
        }

        if (nextProps.locations) {
            this.setState({
                locations: nextProps.locations
            })
        }

        if (nextProps.deletedLocation) {
            this.props.dispatch(LocationActions.list(QueryString.get('customerId')))
        }

        if (nextProps.floors && nextProps.locationId) {
            const locations = [];
            this.state.locations.map((location) => {
                if (location.id === nextProps.locationId) {
                    location['floors'] = nextProps.floors;
                }

                locations.push(location);
            })
            this.setState({
                locations: locations
            })
        }
    }

    renderLocations() {
        const locations = [];
        this.state.locations.map((location, index) => {
            locations.push(
                <LocationItem key={index} info={location} functions={this.functions}/>
            )
        })

        return locations;
    }

    render() {
        return(
            <div className={"locations-container"}>
                <div className={"locations-header"}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <span>{ Intl("Totale") }: <b>{ this.state.locations.length } { Intl("sedi") }</b></span>
                        </div>
                        <div className={"col-4 offset-4 text-right"}>
                            <button className={"btn btn-sm btn-primary"} onClick={
                                (e) => {
                                    e.preventDefault();
                                    this.toggleLocationForm(e);
                                    this.handleLocationId(undefined);
                                }
                            }>
                                <i className={"far fa-plus mr-2"}/>
                                { Intl("Aggiungi sede") }
                            </button>
                        </div>
                    </div>
                </div>
                { this.renderLocations() }
                { this.state.showLocationForm ? <LocationItemForm functions={this.functions} locationId={this.state.editLocationId} customerId={QueryString.get('customerId')}/> : "" }
                { this.state.showFloorForm ? <FloorItemForm functions={this.functions} floorId={this.state.editFloorId} locationId={ this.state.editLocationId }/> : "" }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { customer } = state.Customer;
    const { locations, deletedLocation } = state.Location;
    const { floors, locationId, deletedFloor } = state.Floor;

    return {
        customer,
        locations,
        floors,
        locationId,
        deletedFloor,
        deletedLocation
    }
}

export default connect(mapStateToProps)(LocationForm);
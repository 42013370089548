import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import FormAbstract from "../../../abstract/FormAbstract";
import Input from "../../../components/Input/Input";
import Intl from "../../../helpers/Intl";
import Select from "react-select";
import {QueryString} from "../../../helpers/QueryString";
import moment from "moment";
import {HoursOffActions} from "../../../actions/HoursOffActions";

class HoursOff extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "hoursOff"

        this.state = {
            hoursOffList: [],
            hoursOff: {
                start: null,
                end: null
            },
            showForm: false
        }

        this.toggleForm = this.toggleForm.bind(this);
        this.deleteHoursOff = this.deleteHoursOff.bind(this);

        this.functions = {
            handleSelect: this.handleSelect,
            sendForm: this.sendForm,
            handleForm: this.handleForm,
            handleDate: this.handleDate,
            toggleForm: this.toggleForm,
            deleteHoursOff: this.deleteHoursOff
        }
    }

    toggleForm(e) {
        e.preventDefault();
        this.setState({
            showForm: !this.state.showForm
        })
    }

    componentDidMount() {
        this.setState({
            hoursOff: {
                ...this.state.hoursOff,
                user_id: QueryString.get('userId')
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.hoursOffList) {
            this.setState({
                hoursOffList: nextProps.hoursOffList
            })
        }
    }

    render() {
        return (
            <div>
                <h4>
                    Holidays and hours off
                    { !this.state.showForm || !this.state.hoursOffList.length ?
                        <button className={"btn btn-primary ml-4"} onClick={this.toggleForm}>
                            <i className={"fal fa-plus mr-2"}/>
                            { Intl("Nuovo") }
                        </button>
                        : null }
                </h4>
                { this.state.showForm ?
                    <HoursOffForm functions={this.functions} hoursOff={this.state.hoursOff} error={this.props.error}/>
                    :
                    <HoursOffList hoursOff={this.state.hoursOffList} functions={this.functions}/>
                }
                { !this.state.hoursOffList.length && !this.state.showForm ? <span>No hours off registered for this employee</span> : null }
            </div>
        );
    }

    deleteHoursOff(itemId) {
        this.props.dispatch(HoursOffActions.remove(itemId, QueryString.get('userId')));
    }
}

function mapStateToProps(state) {
    const { hoursOff, hoursOffList, error } = state.HoursOff;
    return {
        hoursOff,
        hoursOffList,
        error
    }
}

export default connect(mapStateToProps)(HoursOff);

export class HoursOffList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirm: undefined
        }

        this.toggleDeleteConfirm = this.toggleDeleteConfirm.bind(this);
    }

    toggleDeleteConfirm(itemId) {
        this.setState({
            showDeleteConfirm: itemId
        })
    }

    renderList() {
        const hoursOffItems = [];
        if (this.props.hoursOff) {
            this.props.hoursOff.map((item, index) => {
                hoursOffItems.push(
                    <div className={"card mb-2"} key={item.id}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <small className={"d-block"}>Start</small>
                                    {moment(item.start).format('Do MMM YYYY HH:mm')}
                                </div>
                                <div className={"col-4"}>
                                    <small className={"d-block"}>End</small>
                                    {moment(item.end).format('Do MMM YYYY HH:mm')}
                                </div>
                                <div className={"col-2 offset-2 text-center"}>
                                    <div className={"mt-3"}>
                                        <a href={"#"} className={"mr-2"}>
                                            <i className={"fal fa-pencil-alt"}/>
                                        </a>
                                        <a href={"#"} className={"mr-2"} onClick={(e) => {
                                            e.preventDefault();
                                            this.toggleDeleteConfirm(item.id)
                                        }}>
                                            <i className={"fal fa-trash-alt"}/>
                                        </a>
                                    </div>
                                </div>
                                <div className={"col-12 mt-2"}>
                                    <small className={"d-block"}>Reason</small>
                                    {item.reason}
                                </div>
                            </div>
                        </div>
                        { this.state.showDeleteConfirm === item.id ?
                            <div className={"card-footer"}>
                                <span>Are you sure you want to delete this?</span>
                                <button className={"btn btn-danger btn-sm float-right mr-2"} onClick={(e) => {
                                    e.preventDefault();
                                    this.props.functions.deleteHoursOff(item.id)
                                }}>Delete</button>
                                <button className={"btn btn-secondary btn-sm float-right mr-2"} onClick={this.toggleDeleteConfirm}>No</button>
                            </div>
                            : null
                        }
                    </div>
                );
            })
        }

        return hoursOffItems;
    }

    render() {
        return (
            <div clasName="clearfix">
                { this.renderList() }
            </div>
        );
    }
}

export class HoursOffForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoursOffTypes: [
                {
                    id: "long-holidays",
                    name: Intl("Long Holidays")
                },
                {
                    id: "day-off",
                    name: Intl("Day Off")
                },
                {
                    id: "hours-off",
                    name: Intl("Hours Off")
                },
            ]
        }
    }
    render() {
        return (
            <form className={"mt-4"} onSubmit={this.props.functions.sendForm}>
                <div className={"form-group"}>
                    <div className="row">
                        <div className="col-5">
                            <label className={"d-block"}>Start Day</label>
                            <DatePicker
                                className={'form-control'}
                                selected={this.props.hoursOff.start}
                                placeholderText={"Start"}
                                showTimeSelect={true}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                minDate={new Date()}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={(day) => {
                                    this.props.functions.handleDate(day, 'start')
                                }}
                            />
                            { this.props.error && this.props.error.start ? <p className={"error"}>{ this.props.error.start[0]}</p> : "" }
                        </div>
                        <div className={"col-5 offset-2"}>
                            <label className={"d-block"}>End Day</label>
                            <DatePicker
                                className={'form-control'}
                                selected={this.props.hoursOff.end}
                                placeholderText={"End"}
                                showTimeSelect={true}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                minDate={this.props.hoursOff.start_day || new Date()}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                onChange={(day) => {
                                    this.props.functions.handleDate(day, 'end')
                                }}
                            />
                            { this.props.error && this.props.error.end ? <p className={"error"}>{ this.props.error.end[0]}</p> : "" }
                        </div>
                        <div className={"col-12 mt-2"}>
                            <label className={"d-block"}>Type</label>
                            <Select
                                options={this.state.hoursOffTypes}
                                isSearchable={true}
                                isMulti={false}
                                getOptionLabel={option => option.name}
                                getOptionValue={option => option.id}
                                onChange={(hoursOffType) => {
                                    this.props.functions.handleSelect(hoursOffType, 'type')
                                }}
                            />
                            { this.props.error && this.props.error.type ? <p className={"error"}>{ this.props.error.type[0]}</p> : "" }
                        </div>
                    </div>
                    <div className={"form-group mt-2"}>
                        <label>Notes</label>
                        <Input type={"textarea"} name={"reason"} error={this.props.error} onChange={this.props.functions.handleForm}/>
                    </div>
                </div>
                <a href={"#"} className={"btn btn-secondary"} onClick={this.props.functions.toggleForm}>
                    <i className={"fal fa-times mr-2"}/>
                    Cancel
                </a>
                <button type={"submit"} className={"btn btn-primary ml-2 float-right"}>
                    <i className={"fal fa-plus mr-1"}/>
                    { Intl("Salva") }
                </button>
            </form>
        );
    }
}
import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const FloorService = {
    list,
    item,
    save,
    remove
};

function list(locationId) {
    return Request("floor", "GET", undefined, {locationId: locationId})
        .then(ErrorHandler)
        .then(
            floors => {
                return floors;
            }
        );
}

function item(floorId) {
    return Request("floor/" + floorId, "GET")
        .then(ErrorHandler)
        .then(
            floorItem => {
                return floorItem;
            }
        );
}

function save(data) {
    let method = "POST";
    let endpoint = "floor";

    return Request(endpoint, method, data, undefined, {}, "multipart")
        .then(ErrorHandler)
        .then(
            floor => {
                return floor;
            }
        );

}

function remove(floorId) {
    return Request("floor/" + floorId, "DELETE")
        .then(ErrorHandler)
        .then(
            deleted => {
                return deleted;
            }
        );
}
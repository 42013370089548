export const en = {
    // General translations
    "Modifica": "Edit",
    "Pianifica intervento": "Add to calendar",
    "Annulla": "Cancel",
    "Salva": "Save",
    "Cerca su Fyri": "Search inside Fyri",
    "Cerca tra i clienti": "Search customer",
    "Cerca tra gli apparati": "Search items",
    "Apparati": "Items",
    "Cognome": "Last Name",
    "Nome": "First Name",
    "Nome sede": "Location name",
    "Nome articolo": "Product name",
    "Codice": "Code",
    "Tipo": "Type",
    "Prezzo": "Price",
    "Parti": "Parts",
    "Controlli": "Audit",
    "Tempo di sostituzine": "Replacement time",
    "Tempo di manutenzione": "Mantainence time",
    "Descrizione": "Description",
    "Clienti": "Customers",
    "Fatture": "Finance",
    "Documenti": "Documents",
    "Interventi": "Operations",
    "Pianificazione": "Planning",
    "Live timeline": "Live timeline",
    "Risorse umane": "Employees",
    "Notifiche": "Notifications",
    "Settings": "Settings",
    "Generale": "Main",
    "Operativo": "Operational",
    "Tecnico": "Technical",
    "Amministrazione": "Admin",
    "Nuovo": "New",
    "Ragione Sociale": "Company name",
    "Telefono": "Phone",
    "Fax": "Fax",
    "Sito web": "Website",
    "Sconto %": "Discount %",
    "Indirizzo": "Address",
    "Partita IVA": "VAT number",
    "IVA %": "VAT %",
    "Indirizzo corrispondenza": "Mail address",
    "Info generali": "General details",
    "Nuovo cliente": "New customer",
    "Nuovo apparato": "New item",
    "Nuova parte": "New part",
    "Totale": "Total",
    "sedi": "locations",
    "Anno": "Year",
    "Anni": "Years",
    "Mesi": "Months",
    "Giorni": "Days",
    "Ore": "Hours",
    "Minuti": "Minutes",
    "Gestione risorsa": "Employee management",
    "Sedi": "Locations",
    "Sedi fisiche": "Locations",
    "Interventi pianificati per oggi": "Today's operations",
    "Rimuovi selezione": "Clear selection",
    "Responsabile operazioni": "Operations coordinator",
    "Operatore": "Operator",
    "Tu": "You",
    "Cerca...": "Search...",
    "Dettagli fatturazione": "Finance details",
    "Responsabile Sito / RSPP": "Health and Safety Officer",
    "Aggiungi sede": "Add location",
    "planimetrie": "floor plans",
    "Aggiungi planimetria": "Add floor plan",
    "Annulla intervento": "Remove schedule",
    "Nuovo controllo": "New validation",
    "Sedi selezionate": "Selected locations",
    "Seleziona operatore": "Choose operator",
    "Apparati elettronici vietati": "Electronic devices forbidden",
    "Nessuna sede selezionata": "No location selected",
    "Nome controllo": "Audit name",
    "Periodo di controllo": "Audit time range",
    "Azione necessaria": "Required action",
    "Categorie riguardanti": "Affected categories",
    "Mantenimento": "Maintenance",
    "Sostituzione": "Replacement",
    "Seleziona": "Choose",
    "Posizione di riferimento": "Position of reference",
    "su planimetria": "on floor plan",
    "Data installazione": "Installation date",
    "Data fabbricazione": "Manifacture date",

}
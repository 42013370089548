import {Request} from "../../../helpers/Request";
import {ErrorHandler} from "../../../helpers/ErrorHandler";

export const GmapsApiService = {
    searchAddress,
    getPosition
}

function getPosition() {
    let position = {};
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((userLocation) => {
            position = {
                lat: userLocation.coords.latitude,
                lng: userLocation.coords.longitude
            };
        });
    }

    return position;
}

function searchAddress(searchText) {
    const params = {
        searchText: searchText
    };
    return Request('gmaps/searchAddress', "GET", undefined, params)
        .then(ErrorHandler)
        .then(
            addressList => {
                return addressList;
            }
        );
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FloorItemActions} from "../../actions/FloorItemActions";
import "./styles.scss";
import {Link} from "react-router-dom";
import {QueryString} from "../../helpers/QueryString";
import FloorConfigureItemForm from "./components/FloorConfigureItemForm";
import {ItemActions} from "../../actions/ItemActions";
import {LeafletMap} from "../../plugins/Leaflet/Leaflet";
import Intl from "../../helpers/Intl";

class FloorMenu extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            floorItems: [],
            floor: null
        };

        this.newFloorItem = this.newFloorItem.bind(this);

        this.functions = {
            newFloorItem: this.newFloorItem
        }
    }

    componentWillMount() {
        this.props.dispatch(ItemActions.list())
    }

    printFloorItems() {
        const iframe = document.frames ? document.frames['floor-file'] : document.getElementById('floor-file')
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();

        return false;

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.items) {
            this.setState({
                items: nextProps.items
            })
        }

        if (nextProps.floor) {
            this.setState({
                floor: nextProps.floor
            })
        }
    }

    newFloorItem(item) {
        // LeafletMap.createAnonymousMarker(item)
        const mapCenter = LeafletMap.getMap().getCenter();
        console.log(mapCenter);
        const floorItem = {
            itemId: item.id,
            name: item.name,
            floorId: QueryString.get('floorId'),
            lat: mapCenter.lat,
            lng: mapCenter.lng
        }
        this.props.dispatch(FloorItemActions.create(floorItem, QueryString.get('floorId')))
    }

    render() {
        return (
            <div className="items-menu">
                <div className={"items-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <button className={"btn btn-primary btn-sm"} onClick={this.printFloorItems}>
                                <i className={"fal fa-print mr-1"}/>
                                { Intl("Esporta") }
                            </button>
                        </div>
                        <div className={"col-7"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type={"text"} className={"form-control form-control-sm"} placeholder={Intl("Cerca tra gli apparati")}/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/customers/edit?customerId=" + (this.state.floor ? this.state.floor.location.customer_id : "")} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <iframe id={"floor-file"} src={"/export/floor?floorId=" + QueryString.get('floorId')} style={{display: "none"}}></iframe>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Apparati disponibili") }</h4>
                        </div>
                    </div>
                </div>
                <FloorItems items={this.state.items} functions={this.functions}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { floorItems } = state.FloorItem;
    const { floor } = state.Floor;
    const { items } = state.Item;

    return {
        items,
        floor,
        floorItems
    }
}

export default connect(mapStateToProps)(FloorMenu);

export class FloorItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items || []
        }

        this.renderItems = this.renderItems.bind(this);
    }

    renderItems() {
        const items = [];
        this.state.items.map((item, index) => {
            items.push(
                <div className={"item-item"} key={index}>
                    <div className={"row"}>
                        <div className={"col-10"}>
                            <h5>
                                <img src={"/img/items/icons/" + item.category.image} height={"28"} className={"float-left mr-2"}/>
                                { item.name }
                            </h5>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"#"} onClick={
                                (e) => {
                                    e.preventDefault();
                                    this.props.functions.newFloorItem(item)
                                }}>
                                <span className={"fa fa-stack"}>
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-stack-1x fa-inverse fa-plus"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        })

        return items;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.items && nextProps.items.length) {
            this.setState({
                items: nextProps.items
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.items) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <div className={"items-menu-wrapper"}>
                { this.renderItems() }
            </div>
        );
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from "../../../components/modal/Modal";
import ModalHeader from "../../../components/modal/ModalHeader";
import ModalBody from "../../../components/modal/ModalBody";
import Input from "../../../components/Input/Input";
import DatePicker from "react-datepicker";
import ModalFooter from "../../../components/modal/ModalFooter";
import FormAbstract from "../../../abstract/FormAbstract";
import {FloorItemActions} from "../../../actions/FloorItemActions";
import QRCode from 'qrcode.react';
import ReactToPrint from "react-to-print";
import Intl from "../../../helpers/Intl";


class FloorConfigureItemForm extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "floorItem";

        this.state = {
            floorItem: this.props.floorItem
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps)
        if (typeof nextProps.success !== "undefined") {
            this.props.dispatch(FloorItemActions.list(this.props.floorId))
        }

        if (nextProps.floorItem) {
            this.setState({
                floorItem: nextProps.floorItem
            })
        }

        if (nextProps.selectedPosition) {
            this.setState({
                floorItem: this.state.floorItem
            })
        }
    }

    render() {
        console.log(this.state)
        return (
            <Modal>
                <ModalHeader
                    prependIcon={this.state.floorItem && this.state.floorItem.id ? '/img/items/icons/' + this.state.floorItem.item.category.image : null}
                    title={
                        this.state.floorItem && this.state.floorItem.id ? this.state.floorItem.item.category.name + ": " + this.state.floorItem.item.name
                            : Intl("Nuovo") + " " + this.state.floorItem.item.name + " " + Intl("su planimetria")}
                />
                <ModalBody>
                    <form>
                        <Input type={"text"} value={this.state.floorItem.position} name={"position"} label={Intl("Posizione di riferimento")} onChange={this.handleForm}/>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <Input type={"date"} value={this.state.floorItem.installation_date} label={Intl("Data installazione")} name={"installation_date"} onChange={this.handleForm}/>
                            </div>
                            <div className={"col-6"}>
                                <Input type={"date"} value={this.state.floorItem.manufacturing_date} label={Intl("Data fabbricazione")} name={"manufacturing_date"} onChange={this.handleForm}/>
                            </div>
                            <Input type={"hidden"} name={"lat"} value={this.state.floorItem.lat}/>
                            <Input type={"hidden"} name={"lng"} value={this.state.floorItem.lng}/>
                        </div>
                        {this.state.floorItem.id ?
                            <React.Fragment>
                                <div className={"row"} ref={el => (this.codeToPrint = el)}>
                                    <div className={"col-12 text-center"}>
                                        <QRCode value={this.state.floorItem.id}/>
                                        <br/>
                                        <span className={"d-bloock"}>{this.state.floorItem.id}</span>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12 text-center"}>
                                        <ReactToPrint
                                            className={"btn btn-primary"}
                                            trigger={() => <a href={"#"} className={"btn btn-primary"}><i
                                                className={"fal fa-print mr-1"}/>
                                                Print</a>
                                            }
                                            content={() => this.codeToPrint}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                            : null
                        }
                    </form>
                </ModalBody>
                <ModalFooter>
                    <button className={"btn btn-secondary btn-sm"} onClick={this.props.functions.toggleFloorItemForm}>
                        <i className={"fal fa-arrow-left mr-2"}/>
                        { Intl("Annulla") }
                    </button>
                    <button className={"btn btn-primary btn-sm float-right"} onClick={this.sendForm}>
                        <i className={"fal fa-check mr-2"}/>
                        { Intl("Salva") }
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const { floorItem, success, error } = state.FloorItem;

    console.log(floorItem)

    return {
        floorItem,
        success,
        error
    }
}

export default connect(mapStateToProps)(FloorConfigureItemForm);
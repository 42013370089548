import {UserService} from "../services/UserService";

export const UserActions = {
    empty,
    list,
    item,
    save,
    remove
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        UserService.save(data)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    }


    function request(data) { return { type: "USER_SAVE_REQUEST", data} };
    function success(user) { return {type: "USER_SAVE_SUCCESS", user } };
    function failure(error) { return {type: "USER_SAVE_FAILURE", error } };
}

function empty() {
    const user = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        roles: [],
        notes: "",
        address: undefined,
        mobile: ""
    }

    return dispatch => {
        dispatch(success(user));
    }

    function success(user) { return {type: "USER_NEW", user } };
}

function list(schedule) {
    return dispatch => {
        dispatch(request());
        UserService.list(schedule)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "USERS_LIST_REQUEST"} };
    function success(users) { return {type: "USERS_LIST_SUCCESS", users } };
    function failure(error) { return {type: "USERS_LIST_FAILURE", error } };
}

function item(id) {
    return dispatch => {
        dispatch(request());
        UserService.item(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "USER_ITEM_REQUEST" }; }
    function success(user) { return { type: "USER_ITEM_SUCCESS", user }; }
    function failure(error) { return { type: "USER_ITEM_FAILURE", error }; }
}

function remove(id) {
    return dispatch => {
        dispatch(request());
        UserService.remove(id)
            .then(
                deleted => {
                    dispatch(success(deleted));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "USER_REMOVE_REQUEST" }; }
    function success(deleted) { return { type: "USER_REMOVE_SUCCESS", deleted }; }
    function failure(error) { return { type: "USER_REMOVE_FAILURE", error }; }
}
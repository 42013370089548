import React, { Component } from 'react';
import App from "../../App";
import UserMenu from "./UserMenu";
import UserStore from "./scenes/UserStore";

class User extends Component {
    constructor(props) {
        super(props);

        this.scenes = {
            add: <UserStore user={undefined}/>,
            edit: <UserStore/>
        };
    }

    render() {
        return(
            <App menu={<UserMenu/>} authRoles={['SUPER_ADMIN']}>
                { this.scenes[this.props.match.params.scene] }
            </App>
        );
    }
}

export default User;
export function GmapsApi(state = {}, action) {
    switch(action.type) {
        case "GMAPS_ADDRESS_REQUEST":
            return {
                loading: true,
                searchText: action.searchText
            };
        case "GMAPS_ADDRESS_SUCCESS":
            return {
                loading: false,
                addressList: action.addressList.results,
                inputName: action.inputName
            };
        case "GMAPS_ADDRESS_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
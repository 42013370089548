import React, { Component } from 'react';
import "./styles.scss";
import {Link} from "react-router-dom";
import Intl from "../../helpers/Intl";
import {allow} from "../../helpers/Auth";

class AppMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [
                {
                    label: "Generale",
                    roles: ["SUPER_ADMIN", "PLANNER", "OPERATOR"],
                    items: [
                        {
                            icon: "suitcase",
                            label: "Clienti",
                            url: "/customers",
                            roles: ["SUPER_ADMIN", "PLANNER"]
                        },
                        {
                            icon: "file-invoice-dollar",
                            label: "Fatture",
                            url: "/finance",
                            roles: ["SUPER_ADMIN"]
                        },
                        {
                            icon: "folder-open",
                            label: "Documenti",
                            url: "/documents",
                            roles: ["SUPER_ADMIN", "OPERATOR"]
                        }
                    ]
                },
                {
                    label: "Operativo",
                    roles: [],
                    items: [
                        {
                            icon: "calendar-check",
                            label: "Interventi",
                            url: "/operations/calendar"
                        },
                        {
                            icon: "wrench",
                            label: "Pianificazione",
                            url: "/operations",
                            roles: ["SUPER_ADMIN", "PLANNER"]
                        },
                        {
                            icon: "code-commit",
                            label: "Live timeline",
                            url: "/operations/timeline"
                        },
                    ]
                },
                {
                    label: "Tecnico",
                    roles: ["SUPER_ADMIN"],
                    items: [
                        {
                            icon: "fire-extinguisher",
                            label: "Apparati",
                            url: "/items",
                        },
                        {
                            icon: "shield-check",
                            label: "Controlli",
                            url: "/validations",
                        },
                    ]
                },
                {
                    label: "Amministrazione",
                    roles: ['SUPER_ADMIN'],
                    items: [
                        {
                            icon: "users",
                            label: "Risorse umane",
                            url: "/users",
                            roles: ['SUPER_ADMIN']
                        },
                        {
                            icon: "cogs",
                            label: "Settings",
                            url: "/settings",
                            roles: ['SUPER_ADMIN']
                        },
                    ]
                }
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={"app-menu-backdrop " + (this.props.show ? "show" : "hide")} onClick={this.props.functions.toggleMenu}></div>
                <div className={"app-menu-wrapper " + (this.props.show ? "show" : "hide")}>
                    <div className={"app-menu " + (this.props.show ? "show" : "hide")}>
                        <div className={"container no-padding"}>
                            <div className={"app-menu-header"}>
                                <div className={"row"}>
                                    <div className={"col-10"}>
                                        <div className={"text-left pt-1"}>
                                            <img src={"/img/neosigean-logo-letters-blue.svg"} height={30}/>
                                        </div>
                                    </div>
                                    <div className={"col-2"}>
                                        <a href={"#"} className={"close"} onClick={this.props.functions.toggleMenu}>
                                            <i className={"fal fa-chevron-left"}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <Menu sections={this.state.sections} functions={this.props.functions} authRoles={this.props.authRoles}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AppMenu;

export class Menu extends Component {
    constructor(props) {
        super(props);

        this.renderMenu();
    }

    renderMenu() {
        const sections = [];
        this.props.sections.map((section, sectionIndex) => {
            const items = [];
            if (allow(section.roles, this.props.authRoles)) {
                section.items.map((item, itemIndex) => {
                    if (allow(item.roles, this.props.authRoles)) {
                        items.push(
                            <div className={"app-menu-item"} key={itemIndex}>
                                <Link to={item.url} onClick={this.props.functions.toggleMenu}>
                                    <div className="row">
                                        <div className="col-2 text-center">
                                            <i className={"fas mr-3 fa-" + (item.icon)}/>
                                        </div>
                                        <div className="col-10 px-0">
                                            <span>{Intl(item.label)}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    }
                });
                sections.push(
                    <div className={"app-menu-section"} key={sectionIndex}>
                        <h4>{Intl(section.label)}</h4>
                        {items}
                    </div>
                );
            }
        });

        return <div className={"app-menu-content"}>{ sections }</div>
    }

    render() {
        return this.renderMenu();
    }
}
import {ItemService} from "../services/ItemService";

export const ItemActions = {
    list,
    save,
    subItems,
    show,
    remove,
    empty
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        ItemService.save(data)
            .then(
                item => {
                    dispatch(success(item));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }


    function request(data) { return { type: "ITEM_SAVE_REQUEST", data} };
    function success(item) { return {type: "ITEM_SAVE_SUCCESS", item } };
    function failure(error) { return {type: "ITEM_SAVE_FAILURE", error } };
}

function empty() {
    const item = {
        name: "",
        code: "",
        category_id: "",
        price: "",
        sub_items: [],
        checks: [],
        replacement_time: 0,
        maintainance_time: 0,
        description: ""
    }

    return dispatch => {
        dispatch(success(item));
    }

    function success(item) { return {type: "ITEM_NEW", item } };
}

function list() {
    return dispatch => {
        dispatch(request());
        ItemService.list()
            .then(
                items => {
                    dispatch(success(items));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "ITEMS_LIST_REQUEST"} };
    function success(items) { return {type: "ITEMS_LIST_SUCCESS", items } };
    function failure(error) { return {type: "ITEMS_LIST_FAILURE", error } };
}

function show(id) {
    return dispatch => {
        dispatch(request());
        ItemService.show(id)
            .then(
                item => {
                    dispatch(success(item));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "ITEM_REQUEST"} };
    function success(item) { return {type: "ITEM_SUCCESS", item } };
    function failure(error) { return {type: "ITEM_FAILURE", error } };
}

function remove(id) {
    return dispatch => {
        dispatch(request());
        ItemService.remove(id)
            .then(
                item => {
                    dispatch(success(item));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "ITEM_REMOVE_REQUEST"} };
    function success(item) { return {type: "ITEM_REMOVE_SUCCESS", item } };
    function failure(error) { return {type: "ITEM_REMOVE_FAILURE", error } };
}

function subItems() {
    return dispatch => {
        dispatch(request());
        ItemService.subItems()
            .then(
                subItems => {
                    dispatch(success(subItems));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    }

    function request() { return { type: "ITEM_SUBITEMS_LIST_REQUEST"} };
    function success(subItems) { return {type: "ITEM_SUBITEMS_LIST_SUCCESS", subItems } };
    function failure(error) { return {type: "ITEM_SUBITEMS_LIST_FAILURE", error } };
}
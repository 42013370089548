import React, { Component } from 'react';
import InputWrapper from "./InputWrapper";
import GmapsAutocomplete from "../gmaps/GmapsAutocomplete";
import DatePicker from "react-datepicker/es";

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            valid: true
        };
        this.getInputType = this.getInputType.bind(this);
        this.handleValue = this.handleValue.bind(this);
        this.handleBoolean = this.handleBoolean.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleColor = this.handleColor.bind(this);
        this.setAddress = this.setAddress.bind(this);
    }

    render() {
        return(
            <React.Fragment>
                { this.props.label ? <label>{ this.props.label }</label> : ""}
                <InputWrapper
                    prepend={ this.props.prepend }
                    append={ this.props.append }
                    prependText={ this.props.prependText }
                    appendText={ this.props.appendText }
                    prependIconKey={ this.props.prependIconKey }
                    appendIconKey={ this.props.appendIconKey }
                    placeholder={ this.props.placeholder }
                >
                { this.getInputType() }
                { this.props.error && this.props.error[this.props.name] ? <p className={"error"}>{ this.props.error[this.props.name][0]}</p> : "" }
                </InputWrapper>
            </React.Fragment>
        );
    }

    getInputType() {
        switch(this.props.type) {
            case "text":
            case "password":
            case "number":
            case "email":
            case "date":
            case "hidden":
                return <input
                    type={ this.props.type }
                    name={ this.props.name }
                    placeholder={ this.props.placeholder }
                    className={"form-control" + (!this.state.valid ? " error" : "") }
                    value={this.state.value}
                    onChange={ this.handleValue }
                    onClick={ this.handleClick }
                    onFocus={ this.props.onFocus }
                    onBlur={ this.props.onBlur }
                    tabIndex={this.props.tabIndex}
                    autoComplete={"no"}
                    id={this.props.id}
                />
            case "file":
                return <input type={"file"} name={this.props.name} className={"form-control"} onChange={this.handleValue}/>
            case "addressAutocomplete":
                return <GmapsAutocomplete setAddress={this.setAddress} value={this.state.value} name={this.props.name}/>;
            case "textarea":
                return <textarea name={ this.props.name } className="form-control" onChange={ this.handleValue } value={ this.state.value } rows={5}/>
            case "checkbox":
                return (
                    <label className={"mr-1"}>
                        <input type="checkbox" name={this.props.name} onChange={ this.handleBoolean } checked={!!this.state.value} className={"mr-2"}/>
                        { this.props.placeholder }
                    </label>
                );
            case "datepicker":
                return (
                    <DatePicker name={this.props.name} className={"form-control"} onChange={this.props.onChange} selected={this.state.value}/>
                );
            default:
                return <input type="text" name={ this.props.name } className="form-control" value={ this.state.value } onChange={ this.handleValue } autoComplete={"no"}/>
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value) {
            this.setState({value: nextProps.value});
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.value !== this.state.value) {
            this.setState({
                value: nextProps.value,
            });
        }

        if (typeof nextProps.valid !== "undefined" && nextProps.valid !== this.state.valid) {
            this.setState({ valid: nextProps.valid});
        }
    }

    handleValue(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        this.setState({
            value: e.target.value
        });
    }

    handleBoolean(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }

        this.setState({
            value: e.currentTarget.checked
        });
    }

    handleColor(event) {
        this.setState({
           value: event.target.title
        });
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    handleClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    setAddress(address) {
        if (this.props.type === "addressAutocomplete") {
            this.props.setAddress(address, this.props.name);
        }
    }
}

export default Input;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import {AppActions} from "../../actions/AppActions";

class AdminMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: null
        };
    }

    componentDidMount() {
        this.props.dispatch(AppActions.settings());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.settings) {
            this.setState({
                settings: nextProps.settings
            })
        }
    }

    render() {
        return(
            <div className="customers-menu">
                <div className={"customers-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-10"}>
                            <h4>Settings</h4>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                { this.state.settings ? <Subscription subscription={this.state.settings.subscription}/> : null }
                { this.state.settings ? <Features features={this.state.settings.features}/> : null }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state.App;

    return {
        settings
    }
}

export default connect(mapStateToProps)(AdminMenu);

export class Subscription extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className={"subscription-container"}>
                <span className={"d-block"}>{ this.props.subscription.name }</span>
            </div>
        );
    }
}

export class Features extends Component {
    constructor(props) {
        super(props)
        this.renderFeatures = this.renderFeatures.bind(this);
    }

    renderFeatures() {
        const features = [];
        this.props.features.map((feature) => {
            if (feature.config.type === 'limitation') {
                features.push(<span className={"d-block"}>{feature.config.name}: { feature.value }</span>)
            }
        });

        return features;
    }

    render() {
        return(
            <div className={"subscription-container"}>
                <span className={"d-block"}>{ this.renderFeatures() }</span>
            </div>
        );
    }
}
import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const RoleService = {
    list,
    item
}

function list() {
    return Request('roles', 'GET')
        .then(ErrorHandler)
        .then(
            roles => {
                return roles;
            }
        );
}

function item(id) {
    return Request('roles/' + id, 'GET')
        .then(ErrorHandler)
        .then(
            role => {
                return role;
            }
        );
}
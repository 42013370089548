import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormHeader from "../../../components/FormHeader/FormHeader";
import Input from "../../../components/Input/Input";
import {QueryString} from "../../../helpers/QueryString";
import {UserActions} from "../../../actions/UserActions";
import FormAbstract from "../../../abstract/FormAbstract";
import Select from "react-select";
import {RoleActions} from "../../../actions/RoleActions";
import {Link} from "react-router-dom";
import Intl from "../../../helpers/Intl";
import UserForm from "../components/UserForm";
import FyriScheduler from "../../../components/FyriScheduler/FyriScheduler";
import {OperationActions} from "../../../actions/OperationActions";
import EmployeeManager from "../components/EmployeeManager";

class UserStore extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = 'user';

        this.state = {
            tab: "general",
            user: undefined
        }

        this.partials = {
            general: <UserForm/>,
            management: <EmployeeManager/>,
            operations: <FyriScheduler view={"timeGridWeek"}/>
        }
    }

    componentDidMount() {
        if (QueryString.get('userId')) {
            this.props.dispatch(UserActions.item(QueryString.get('userId')))
        }
        this.props.dispatch(RoleActions.list());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            this.setState({
                user: nextProps.user
            })
        }

        if (nextProps.roles) {
            this.setState({
                roles: nextProps.roles
            })
        }

        if (nextProps.success === true) {
            this.props.dispatch(UserActions.list());
        }
    }

    render() {
        console.log(this.state);
        return (
            <div className={"user-form-container"}>
                <FormHeader title={this.state.user && this.state.user.id ? this.state.user.fullName : "Nuovo utente"} redirectTo={"/users"}/>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={"nav-link " + (this.state.tab === "general" ? "active" : "")} href="#" onClick={(e) => {
                            e.preventDefault();
                            this.switchTab("general")
                        }}>{ Intl("Info generali") }</a>
                    </li>
                    { this.state.user && this.state.user.id ?
                        <React.Fragment>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.state.tab === "management" ? "active" : "")} href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.switchTab("management")
                                }}>{ Intl("Gestione risorsa") }</a>
                            </li>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.state.tab === "operations" ? "active" : "")} href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.switchTab("operations")
                                    this.props.dispatch(OperationActions.events({userId: this.props.user.id }))
                                }}>{ Intl("Interventi") }</a>
                            </li>
                        </React.Fragment>
                        : null }
                </ul>
                <div className={"form-container"}>
                    { this.partials[this.state.tab] }
                </div>
            </div>
        );
    }

    switchTab(tab) {
        this.setState({
            tab: tab
        })
    }
}

function mapStateToProps(state) {
    const { user, success } = state.User;
    const { roles } = state.Role;

    return {
        user,
        roles,
        success
    }
}

export default connect(mapStateToProps)(UserStore);
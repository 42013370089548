export function Operation(state = {}, action) {
    switch(action.type) {
        case "ADD_LOCATION_TO_PLANNER":
            return {
                location: action.location
            }
        case "OPERATION_SAVE_REQUEST":
            return {
                loading: true
            }
        case "OPERATION_SAVE_SUCCESS":
            return {
                loading: false,
                events: action.events,
                operationsSaved: true
            }
        case "OPERATION_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "OPERATION_EVENTS_REQUEST":
            return {
                loading: true
            }
        case "OPERATION_EVENTS_SUCCESS":
            return {
                loading: false,
                events: action.events
            }
        case "OPERATION_EVENTS_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "OPERATION_EVENTS_GROUPED_REQUEST":
            return {
                loading: true
            }
        case "OPERATION_EVENTS_GROUPED_SUCCESS":
            return {
                loading: false,
                usersWithEvents: action.events
            }
        case "OPERATION_EVENTS_GROUPED_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        case "OPERATION_ROUTES":
            return {
                routes: action.routes
            }
        case "OPERATION_REMOVE_REQUEST":
            return {
                loading: true
            }
        case "OPERATION_REMOVE_SUCCESS":
            return {
                loading: false,
                deletedOperation: action.deletedOperation
            }
        case "OPERATION_REMOVE_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from "react-router-dom/es/Link";
import Input from "../../../components/Input/Input";
import {QueryString} from "../../../helpers/QueryString";
import {CustomerActions} from "../../../actions/CustomerActions";
import FormAbstract from "../../../abstract/FormAbstract";
import Intl from "../../../helpers/Intl";
import LocationItemForm from "./LocationItemForm";
import PermissionButton from "../../../components/PermissionButton/PermissionButton";

class CustomerForm extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "customer";

        this.state = {
            customerId: undefined,
            customer: this.initialState(),
            success: undefined,
            error: undefined,
            showMessage: false
        };
    }

    initialState() {
        return {
            id: undefined,
            name: "",
            email: "",
            phone: "",
            fax: "",
            vat: "",
            vat_number: "",
            website: "",
            discount: 0,
            address: "",
            notes: "",
            siteManager: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                mobile: "",
                address: ""
            }
        };
    }

    componentDidMount() {
        console.log(QueryString.get('customerId'));
        if (QueryString.get('customerId')) {
            this.props.dispatch(CustomerActions.item(QueryString.get('customerId')));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.success !== "undefined") {
            this.props.dispatch(CustomerActions.list());
        }

        if (nextProps.customer) {
            this.setState({
                customer: nextProps.customer
            });
        }

        if (nextProps.error) {
            this.setState({
                error: nextProps.error
            });
        }
    }

    render() {
        return(
            <React.Fragment>
                <form autoComplete={"no"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"name"} value={this.state.customer.name}
                                   label={Intl("Ragione Sociale")} error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <Input type={"email"} name={"email"} value={this.state.customer.email} label={Intl("E-mail")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"phone"} value={this.state.customer.phone} label={Intl("Telefono")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"fax"} value={this.state.customer.fax} label={"Fax"}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"website"} value={this.state.customer.website}
                                   error={this.state.error} label={Intl("Sito web")} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <div className={"form-group"}>
                                <Input type={"text"} name={"discount"} value={this.state.customer.discount}
                                       label={Intl("Sconto %")} onChange={this.handleForm}/>
                            </div>
                        </div>
                    </div>
                    <h5 className={"mt-3"}>{ Intl("Dettagli fatturazione") }</h5>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"vat_number"} value={this.state.customer.vat_number}
                                   error={this.state.error} label={Intl("Partita IVA")} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"vat"} value={this.state.customer.vat} label={Intl("IVA %")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"addressAutocomplete"} name={"address"} setAddress={this.setAddress}
                                   value={this.state.customer.address} label={Intl("Indirizzo corrispondenza")} error={this.state.error}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Input type={"textarea"} name={"notes"} value={this.state.customer.notes} label={"Note"}
                                   onChange={this.handleForm}/>
                        </div>
                    </div>
                    <h5 className={"mt-3"}>{ Intl("Responsabile Sito / RSPP") }</h5>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"siteManager.firstname"}
                                   value={this.state.customer.siteManager.firstname} label={Intl("Nome")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"siteManager.lastname"}
                                   value={this.state.customer.siteManager.lastname} label={Intl("Cognome")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"email"} name={"siteManager.email"}
                                   value={this.state.customer.siteManager.email} label={"E-mail"}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <Input type={"addressAutocomplete"} name={"siteManager.address"}
                                   setAddress={this.setAddress} value={this.state.customer.siteManager.address}
                                   error={this.state.error} label={Intl("Indirizzo")}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"siteManager.phone"}
                                   value={this.state.customer.siteManager.phone} label={Intl("Telefono")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"siteManager.mobile"}
                                   value={this.state.customer.siteManager.mobile} label={Intl("Mobile")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                    </div>
                    <Link to={"/customers"} className={"btn btn-secondary"}>
                        <i className={"fal fa-arrow-left mr-1"}/>
                        { Intl("Annulla") }
                    </Link>
                    <PermissionButton
                        roles={["SUPER_ADMIN"]}
                        onClick={this.sendForm}
                        prevent={true}
                        className={"btn btn-primary float-right"}
                    >
                        <i className={"fal fa-check mr-1"}/>
                        { Intl("Salva") }
                    </PermissionButton>
                </form>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { customer, success, error } = state.Customer;

    return {
        customer,
        success,
        error
    }
}

export default connect(mapStateToProps)(CustomerForm);
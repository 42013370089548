export function Item(state = {}, action) {
    switch(action.type) {
        case "ITEMS_LIST_REQUEST":
            return {
                loading: true
            };
        case "ITEMS_LIST_SUCCESS":
            return {
                loading: false,
                items: action.items
            };
        case "ITEMS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "ITEM_NEW":
            return {
                item: action.item,
                error: {}
            };
        case "ITEM_REQUEST":
            return {
                loading: true
            };
        case "ITEM_SUCCESS":
            return {
                loading: false,
                item: action.item
            };
        case "ITEM_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "ITEM_SAVE_REQUEST":
            return {
                loading: true
            };
        case "ITEM_SAVE_SUCCESS":
            return {
                loading: false,
                item: action.item,
                success: true
            };
        case "ITEM_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error,
                success: false
            };
        case "ITEM_REMOVE_REQUEST":
            return {
                loading: true
            };
        case "ITEM_REMOVE_SUCCESS":
            return {
                loading: false,
                removedItem: action.item.removed,
                success: true
            };
        case "ITEM_REMOVE_FAILURE":
            return {
                loading: false,
                error: action.error,
                success: false
            };
        case "ITEM_SUBITEMS_LIST_REQUEST":
            return {
                loading: true
            };
        case "ITEM_SUBITEMS_LIST_SUCCESS":
            return {
                loading: false,
                subItems: action.subItems
            };
        case "ITEM_SUBITEMS_LIST_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

import {history} from "./History";

export const QueryString = {
    all,
    get,
    set,
    convertObject,
    literal,
    getPath
};

function getPath() {
    return history.location.pathname+history.location.search;
}

function all() {
    const uri = history.location.search;
    const paramsArray = uri.split('?').pop().split('&');
    let params = {};
    paramsArray.map((param) => {
        if (param.length) {
            const paramArray = param.split('=');
            params = {
                ...params,
                [paramArray[0]]: paramArray[1]
            }
        }
    });

    return params;
}

function get(paramName) {
    const params = all();
    let requested = undefined;
    Object.keys(params).map((param, index) => {
        if (param === paramName) {
            requested = params[paramName];
        }
    });

    return requested;
}

function literal() {
    return history.location.search;
}

function set(pathName, queryString) {
    history.push({
        pathname: pathName,
        search: queryString
    });
}

function convertObject(params) {
    const queryString = Object.keys(params).map(function(key) {
        return key + '=' + params[key]
    }).join('&');

    return queryString;
}
import {HoursOffService} from "../services/HoursOffService";

export const HoursOffActions = {
    getByUser,
    save,
    remove
};

function getByUser(userId) {
    return dispatch => {
        dispatch(request());
        HoursOffService.getByUser(userId)
            .then(
                hoursOff => {
                    dispatch(success(hoursOff))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request() { return { type: "USER_HOURS_OFF_REQUEST"} };
    function success(hoursOff) { return {type: "USER_HOURS_OFF_SUCCESS", hoursOff } };
    function failure(error) { return {type: "USER_HOURS_OFF_FAILURE", error } };
}

function save(data) {
    return dispatch => {
        dispatch(request());
        HoursOffService.save(data)
            .then(
                hoursOff => {
                    dispatch(success(hoursOff))
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            )
    }

    function request() { return { type: "USER_HOURS_OFF_SAVE_REQUEST"} };
    function success(hoursOff) { return {type: "USER_HOURS_OFF_SAVE_SUCCESS", hoursOff } };
    function failure(error) { return {type: "USER_HOURS_OFF_SAVE_FAILURE", error } };
}

function remove(itemId, userId) {
    return dispatch => {
        dispatch(request());
        HoursOffService.remove(itemId, userId)
            .then(
                hoursOff => {
                    dispatch(success(hoursOff))
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            )
    }

    function request() { return { type: "USER_HOURS_OFF_REMOVE_REQUEST"} };
    function success(hoursOff) { return {type: "USER_HOURS_OFF_REMOVE_SUCCESS", hoursOff } };
    function failure(error) { return {type: "USER_HOURS_OFF_REMOVE_FAILURE", error } };
}
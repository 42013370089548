import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import {Store} from "./helpers/Store";
import {history} from "./helpers/History";
import * as serviceWorker from './serviceWorker';
import Login from "./modules/auth/Login";
import Customers from "./modules/customers/Customers";
import Items from "./modules/items/Items";
import Floor from "./modules/floor/Floor";
import Admin from "./modules/admin/Admin";
import User from "./modules/user/User";
import Operations from "./modules/operations/Operations";
import Validations from "./modules/validations/Validations";
import Finance from "./modules/finance/Finance";
import Documents from "./modules/documents/Documents";
import FloorPrint from "./modules/floor/components/FloorPrint";
import Locations from "./modules/locations/Locations";

ReactDOM.render(
    <Provider store={Store}>
        <Router history={ history }>
            <Switch>
                <Route path="/" exact component={ Login }/>
                <Route path={"/dashboard/:scene?"} component={App}/>
                <Route path={"/customers/:scene?"} component={Customers}/>
                <Route path={"/floor/:scene?"} component={Floor}/>
                <Route path={"/items/:scene?"} component={Items}/>
                <Route path={"/subitems/:scene?"} component={Items}/>
                <Route path={"/validations/:scene?"} component={Validations}/>
                <Route path={"/users/:scene?"} component={User}/>
                <Route path={"/settings/:scene?"} component={Admin}/>
                <Route path={"/finance/:scene?"} component={Finance}/>
                <Route path={"/documents/:scene?"} component={Documents}/>
                <Route path={"/operations/:scene?"} component={Operations}/>
                <Route path={"/export/floor"} exact component={FloorPrint}/>
                <Route path={"/locations/:scene?"} component={Locations}/>
            </Switch>
        </Router>
    </Provider>
    , document.getElementById('root'));
serviceWorker.register();

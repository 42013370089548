import React,  { Component } from 'react';
import {GMaps} from "../../plugins/gmaps";
import {LocationActions} from "../../actions/LocationActions";
import connect from "react-redux/es/connect/connect";

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            locations: []
        }
    }


    componentDidMount() {
        this.props.dispatch(LocationActions.all());
        GMaps.init();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps)
        if (nextProps.locations) {
            this.setState({
                locations: nextProps.locations
            })
            GMaps.createMarkers(nextProps.locations, this.props.profile, this.props.dispatch);
        }

        if (nextProps.events && nextProps.events.length) {
            GMaps.createPlanningOverview(nextProps.events)
        }
    }

    render() {
        return (
            <div id={"map"}></div>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.locations) {
            return false;
        }
        return true;
    }
}

function mapStateToProps(state) {
    const { locations } = state.Location;
    const { location, events } = state.Operation;
    const { profile } = state.Auth;

    return {
        locations,
        location,
        events,
        profile
    }
}

export default connect(mapStateToProps)(Map);
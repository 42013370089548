import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from "../Input/Input";
import {GmapsApi} from "./services/GmapsApiService";
import {GmapsApiActions} from "./actions/GmapsApiActions";

class GmapsAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addressList: undefined,
            typingTimer: undefined,
            value: "",
            showList: false,
            address: undefined
        };

        this.unique = [...Array(10)].map(i=>(~~(Math.random()*36)).toString(36)).join('');

        this.searchAddress = this.searchAddress.bind(this);
        this.requestTrigger = this.requestTrigger.bind(this);
        this.setAddress = this.setAddress.bind(this);
    }

    render() {
        const addressList = [];
        if (this.state.addressList) {
            this.state.addressList.map((address, index) => {
                addressList.push(
                    <li key={index} className={"list-group-item"}>
                        <a href="#" data-index={index} onClick={this.setAddress}>
                            { address.formatted_address }
                        </a>
                    </li>
                );
            });
        }
        return(
            <div className={"autocomplete-container"} id={"autocomplete-container"}>
                <input type={"text"} className={"form-control"} id={"address-autocomplete"} onChange={this.requestTrigger} autoComplete={"off"} name={this.props.name} value={this.state.value}/>
                <ul className="list-group">
                    { this.state.showList ? addressList : "" }
                </ul>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.addressList && nextProps.inputName === this.props.name) {
            if (nextProps.addressList.length) {
                this.setState({
                    addressList: nextProps.addressList,
                    showList: true
                })
            }
        }

        if (nextProps.value && !this.state.edit) {
            this.setState({
                address: nextProps.value,
                value: nextProps.value.formatted_address,
                showList: false
            });
        }

        if (typeof nextProps.value === "undefined") {
            this.setState({
                address: undefined,
                value: "",
                showList: false
            });
        }
    }

    requestTrigger(e) {
        const doneTypingInterval = 1000; //1.0 seconds

        //on keyup, start the countdown
        const { value } = e.target;
        this.setState({
            edit: true,
            value: value,
            typingTimer: setTimeout(this.searchAddress, doneTypingInterval, value)
        });

        if (this.state.typingTimer) {
            clearTimeout(this.state.typingTimer);
        }
    }

    searchAddress(searchText) {
        this.props.dispatch(GmapsApiActions.searchAddress(searchText, this.props.name));
    }

    setAddress(e) {
        e.preventDefault();
        const addressIndex = e.target.getAttribute('data-index');
        const address = this.state.addressList[addressIndex];
        this.setState({
            edit: false,
            address: address,
            value: address.formatted_address,
            showList: false
        });

        if (this.props.setAddress) {
            this.props.setAddress(address);
        }
    }
}

function mapStateToProps(state) {
    const { addressList, inputName } = state.GmapsApi;

    return {
        addressList,
        inputName
    };
}

export default connect(mapStateToProps)(GmapsAutocomplete);
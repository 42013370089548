import React, { Component } from 'react';
import OperationsMenu from "./OperationsMenu";
import App from "../../App";
import "./styles.scss"
import OperationsCalendar from "./scenes/OperationsCalendar";
import ResourcesMenu from "./ResourcesMenu";
import OperationsPlanning from "./OperationsPlanning";
import TimelineMenu from "./TimelineMenu";
import Timeline from "./scenes/Timeline";

class Operations extends Component {
    constructor(props) {
        super(props);

        this.scenes = {
            calendar: <OperationsCalendar/>,
            overview: null,
            timeline: <Timeline/>,
        }

        this.renderMenu = this.renderMenu.bind(this);
    }

    renderMenu() {
        switch(this.props.match.params.scene) {
            case "add":
            case "edit":
                return <OperationsPlanning/>;
            case "calendar":
                return <ResourcesMenu/>
            case "timeline":
                return <TimelineMenu/>
            default:
                return <OperationsMenu/>
        }
    }

    render() {
        const menu = this.renderMenu();
        return (
            <App menu={menu}>
                { this.scenes[this.props.match.params.scene]}
            </App>
        )
    }
}

export default Operations;


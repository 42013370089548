import {GmapsApiService} from "../services/GmapsApiService";

export const GmapsApiActions = {
    searchAddress
};

function searchAddress(searchText, inputName) {
    return dispatch => {
        dispatch(request(searchText));
        GmapsApiService.searchAddress(searchText)
            .then(
                addressList => {
                    dispatch(success(addressList, inputName));
                },
                error => {
                    dispatch(failure(error, inputName));
                }
            );
    };

    function request(searchText) { return { type: "GMAPS_ADDRESS_REQUEST", searchText }; }
    function success(addressList, inputName) { return { type: "GMAPS_ADDRESS_SUCCESS", addressList, inputName }; }
    function failure(error) { return { type: "GMAPS_ADDRESS_FAILURE", error, inputName }; }
}
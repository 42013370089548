import React, { Component } from 'react';
import App from "../../App";
import FinanceMenu from "./FinanceMenu";
import Charts from "./scenes/Charts";
import "./styles.scss"

class Finance extends Component {
    constructor(props) {
        super(props);

        this.scenes = {

        }
    }

    render() {
        return(
            <App menu={<FinanceMenu/>}>
                <Charts/>
            </App>
        );
    }
}

export default Finance;
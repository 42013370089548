export function HoursOff(state = {}, action) {
    switch(action.type) {
        case "USER_HOURS_OFF_SAVE_REQUEST":
            return {
                loading: true
            };
        case "USER_HOURS_OFF_SAVE_SUCCESS":
            return {
                loading: false,
                hoursOffList: action.hoursOff
            };
        case "USER_HOURS_OFF_SAVE_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "USER_HOURS_OFF_REMOVE_REQUEST":
            return {
                loading: true
            };
        case "USER_HOURS_OFF_REMOVE_SUCCESS":
            return {
                loading: false,
                hoursOffList: action.hoursOff
            };
        case "USER_HOURS_OFF_REMOVE_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        case "USER_HOURS_OFF_REQUEST":
            return {
                loading: true
            };
        case "USER_HOURS_OFF_SUCCESS":
            return {
                loading: false,
                hoursOffList: action.hoursOff
            };
        case "USER_HOURS_OFF_FAILURE":
            return {
                loading: false,
                error: action.error
            };
        default:
            return state
    }
}
import {Request} from "../helpers/Request";
import {ErrorHandler} from "../helpers/ErrorHandler";

export const DocumentService = {
    list,
    save
}

function list() {
    return Request('documents', 'GET')
        .then(ErrorHandler)
        .then(
            documents => {
                return documents
            }
        )
}

function save(data) {
    let method = "POST";
    let endpoint = "documents";

    return Request(endpoint, method, data, undefined, {}, "multipart")
        .then(ErrorHandler)
        .then(
            documentItem => {
                return documentItem;
            }
        );

}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormHeader from "../../../components/FormHeader/FormHeader";
import Input from "../../../components/Input/Input";
import {QueryString} from "../../../helpers/QueryString";
import {UserActions} from "../../../actions/UserActions";
import FormAbstract from "../../../abstract/FormAbstract";
import Select from "react-select";
import {RoleActions} from "../../../actions/RoleActions";
import {Link} from "react-router-dom";
import Intl from "../../../helpers/Intl";

class UserForm extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = 'user';

        this.state = {
            user: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                roles: [],
                notes: "",
                address: "",
                mobile: ""
            },
            roles: []
        }
    }

    componentDidMount() {
        if (QueryString.get('userId')) {
            this.props.dispatch(UserActions.item(QueryString.get('userId')))
        }
        this.props.dispatch(RoleActions.list());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            this.setState({
                user: nextProps.user
            })
        }

        if (nextProps.roles) {
            this.setState({
                roles: nextProps.roles
            })
        }

        if (nextProps.success === true) {
            this.props.dispatch(UserActions.list());
        }

        if (nextProps.error) {
            console.log(nextProps.error)
            this.setState({
                error: nextProps.error
            })
        }
    }

    render() {
        console.log(this.state);
        return (

            <form onSubmit={this.sendForm}>
                <div className={"row form-group"}>
                    <div className={"col-6"}>
                        <Input type={"text"} name={"firstname"} value={this.state.user.firstname} label={Intl("Nome")}
                               error={this.state.error} onChange={this.handleForm}/>
                    </div>
                    <div className={"col-6"}>
                        <Input type={"text"} name={"lastname"} value={this.state.user.lastname} label={Intl("Cognome")}
                               error={this.state.error} onChange={this.handleForm}/>
                    </div>
                </div>
                <div className={"row form-group"}>
                    <div className={"col-6"}>
                        <Input type={"text"} name={"email"} value={this.state.user.email} label={"E-mail"} error={this.state.error} onChange={this.handleForm}/>
                    </div>
                    <div className={"col-6"}>
                        <Input type={"addressAutocomplete"} name={"address"} setAddress={this.setAddress}
                               value={this.state.user.address} label={"Indirizzo"} error={this.state.error}/>
                    </div>
                </div>
                <div className="row form-group">
                    <div className={"col-6"}>
                        <Input type={"text"} name={"phone"} value={this.state.user.phone} label={"Tel. Fisso"} error={this.state.error} onChange={this.handleForm}/>
                    </div>
                    <div className={"col-6"}>
                        <Input type={"text"} name={"mobile"} value={this.state.user.mobile} label={"Tel. Mobile"} error={this.state.error} onChange={this.handleForm}/>
                    </div>
                </div>
                <div className="row form-group">
                    <div class="col-12">
                        <label>Ruoli</label>
                        <Select
                            options={this.state.roles}
                            value={this.state.user.roles}
                            isSearchable={true}
                            isMulti={true}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            onChange={(roles) => {
                                this.handleMultipleSelect(roles, 'roles')
                            }}
                        />
                    </div>
                </div>
                <div className={"form-group"}>
                    <Input type={"textarea"} name={"notes"} value={this.state.user.notes} label={"Note"} error={this.state.error} onChange={this.handleForm}/>
                </div>
                <div className={"form-group"}>
                    <Link to={"/users"} className={"btn btn-secondary btn-flat"}>
                        <i className={"fal fa-arrow-left mr-2"}/>
                        Annulla
                    </Link>
                    <button className={"btn btn-primary btn-flat float-right"}>
                        <i className={"fal fa-check mr-2"}/>
                        Salva
                    </button>
                </div>
            </form>

        );
    }
}

function mapStateToProps(state) {
    const { user, success, error } = state.User;
    const { roles } = state.Role;

    return {
        user,
        roles,
        success,
        error
    }
}

export default connect(mapStateToProps)(UserForm);
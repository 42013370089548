import {AuthService} from "../services/AuthService";

export const AuthActions = {
    login,
    profile,
    logout
};

function login(data) {
    return dispatch => {
        dispatch(request(data));
        AuthService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    };

    function request(data) { return { type: "AUTH_LOGIN_REQUEST", data }; }
    function success(user) { return { type: "AUTH_LOGIN_SUCCESS", user }; }
    function failure(error) { return { type: "AUTH_LOGIN_FAILURE", error }; }
}

function profile() {
    return dispatch => {
        dispatch(request());
        AuthService.profile()
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(data) { return { type: "AUTH_PROFILE_REQUEST", data }; }
    function success(user) { return { type: "AUTH_PROFILE_SUCCESS", user }; }
    function failure(error) { return { type: "AUTH_PROFILE_FAILURE", error }; }
}

function logout() {
    return dispatch => {
        dispatch(request());
        AuthService.logout()
            .then(
                loggedOut => {
                    dispatch(success(loggedOut));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    };

    function request(data) { return { type: "AUTH_LOGOUT_REQUEST", data }; }
    function success(loggedOut) { return { type: "AUTH_LOGOUT_SUCCESS", loggedOut }; }
    function failure(error) { return { type: "AUTH_LOGOUT_FAILURE", error }; }
}
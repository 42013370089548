import {ItemService} from "../services/ItemService";
import {CategoryService} from "../services/CategoryService";

export const CategoryActions = {
    list
}

function list() {
    return dispatch => {
        dispatch(request());
        CategoryService.list()
            .then(
                categories => {
                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "CATEGORIES_LIST_REQUEST"} };
    function success(categories) { return {type: "CATEGORIES_LIST_SUCCESS", categories } };
    function failure(error) { return {type: "CATEGORIES_LIST_FAILURE", error } };
}
export function Auth() {
    // return authorization header with jwt token
    let auth = JSON.parse(localStorage.getItem('auth'));

    if (auth && auth.access_token) {
        return {
            Authorization: 'Bearer ' + auth.access_token
        };
    }
}

export function allow(required, roles) {
    if (!required || !required.length) {
        return true;
    }

    let allowed = false;

    roles.map((role) => {
        if (required.indexOf(role.key) >= 0) {
            allowed = true;
        }
    });

    return allowed;
}
import React, { Component } from 'react';
import App from "../../App";
import DocumentsMenu from "./DocumentsMenu";
import DocumentsList from "./scenes/DocumentsList";

class Documents extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <App menu={<DocumentsMenu/>}>
                <DocumentsList/>
            </App>
        );
    }
}

export default Documents;
import React, { Component } from 'react';
import App from "../../App";
import './styles.scss';
import ValidationsMenu from "./ValidationsMenu";
import ValidationsStore from "./scenes/ValidationsStore";

class Validations extends Component {
    constructor(props) {
        super(props);

        this.scenes = {
            add: <ValidationsStore validation={undefined}/>,
            edit: <ValidationsStore/>
        }
    }

    render() {
        return (
            <App menu={<ValidationsMenu/>} authRoles={['SUPER_ADMIN']}>
                { this.scenes[this.props.match.params.scene] }
            </App>
        );
    }
}

export default Validations;
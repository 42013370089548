import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from './logo.svg';
import "./App.scss"
import Dashboard from "./components/dashboard/Dashboard";
import AppMenu from "./components/appMenu/AppMenu";
import Map from "./components/gmaps/Map";
import "react-datepicker/dist/react-datepicker.css";
import {AuthActions} from "./actions/AuthActions";
import {allow} from "./helpers/Auth";
import {Link} from "react-router-dom";
import { PusherClient } from './plugins/Pusher/PusherClient'
import LocationItemForm from "./modules/customers/components/LocationItemForm";
import {LocationActions} from "./actions/LocationActions";
import {AppActions} from "./actions/AppActions";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSideBar: true,
            showMenu: false,
            profile: {
                id: null,
                roles: []
            },
            allowed: undefined,
            showLocationForm: false
        }

        this.toggleSideBar = this.toggleSideBar.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleLocationForm = this.toggleLocationForm.bind(this);

        this.functions = {
            toggleMenu: this.toggleMenu
        }

        this.locationFormFunctions = {
            toggleLocationForm: this.toggleLocationForm
        }
    }

    toggleLocationForm() {
        this.props.dispatch(AppActions.toggleLocationForm())
    }

    componentWillMount() {
        this.props.dispatch(AuthActions.profile())
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextState.profile !== this.state.profile) {
            const allowed = allow(this.props.moduleRoles, nextState.profile.roles)
            this.setState({
                allowed: allowed
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile) {
            // PusherClient.init(nextProps.profile);
            this.setState({
                profile: nextProps.profile
            })
        }

        if (nextProps.toggleLocationForm) {
            this.setState({
                showLocationForm: !this.state.showLocationForm
            })
            if (nextProps.locationId) {
                this.props.dispatch(LocationActions.item(nextProps.locationId))
            }
        }
    }

    toggleSideBar(e) {
        e.preventDefault();
        this.setState({
            showSideBar: !this.state.showSideBar
        })
    }

    toggleMenu(e) {
        // e.preventDefault();
        this.setState({
            showMenu: !this.state.showMenu
        });
    }

    render() {
        console.log(this.state.showLocationForm);
        return (
            <div className={"app-container"}>
            { this.state.allowed === false ?
                <div className={"row"}>
                    <div className={"col-8 offset-2 mt-5"}>
                        <div className={"text-center pt-1 mb-3"}>
                            <img src={"/img/neosigean-logo-letters-blue.svg"} height={50}/>
                        </div>
                        <div className={"alert alert-danger"}>
                            <span>You do not have sufficient permission.</span>
                        </div>
                        <div className={"text-center"}>
                            <Link to={"/dashboard"} className={"btn btn-primary"}>
                                Return to dashboard
                            </Link>
                        </div>
                    </div>
                </div>
                :
                <React.Fragment>
                    <AppMenu functions={this.functions} show={this.state.showMenu} authRoles={this.state.profile.roles}/>
                    <div className={"app-wrapper"}>
                        <div className={"toggle-wrapper " + (this.state.showSideBar ? "show" : "hide")}>
                            <a className={"toggle"} href={"#"} onClick={this.toggleSideBar}>
                                <i className={"fas " + (this.state.showSideBar ? "fa-caret-left" : "fa-caret-right")}/>
                            </a>
                        </div>
                        <div className={"sidebar " + (this.state.showSideBar ? "show" : "hide")}>
                            <Dashboard functions={this.functions} moduleMenu={this.props.menu}/>
                        </div>
                        <div className={"map-wrapper " + (this.state.showSideBar ? "show" : "hide")}>
                            {this.props.children ? this.props.children : <Map/>}
                        </div>
                    </div>
                    { this.state.showLocationForm ? <LocationItemForm functions={this.locationFormFunctions}/> : null }
                </React.Fragment>
            }
            </div>
        );
  }
}

function mapStateToProps(state) {
    const { profile } = state.Auth;
    const { toggleLocationForm, locationId } = state.App;

    return {
        profile,
        toggleLocationForm,
        locationId,
    }
}

export default connect(mapStateToProps)(App);
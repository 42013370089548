import React, { Component } from 'react';
import { connect } from 'react-redux';
import {OperationActions} from "../../actions/OperationActions";
import moment from "moment";
import {QueryString} from "../../helpers/QueryString";
import {Link} from "react-router-dom";
import {UsersList} from "./ResourcesMenu";
import Intl from "../../helpers/Intl";

class TimelineMenu extends Component {
    constructor(props) {
        super(props);
        this.renderUsers = this.renderUsers.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(OperationActions.eventsGroupedByUser({
            schedule: moment().format('YYYY-MM-DD')
        }))
    }

    renderUsers() {
        console.log(this.props.usersWithEvents);
        const users = [];
        if (this.props.usersWithEvents) {
            this.props.usersWithEvents.map((user) => {
                users.push(<UserItem user={user} key={user.id}/>);
            });
        }

        return users;
    }

    render() {
        return(
            <div className={"users-menu"}>
                <div className={"users-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <Link to={"/operations/add"} className={"btn btn-primary btn-sm"} onClick={this.newUser}>
                                <i className={"far fa-plus mr-2"}/>
                                Nuovo
                            </Link>
                        </div>
                        <div className={"col-6"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type={"text"} className={"form-control form-control-sm"}
                                           placeholder={Intl("Cerca tra gli utenti")}/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Operators") }</h4>
                        </div>
                    </div>
                </div>
                <UsersList users={this.props.usersWithEvents}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { usersWithEvents } = state.Operation;
    return {
        usersWithEvents
    }
}

export default connect(mapStateToProps)(TimelineMenu);

export class UserItem extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.user);
        this.state = {
            user: this.props.user
        }

        this.renderRoles = this.renderRoles.bind(this);
    }

    renderRoles(user) {
        const roles = [];
        if (user) {
            user.roles.map((role) => {
                roles.push(<small key={user.id + role.id} className={"badge badge-primary py-1 px-2 mr-2"}>{ role.name }</small>);
            });
        }
        return roles;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            this.setState({
                users: nextProps.users
            })
        }
    }

    render() {
        return (
            <div className={"user-item" + (QueryString.get('userId') === this.state.user.id ? ' active' : '')} key={this.state.user.id}>
                <h5>
                    <i className={"fas fa-square mr-2"} style={{color: this.state.user.color}}/>
                    {this.state.user.fullName}
                </h5>
                <Link to={"/users/delete?userId=" + this.state.user.id} onClick={
                    (e) => {
                        this.props.functions.editUser(this.state.user.id)
                    }} className={"float-right"}>
                    <i className={"far fa-trash-alt"}/>
                </Link>
                <Link to={"/users/edit?userId=" + this.state.user.id} onClick={
                    (e) => {
                        this.props.functions.editUser(this.state.user.id)
                    }} className={"float-right mr-2"}>
                    <i className={"far fa-pencil-alt"}/>
                </Link>
                <div className={"row"}>
                    <div className={"col-12"}>
                        {this.renderRoles(this.state.user)}
                    </div>
                </div>
                <div className={"user-details"}>
                    <span className="mr-4">
                        <i className={'fal fa-mobile-android-alt mr-2'}/>
                        <small>{this.state.user.mobile}</small>
                    </span>
                    <span className="mr-4">
                        <i className={'fal fa-envelope mr-2'}/>
                        <small>{this.state.user.email}</small>
                    </span>
                </div>
            </div>
        );
    }
}
export function App(state = {}, action) {
    switch(action.type) {
        case "APP_NAVIGATION":
            return {
                item: action.navInfo.menuItem,
                referrer: action.navInfo.referrer
            };
        case "TOGGLE_LOCATION_FORM":
            return {
                toggleLocationForm: true,
                locationId: action.locationId
            }
        case "APP_SETTINGS_REQUEST":
            return {
                loading: true
            }
        case "APP_SETTINGS_SUCCESS":
            return {
                loading: false,
                settings: action.settings
            }
        case "APP_SETTINGS_FAILURE":
            return {
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SubitemActions} from "../../../actions/SubitemActions";
import {CategoryActions} from "../../../actions/CategoryActions";
import Select from "react-select";
import {QueryString} from "../../../helpers/QueryString";
import Link from "react-router-dom/es/Link";
import Input from "../../../components/Input/Input";
import FormAbstract from "../../../abstract/FormAbstract";
import FormHeader from "../../../components/FormHeader/FormHeader";
import Intl from "../../../helpers/Intl";

class SubitemStore extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "subitem";

        this.state = {
            subitem: {
                name: "",
                code: "",
                price: "",
                checks: [],
                hours_replacement: "",
                minutes_replacement: "",
                description: ""
            }
        };
    }

    componentWillMount() {
        if (QueryString.get('subitemId')) {
            this.props.dispatch(SubitemActions.show(QueryString.get('subitemId')));
        }
    }

    render() {
        console.log(this.state)
        return(
            <div className={"subitem-form-container"}>
                <FormHeader title={this.state.subitem.id ? this.state.subitem.name : Intl("Nuova parte")} redirectTo={"/subitems"}/>
                <form onSubmit={this.sendForm}>
                    <div className={"row form-group"}>
                        <div className={"col-4"}>
                            <Input type={"text"} name={"name"} value={this.state.subitem.name} label={ Intl("Nome articolo") }
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-4"}>
                            <Input type={"text"} name={"code"} value={this.state.subitem.code} label={Intl("Codice")}
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-4"}>
                            <Input type={"text"} name={"price"} value={this.state.subitem.price} label={Intl("Prezzo")}
                                   error={this.state.error} onChange={this.handleForm} prepend prependText={"€"}/>
                        </div>
                    </div>
                    <div className={"row form-group"}>
                        <div className={"col-6"}>
                            <label>{ Intl("Tempo di sostituzine") }</label>
                            <div className={"input-group input-group-sm"}>
                                <div className={"input-group-prepend"}>
                                    <span className={"input-group-text"}>
                                        <i className={"fal fa-clock"}/>
                                    </span>
                                </div>
                                <input type={"number"} className={"form-control"} placeholder={Intl("Ore")} name={"hours_replacement"} value={this.state.subitem.hours_replacement} onChange={this.handleForm}/>
                                <input type={"number"} className={"form-control"} placeholder={Intl("Minuti")} name={"minutes_replacement"} value={this.state.subitem.minutes_replacement} onChange={this.handleForm}/>
                                { this.state.error && this.state.error['hours_replacement'] ? <p className={"error"}>{this.state.error['hours_replacement']}</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <label>{ Intl("Descrizione") }</label>
                        <textarea name={"description"} className={"form-control"} rows={5} value={this.state.subitem.description} onChange={this.handleForm}/>
                    </div>
                    <div className={"form-group"}>
                        <Link to={"/subitems"} className={"btn btn-secondary btn-flat"}>
                            <i className={"fal fa-arrow-left mr-2"}/>
                            { Intl("Annulla") }
                        </Link>
                        <button className={"btn btn-primary btn-flat float-right"}>
                            <i className={"fal fa-check mr-2"}/>
                            { Intl("Salva") }
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.error) {
            if (nextProps.error instanceof Promise) {
                nextProps.error.then(
                    errorMessages => {
                        this.setState({
                            error: errorMessages
                        })
                    }
                )
            } else {
                this.setState({
                    error: nextProps.error
                })
            }
        }

        if (nextProps.subitem) {
            console.log(nextProps.subitem)
            const subitem = nextProps.subitem;
            subitem.hours_replacement = Math.floor(subitem.replacement_time/60);
            subitem.minutes_replacement = subitem.replacement_time%60;
            this.setState({
                subitem: subitem
            })
        }

        if (nextProps.success === true) {
            this.props.dispatch(SubitemActions.list());
        }
    }
}

function mapStateToProps(state) {
    const { subitem, error, success } = state.Subitem;

    return {
        subitem,
        error,
        success
    }
}

export default connect(mapStateToProps)(SubitemStore);
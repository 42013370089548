import React, { Component } from 'react'
import FormHeader from "../../../components/FormHeader/FormHeader";
import {QueryString} from "../../../helpers/QueryString";

class Charts extends Component {
    render() {
        return (
            <div class={"finance-charts"}>
                <FormHeader title={"Finance"} redirectTo={"/dashboard"}/>
                { QueryString.get('customerId')}
            </div>
        )
    }
}

export default Charts
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DocumentActions} from "../../../actions/DocumentActions";
import DocumentCard from "../components/DocumentCard";
import Intl from "../../../helpers/Intl";
import FormHeader from "../../../components/FormHeader/FormHeader";

class DocumentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: []
        }
    }

    componentWillMount() {
        this.props.dispatch(DocumentActions.list())
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.documents) {
            this.setState({
                documents: nextProps.documents
            })
        }

        if (nextProps.documentItem) {
            this.state.documents.unshift(nextProps.documentItem);
        }
    }

    renderDocuments() {
        const documents = [];
        console.log(this.state.documents)
        this.state.documents.map((item, index) => {
            documents.push(<DocumentCard document={item} key={item.id}/>)
        })

        return documents;
    }

    render() {
        return(
            <div className={"customer-form-container"}>
                <FormHeader
                    title={Intl("Documenti") }
                    subtitle={Intl("Tutti i documenti condivisi")}
                    redirectTo={"/dashboard"}
                />
                <div className={"row"}>
                    { this.renderDocuments() }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { documents, documentItem } = state.Documents;
    return {
        documents,
        documentItem
    }
}

export default connect(mapStateToProps)(DocumentsList);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ValidationActions} from "../../../actions/ValidationActions";
import {CategoryActions} from "../../../actions/CategoryActions";
import Select from "react-select";
import {QueryString} from "../../../helpers/QueryString";
import Link from "react-router-dom/es/Link";
import Input from "../../../components/Input/Input";
import FormAbstract from "../../../abstract/FormAbstract";
import FormHeader from "../../../components/FormHeader/FormHeader";
import Intl  from "../../../helpers/Intl"

class ValidationsStore extends FormAbstract {
    constructor(props) {
        super(props);

        this.model = "validation";

        this.state = {
            categories: undefined,
            validation: {
                name: "",
                years: "",
                months: "",
                description: "",
                categories: [],
                action_required: ""
            }
        };

        this.handlePeriod = this.handlePeriod.bind(this);
    }

    componentWillMount() {
        if (QueryString.get('validationId')) {
            this.props.dispatch(ValidationActions.show(QueryString.get('validationId')));
        }
        if (!this.state.categories) {
            this.props.dispatch(CategoryActions.list());
        }
    }

    render() {
        return(
            <div className={"item-form-container"}>
                <FormHeader title={this.state.validation.id ? this.state.validation.name : Intl("Nuovo controllo")} redirectTo={"/validations"}/>
                <form onSubmit={this.sendForm}>
                    <div className={"row form-group"}>
                        <div className={"col-6"}>
                            <Input type={"text"} name={"name"} value={this.state.validation.name} label={ Intl('Nome controllo') }
                                   error={this.state.error} onChange={this.handleForm}/>
                        </div>
                        <div className={"col-6"}>
                            <label>{ Intl('Periodo di controllo') }</label>
                            <div className={"input-group input-group-sm"}>
                                <div className={"input-group-prepend"}>
                                    <span className={"input-group-text"}>
                                        <i className={"fal fa-clock"}/>
                                    </span>
                                </div>
                                <input type={"number"} className={"form-control"} placeholder={"Anni"} name={"years"} value={this.state.validation.years} onChange={this.handlePeriod}/>
                                <input type={"number"} className={"form-control"} placeholder={"Mesi"} name={"months"} value={this.state.validation.months} onChange={this.handlePeriod}/>
                                { this.state.error && this.state.error['check_period'] ? <p className={"error"}>{this.state.error['check_period']}</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className={"col-4"}>
                            <label>{ Intl('Azione necessaria') }</label>
                            <select className={"form-control custom-select"} name={"action_required"} value={this.state.validation.action_required} onChange={this.handleForm}>
                                <option>{ Intl('Seleziona') }</option>
                                <option value={"maintenance"} key={'maintenance'}>{ Intl('Mantenimento') }</option>
                                <option value={"replacement"} key={'replacement'}>{ Intl('Sostituzione') }</option>
                            </select>
                        </div>
                        <div className="col-8">
                            <label>{ Intl('Categorie riguardanti') }</label>
                            <Select
                                options={this.state.categories}
                                value={this.state.validation.categories}
                                isSearchable={true}
                                isMulti={true}
                                getOptionLabel={option => <span><img src={"/img/items/icons/" + option.image} height={22}/> <span className={"pt-5"}>{option.name}</span></span>}
                                getOptionValue={option => option.id}
                                getOptionsIcon={option => '/img/items/icons/' + option.image}
                                onChange={(categories) => {
                                    this.handleMultipleSelect(categories, 'categories')
                                }}
                            />
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <label>{ Intl('Descrizione')}</label>
                        <Input type={"textarea"} name={"description"} className={"form-control"} rows={5} value={this.state.validation.description} onChange={this.handleForm}/>
                    </div>
                    <div className={"form-group"}>
                        <a href={"/validations"} className={"btn btn-secondary btn-flat"}>
                            <i className={"fal fa-arrow-left mr-2"}/>
                            { Intl("Annulla") }
                        </a>
                        <button className={"btn btn-primary btn-flat float-right"}>
                            <i className={"fal fa-check mr-2"}/>
                            { Intl("Salva") }
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    handlePeriod(e) {
        const { name, value } = e.target;
        this.setState({
            validation: {
                ...this.state.validation,
                [name]: value
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.error) {
            if (nextProps.error instanceof Promise) {
                nextProps.error.then(
                    errorMessages => {
                        this.setState({
                            error: errorMessages
                        })
                    }
                )
            } else {
                this.setState({
                    error: nextProps.error
                })
            }
        }

        if (nextProps.categories) {
            this.setState({
                categories: nextProps.categories
            })
        }

        if (nextProps.validation) {
            const validation = nextProps.validation;
            const years = Math.floor(validation.check_period / 12);
            const months = validation.check_period%12;
            validation.years = years ? years : "";
            validation.months = months ? months : "";
            this.setState({
                validation: validation
            })
        }

        if (typeof nextProps.success !== "undefined") {
            this.props.dispatch(ValidationActions.list());
        }
    }
}

function mapStateToProps(state) {
    const { validation, error, success } = state.Validation;
    const { categories } = state.Category;

    return {
        validation,
        error,
        success,
        categories
    }
}

export default connect(mapStateToProps)(ValidationsStore);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {UserActions} from "../../actions/UserActions";
import {Link} from "react-router-dom";
import {OperationActions} from "../../actions/OperationActions";
import moment from "moment";
import Intl from "../../helpers/Intl";
import {QueryString} from "../../helpers/QueryString";

class ResourcesMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: []
        }

        this.getUserEvents = this.getUserEvents.bind(this);

        this.functions = {
            getUserEvents: this.getUserEvents
        }
    }

    componentWillMount() {
        this.props.dispatch(UserActions.list());
        this.props.dispatch(OperationActions.events({userId: QueryString.get('userId')}));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            this.setState({
                users: nextProps.users
            })
        }
    }

    getUserEvents(userId) {
        console.log(userId)
        this.props.dispatch(OperationActions.events({userId}))
    }

    render() {
        return (
            <div className={"users-menu"}>
                <div className={"users-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <Link to={"/operations/add"} className={"btn btn-primary btn-sm"} onClick={this.newUser}>
                                <i className={"far fa-plus mr-2"}/>
                                { Intl('Nuovo') }
                            </Link>
                        </div>
                        <div className={"col-5"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type={"text"} className={"form-control form-control-sm"}
                                           placeholder={Intl("Cerca tra gli utenti")}/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-4 text-right"}>
                            <Link to={"/operations"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-map-marker-alt fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                            <Link to={"/dashboard"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Operators") }</h4>
                        </div>
                    </div>
                </div>
                <UsersList users={this.state.users} profile={this.props.profile} functions={this.functions}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users } = state.User;
    const { profile } = state.Auth;
    return {
        users,
        profile
    }
}

export default connect(mapStateToProps)(ResourcesMenu);

export class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        }

        this.renderUsers = this.renderUsers.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.users) {
            this.setState({
                users: nextProps.users
            })
        }
    }

    renderRoles(user) {
        const roles = [];
        if (user) {
            user.roles.map((role) => {
                roles.push(<small key={user.id + role.id} className={"badge badge-primary py-1 px-2 mr-2"}>{ Intl(role.name) }</small>);
            });
        }
        return roles;
    }

    renderUsers() {
        const users = [];
        const selectedId = QueryString.get('userId');
        this.state.users.map((user) => {
            users.push(
                <div className={"user-item " + (user.id === selectedId ? 'active' : '')} key={user.id}>
                    <h5>
                        <i className={"fas fa-square mr-2"} style={{ color: user.color }}/>
                        {user.fullName} { this.props.profile && user.id === this.props.profile.id ? <small>({Intl("Tu")})</small> : null }
                    </h5>
                    <Link to={"/operations/calendar?userId=" + user.id} className={"float-right"} onClick={(e) => {
                        this.props.functions.getUserEvents(user.id)
                    }}>
                        <i className={"far fa-arrow-right"}/>
                    </Link>
                    <Link to={"/users/edit?userId=" + user.id} className={"float-right mr-2"}>
                        <i className={"far fa-pencil-alt"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {this.renderRoles(user)}
                        </div>
                    </div>
                    <div className={"user-details"}>
                        <span className="mr-4">
                            <i className={'fal fa-mobile-android-alt mr-2'}/>
                            <small>{user.mobile}</small>
                        </span>
                        <span className="mr-4">
                            <i className={'fal fa-envelope mr-2'}/>
                            <small>{user.email}</small>
                        </span>
                    </div>
                </div>
            );
        })

        return users;
    }

    render() {
        return (
            <div className="users-menu-wrapper users-list">
                { this.renderUsers() }
            </div>
        );
    }
}
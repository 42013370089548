import React, { Component } from 'react';
import { connect } from 'react-redux';

class FloorItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirm: false
        }
    }

    render() {
        return (
            <li className={"list-group-item"}>
                <div className={"row"}>
                    <div className={"col-1"}>
                        { this.props.floor.electronic_forbidden ?
                            <span className={"fa-stack"}>
                            <i className={"fas fa-circle fa-stack-2x text-danger"}/>
                            <i className={"fas fa-signal-slash fa-stack-1x fa-inverse"}/>
                        </span>
                            :
                            <span className={"fa-stack"}>
                            <i className={"fas fa-circle fa-stack-2x text-success"}/>
                            <i className={"fas fa-signal fa-stack-1x fa-inverse"}/>
                        </span>
                        }
                    </div>
                    <div className={"col-6"}>
                        <span>{this.props.floor.name}</span>
                    </div>
                    <div className={"col-5 text-right"}>
                        <a href={"/floor/configure?floorId=" + this.props.floor.id} className={"mr-2"} title={"Configura"}>
                            <i className={"far fa-cog"}/>
                        </a>
                        <a href={"#"} className={"mr-2"} onClick={
                            (e) => {
                                e.preventDefault();
                                this.props.functions.toggleFloorForm(e);
                                this.props.functions.handleFloorId(this.props.floor.id)
                                if (this.props.functions.handleLocationId) {
                                    this.props.functions.handleLocationId(this.props.floor.location_id)
                                }
                            }
                        }>
                            <i className={"far fa-pencil-alt"}/>
                        </a>
                        <a href={"#"} className={"mr-2"} onClick={() => {
                            this.setState({
                                showDeleteConfirm: !this.state.showDeleteConfirm
                            })
                        }}>
                            <i className={"far fa-trash-alt"}/>
                        </a>
                    </div>
                    <div className={"col-12"}>
                        {this.state.showDeleteConfirm  ?
                            <div className={"delete-confirm mt-1"}>
                                <span className={"mr-3"}>Do you want to delete this floor?</span>
                                <button className={"btn btn-secondary btn-sm"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirm: false
                                    })
                                }}>No
                                </button>
                                <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                                    this.props.functions.removeFloor(this.props.floor.id)
                                }}>Yes</button>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </li>
        );
    }
}

function mapStateToProps(state) {
    const { deleted } = state.Floor;
    return {
        deleted
    }
}

export default connect(mapStateToProps)(FloorItem);
import {en} from "../lang/en";

export default function Intl(original) {
    let language = localStorage.getItem('language');
    if (!language) {
        language = "en";
    }

    const availableLanguages = {
        en: en
    };

    if (availableLanguages[language] && availableLanguages[language][original]) {
        return availableLanguages[language][original];
    }

    return original;
}
import {RoleService} from "../services/RoleService";

export const RoleActions = {
    list,
    item
}

function list() {
    return dispatch => {
        dispatch(request());
        RoleService.list()
            .then(
                roles => {
                    dispatch(success(roles));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "ROLES_LIST_REQUEST"} };
    function success(roles) { return {type: "ROLES_LIST_SUCCESS", roles } };
    function failure(error) { return {type: "ROLES_LIST_FAILURE", error } };
}

function item(id) {
    return dispatch => {
        dispatch(request());
        RoleService.item(id)
            .then(
                role => {
                    dispatch(success(role));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "ROLE_ITEM_REQUEST" }; }
    function success(role) { return { type: "ROLE_ITEM_SUCCESS", role }; }
    function failure(error) { return { type: "ROLE_ITEM_FAILURE", error }; }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FloorItem from "./FloorItem";
import {OperationActions} from "../../../actions/OperationActions";
import {FloorActions} from "../../../actions/FloorActions";
import Intl from "../../../helpers/Intl";

class LocationItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: this.props.info,
            showFloors: this.props.showFloors || false,
            edit: false,
            showDeleteConfirm: false
        };

        this.toggleFloors = this.toggleFloors.bind(this);
        this.renderLocation = this.renderLocation.bind(this);
        this.renderFloors = this.renderFloors.bind(this);
    }

    toggleFloors(e) {
        this.setState({
            showFloors: !this.state.showFloors
        })
    }

    renderFloors() {
        const floors = [];
        if (Array.isArray(this.state.info.floors)) {
            this.state.info.floors.map((floor, floorIndex) => {
                floors.push(<FloorItem key={floorIndex} floor={floor} functions={this.props.functions}/>)
            })
        }

        return floors;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.info) {
            this.setState({
                info: nextProps.info
            })
        }

        if (nextProps.deletedFloor) {
            this.props.dispatch(FloorActions.list(this.props.info.id))
        }

        if (nextProps.floors && nextProps.locationId) {
            if (nextProps.locationId === this.props.info.id) {
                this.setState({
                    info: {
                        ...this.state.info,
                        floors: nextProps.floors
                    }
                })
            }
        }

        if (nextProps.location) {
            if (nextProps.location.id === this.state.info.id) {
                this.setState({
                    info: nextProps.location
                })
            }
        }
    }

    renderLocation() {
        return (
            <div className={"row"}>
                <div className={"col-1"}>
                    { this.state.info.electronic_forbidden ?
                        <span className={"fa-stack"}>
                            <i className={"fas fa-circle fa-stack-2x text-danger"}/>
                            <i className={"fas fa-signal-slash fa-stack-1x fa-inverse"}/>
                        </span>
                        :
                        <span className={"fa-stack"}>
                            <i className={"fas fa-circle fa-stack-2x text-success"}/>
                            <i className={"fas fa-signal fa-stack-1x fa-inverse"}/>
                        </span>
                    }
                </div>
                <div className={"col-3"}>
                    <strong>{ this.state.info.name }</strong>
                </div>
                <div className={"col-5"}>
                    <strong>{this.state.info.address ? this.state.info.address.formatted_address : ''}</strong>
                </div>
                <div className={"col-3 text-right"}>
                    {this.state.info.status && this.state.info.status !== "ok" ?
                        <a href={"/operations"} className={"float-left mr-3"} onClick={() => {
                            this.props.dispatch(OperationActions.addLocationToPlanner(this.state.info))
                        }}>
                            <i className={"fal fa-tools"}/>
                        </a>
                        : ""
                    }
                    <a href={"#"} className={"float-left mr-3"} onClick={(e) => {
                        e.preventDefault();
                        this.props.functions.toggleLocationForm(e);
                        if (this.props.functions.handleLocationId) {
                            this.props.functions.handleLocationId(this.state.info.id);
                        }
                    }}>
                        <i className={"fal fa-pencil-alt"}/>
                    </a>
                    <a href={"#"} className={"float-left"} onClick={() => {
                        this.setState({
                            showDeleteConfirm: !this.state.showDeleteConfirm
                        })
                    }}>
                        <i className={"fal fa-trash-alt"}/>
                    </a>
                    <a href={"#"} className={"float-right"} onClick={this.toggleFloors}>
                        { this.state.showFloors ?
                            <i className={"far fa-chevron-up"}/>
                            :
                            <i className={"far fa-chevron-down"}/>
                        }
                    </a>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={"card mb-2"}>
                <div className={"card-header"}>
                    <div className={"row"}>
                        <div className={"col-3 offset-1"}>{ Intl("Nome sede") }</div>
                        <div className={"col-5"}>{ Intl("Indirizzo") }</div>
                    </div>
                    { this.renderLocation() }
                </div>
                <div className={"collapse" + (this.state.showFloors ? "show" : "")}>
                    <ul className={"list-group list-group-flush"}>
                        <li className={"list-group-item"}>
                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <span>{ Intl("Totale") }: <b>{ this.state.info.floors ? this.state.info.floors.length : 0 }</b> { Intl("planimetrie") }</span>
                                </div>
                                <div className={"col-4 offset-4 text-right"}>
                                    <button className={"btn btn-sm btn-primary"} onClick={
                                        (e) => {
                                            e.preventDefault();
                                            this.props.functions.toggleFloorForm();
                                            this.props.functions.handleFloorId(undefined);
                                            if (this.props.functions.handleLocationId) {
                                                this.props.functions.handleLocationId(this.state.info.id)
                                            }
                                        }
                                    }>
                                        <i className={"far fa-plus mr-2"}/>
                                        { Intl("Aggiungi planimetria") }
                                    </button>
                                </div>
                            </div>
                        </li>
                        { this.renderFloors() }
                    </ul>
                </div>
                {this.state.showDeleteConfirm  ?
                    <div className={"delete-confirm my-2 mx-2"}>
                        <span className={"mr-3"}>Do you want to delete this location?</span>
                        <button className={"btn btn-secondary btn-sm"} onClick={() => {
                            this.setState({
                                showDeleteConfirm: false
                            })
                        }}>No
                        </button>
                        <button className={"btn btn-danger btn-sm ml-2"} onClick={() => {
                            this.props.functions.removeLocation(this.props.info.id)
                        }}>Yes</button>
                    </div>
                    : null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { floors, locationId, deletedFloor } = state.Floor;
    const { location } = state.Location;

    return {
        location,
        floors,
        locationId,
        deletedFloor
    }
}

export default connect(mapStateToProps)(LocationItem);
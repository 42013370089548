import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import Intl from "../../helpers/Intl";
import {CustomerActions} from "../../actions/CustomerActions";
import {QueryString} from "../../helpers/QueryString";

class FinanceMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            selectedCustomer: null
        }
    }

    componentWillMount() {
        this.props.dispatch(CustomerActions.list());
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        console.log(nextState)
        const customerId = QueryString.get('customerId')
        if (customerId && this.state.selectedCustomer !== customerId) {
            this.setState({
                selectedCustomer: customerId
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customers) {
            this.setState({
                customers: nextProps.customers
            })
        }
    }

    render() {
        return(
            <div className="customers-menu">
                <div className={"customers-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <h4>Finance</h4>
                        </div>
                        <div className={"col-6"}>
                            <Link to={'/finance'} className={"btn btn-sm btn-primary"} onClick={() => {
                                this.setState({
                                    selectedCustomer: null
                                })
                            }}>
                                <i className={'fal fa-eraser mr-1'}/>
                                { Intl('Rimuovi selezione') }
                            </Link>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Clienti") }</h4>
                        </div>
                    </div>
                </div>
                <CustomerItems customers={this.state.customers} selectedCustomer={this.state.selectedCustomer}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { customers } = state.Customer;
    return {
        customers
    }
}

export default connect(mapStateToProps)(FinanceMenu);

export class CustomerItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: this.props.customers,
            openDeleteConfirm: false,
            selectedCustomer: this.props.selectedCustomer || null
        }

        this.renderCustomers = this.renderCustomers.bind(this);
    }

    renderCustomers() {
        const customers = [];
        this.props.customers.map((customer, index) => {
            customers.push(
                <div className={"clearfix customer-item" + (this.state.selectedCustomer && customer.id === this.state.selectedCustomer ? ' active' : '')} key={index}>
                    <h5>{ customer.name }</h5>
                    <Link to={"/finance?customerId=" + customer.id} className={"float-right"} onClick={() => {
                        this.setState({
                            selectedCustomer: customer.id
                        })
                    }}>
                        <i className={"fal fa-arrow-right"}/>
                    </Link>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <small>{ customer.address ? customer.address.formatted_address : '' }</small>
                        </div>
                    </div>
                    <div className={"customer-details"}>
                        <span className={"mr-4"}>
                            <i className={"fal fa-building mr-2"}/>
                            <small>{ customer.locations.length }</small>
                        </span>
                        <span className={"mr-2"}>
                            <i className={"fal fa-bell mr-2"}/>
                            <small>{ customer.alarms.length }</small>
                        </span>
                    </div>
                </div>
            );
        })

        return customers;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (typeof nextProps.selectedCustomer !== "undefined") {
            this.setState({
                selectedCustomer: nextProps.selectedCustomer
            })
        }
    }

    render() {
        return (
            <div className={"customers-menu-wrapper"}>
                { this.renderCustomers() }
            </div>
        );
    }
}
import {FloorService} from "../services/FloorService";

export const FloorActions = {
    list,
    show,
    save,
    remove
};

function list(locationId) {
    return dispatch => {
        dispatch(request(locationId));
        FloorService.list(locationId)
            .then(
                floors => {
                    dispatch(success(floors, locationId));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(locationId) { return { type: "FLOORS_LIST_REQUEST", locationId }; }
    function success(floors, locationId) { return { type: "FLOORS_LIST_SUCCESS", data: {locationId: locationId, floors: floors} }; }
    function failure(error) { return { type: "FLOORS_LIST_FAILURE", error }; }
}

function show(floorId) {
    return dispatch => {
        dispatch(request(floorId));
        FloorService.item(floorId)
            .then(
                floorItem => {
                    dispatch(success(floorItem));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(floorId) { return { type: "FLOOR_ITEM_REQUEST", floorId }; }
    function success(floorItem) { return { type: "FLOOR_ITEM_SUCCESS", floorItem }; }
    function failure(error) { return { type: "FLOOR_ITEM_FAILURE", error }; }
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        FloorService.save(data)
            .then(
                floor => {
                    dispatch(success(floor));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    };

    function request(data) { return { type: "FLOOR_SAVE_REQUEST", data }; }
    function success(floor) { return { type: "FLOOR_SAVE_SUCCESS", floor }; }
    function failure(error) { return { type: "FLOOR_SAVE_FAILURE", error }; }
}

function remove(floorId) {
    return dispatch => {
        dispatch(request())
        FloorService.remove(floorId)
            .then(
                deleted => {
                    dispatch(success(floorId))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }

    function request(data) { return { type: "FLOOR_REMOVE_REQUEST", data }; }
    function success(floorId) { return { type: "FLOOR_REMOVE_SUCCESS", floorId }; }
    function failure(error) { return { type: "FLOOR_REMOVE_FAILURE", error }; }
}
import {OperationActions} from "../actions/OperationActions";
import Intl from "../helpers/Intl";
import {history} from "../helpers/History";
import {AppActions} from "../actions/AppActions";

export const GMaps = {
    init,
    createMarkers,
    createItinerary,
    removeItinerary,
    createPlanningOverview
};
var map;
var myLatLng;
var currentPosition;
var directionsService;
var directionsDisplay;

const mapStyle = new window.google.maps.StyledMapType([
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ],
    {name: 'Grey Map'});

function userCurrentPosition() {
    let currentPosition = null; //new window.google.maps.LatLng(41.9082901, 12.4658853);
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(setCurrentPosition)
    }

    return currentPosition;
}

function setCurrentPosition(position) {
    currentPosition = new window.google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
    );
}

function getCurrentPosition() {
    if (!currentPosition) {
        userCurrentPosition();
    }
    return currentPosition;
}

function init() {
    // if (!map) {
        var position = getCurrentPosition();

        const myOptions = {
            zoom: 10,
            center: position,
            mapTypeControl: false,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'styled_map']
            },
            streetViewControl: false,
        };

        map = new window.google.maps.Map(document.getElementById("map"), myOptions);
        map.mapTypes.set('styled_map', mapStyle);
        map.setMapTypeId('styled_map');

    // }
}

function createBounds(locations) {
    var bounds = new window.google.maps.LatLngBounds();
    userCurrentPosition();

    bounds.extend(currentPosition);

    locations.map((location) => {
        const position = {
            lat: Number(location.lat),
            lng: Number(location.lng)
        };

        bounds.extend(position);
    });

    map.fitBounds(bounds);
}
var markersArray = [];
function clearOverlays() {
    markersArray.map((marker) => {
        marker.setMap(null);
    })
    markersArray.length = 0;
}

function createMarkers(locations, profile, dispatch) {
    clearOverlays();
    userCurrentPosition();

    if (!currentPosition && profile) {
        currentPosition = profile.address.geometry.location;
    }

    new window.google.maps.Marker({
        position: currentPosition,
        map: map
    })

    locations.map((location) => {
        const infoWindow = createInfoWindow(location, dispatch);
        const icon = "/img/markers/marker-" + location.status + ".png";
        const position = {
            lat: Number(location.lat),
            lng: Number(location.lng)
        };
        // bounds.extend(position);
        const marker = new window.google.maps.Marker({
            position: position,
            map: map,
            icon: icon
        });

        markersArray.push(marker);

        marker.addListener('click', function() {
            infoWindow.open(map, marker);
        });
    });

    createBounds(locations)
}

function createInfoWindow(location, dispatch) {
    const operationHours = Math.floor(location.operationEstimatedTime/60);
    const operationMinutes = Math.floor(location.operationEstimatedTime%60);

    let content = '<div class="mb-3">' +
        '<h5 class="mb-2">' + location.customer.name + '</h5>' +
        '<h6>' + location.name + '</h6>' +
        '<span>' + location.address.formatted_address + '</span>' +
        '<div class="mt-2"><strong>Operation Time:</strong> ' + operationHours + ' hours ' + operationMinutes + ' minutes</div>' +
        '</div>';
    const calendarButton = document.createElement('button');
    const statusButton = location.status === 'scheduled' ? 'btn-danger' : 'btn-success'
    calendarButton.classList.add('btn', statusButton, 'btn-sm', 'mr-3');
    calendarButton.onclick = () => {
        if (location.status === 'scheduled') {
            dispatch(OperationActions.removeByLocation(location));
            dispatch(OperationActions.removeByLocation(location));
        } else {
            dispatch(OperationActions.addLocationToPlanner(location));
        }
        popup.close();
    };
    calendarButton.innerText = location.status === 'scheduled' ? Intl('Annulla intervento') : Intl('Pianifica intervento')

    const modifyButton = document.createElement('button');
    modifyButton.classList.add('btn', 'btn-primary', 'float-right', 'btn-sm');
    modifyButton.onclick = () => {
        // dispatch(AppActions.toggleLocationForm(location.id))
        history.push('/locations/edit?locationId=' + location.id)
    };
    modifyButton.innerText = Intl('Modifica')

    const htmlNode = document.createElement('div');
    htmlNode.innerHTML = content;
    if (location.status !== 'ok') {
        htmlNode.appendChild(calendarButton);
    }
    htmlNode.appendChild(modifyButton);

    const popup = new window.google.maps.InfoWindow({
        content: htmlNode
    });

    return popup;
}

function removeItinerary() {
    if (directionsDisplay) {
        directionsDisplay.setMap(null);
        directionsDisplay = null;
    }
    if (directionsService) {
        directionsService = null;
    }

    Object.keys(directionsDisplayInstances).map((instanceId) => {
        directionsDisplayInstances[instanceId].setMap(null);
    });
}

var directionsDisplayInstances = {};
var userMarker;
function createItinerary(user, locations, dispatch = null) {
    var position;
    if (userMarker) {
        userMarker.setMap(null);
    }
    if (!user) {
        position = getCurrentPosition()
    } else {
        position = user.address.geometry.location;
        userMarker = new window.google.maps.Marker({
            position: position,
            map: map,
            title: user.fullName
        })
    }
    console.log(locations);
    if (!locations || !locations.length) {
        return;
    }

    if (!directionsDisplay) {
        directionsDisplay = new window.google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        directionsDisplay.setMap(null);
    }
    if (!directionsService) {
        directionsService = new window.google.maps.DirectionsService();
    }

    directionsDisplay.setMap(map);

    const waypoints = [];
    locations.map((location) => {
        console.log(location);
        waypoints.push({location: { placeId: location.address.place_id}, stopover: true})
    });

    const request = {
        origin: position,
        destination: position,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: "DRIVING"
    }

    directionsService.route(request, (result, status) => {
        if (status === 'OK') {
            console.log('RESULT', result)
            if (dispatch) {
                dispatch(OperationActions.routes(result));
            }
            if (directionsDisplay) {
                directionsDisplay.setDirections(result);
            }
        }
    });
}

function createDirectionsDisplayInstance(user) {
    if (!directionsDisplayInstances[user.id]) {
        const instance = new window.google.maps.DirectionsRenderer({
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: user.color,
                strokeOpacity: 0.5,
                strokeWeight: 6
            }
        });
        instance.setMap(map);
        directionsDisplayInstances[user.id] = instance;
    }
}

function createPlanningOverview(events) {
    Object.keys(directionsDisplayInstances).map((instanceId) => {
        directionsDisplayInstances[instanceId].setMap(null);
    });
    directionsDisplayInstances = [];
    if (!events || !events.length) {
        return false;
    }
    const userLocations = {};
    const users = {};
    events.map((event) => {
        const position = event.user.address.geometry.location;
        const userInfo = new window.google.maps.InfoWindow({
            content: event.user.fullName
        });
        const userMarker = new window.google.maps.Marker({
            position: position,
            map: map
        });

        userMarker.addListener('click', function() {
            userInfo.open(map, userMarker);
        });

        window.google.maps.event.trigger(userMarker, 'click');

        if (!directionsDisplayInstances[event.user.id]) {
            createDirectionsDisplayInstance(event.user);
        }
        if (!userLocations[event.user.id]) {
            userLocations[event.user.id] = []
        }

        if (!users[event.user.id]) {
            users[event.user.id] = event.user
        }

        userLocations[event.user.id].push({ location: { placeId: event.location.address.place_id}});
    })

    directionsService = new window.google.maps.DirectionsService();

    Object.keys(directionsDisplayInstances).map((userId) => {
        const request = {
            origin: users[userId].address.geometry.location,
            destination: users[userId].address.geometry.location,
            waypoints: userLocations[userId],
            optimizeWaypoints: true,
            travelMode: "DRIVING"
        }
        directionsService.route(request, (result, status) => {
            if (status == 'OK') {
                // if (dispatch) {
                //     dispatch(OperationActions.routes(result));
                // }
                if (directionsDisplayInstances[userId]) {
                    directionsDisplayInstances[userId].setDirections(result);
                }
            }
        })
    });

}
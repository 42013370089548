import React, { Component } from 'react';
import InputGroup from "./InputGroup";

class InputWrapper extends Component {
    render() {
        return(
            <div className="form-group">
                { (!this.props.prepend && !this.props.append) ?
                    this.props.children :
                    <InputGroup
                        prepend={ this.props.prepend }
                        append={ this.props.append }
                        prependText={ this.props.prependText }
                        appendText={ this.props.appendText }
                        prependIconKey={ this.props.prependIconKey }
                        appendIconKey={ this.props.appendIconKey }
                    >
                        { this.props.children }
                    </InputGroup>
                }
            </div>
        );
    }
}

export default InputWrapper;
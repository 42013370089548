import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Input from "../../components/Input/Input";
import Intl from "../../helpers/Intl";
import FormAbstract from "../../abstract/FormAbstract";
import { connect } from 'react-redux';

class DocumentsMenu extends FormAbstract {
    constructor(props) {
        super(props);
        this.model = 'document'

        this.state = {
            document: null
        }
    }

    render() {
        return(
            <div className="customers-menu">
                <div className={"customers-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-2 offset-10"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"module-menu-header"}>
                    <div className="row">
                        <div className={"col-12"}>
                            <h4>{ Intl("Documenti") }</h4>
                        </div>
                    </div>
                </div>
                <div className={"document-form p-2"}>
                    <form onSubmit={this.sendForm}>
                        <div className={"form-group"}>
                            <Input type={"file"} name={"file"} onChange={this.handleForm}/>
                        </div>
                        <div className={"form-group"}>
                            <Input type={"text"} name={"name"} label={"Name"} onChange={this.handleForm}/>
                        </div>
                        <button type={"submit"} className={"btn btn-primary btn-block"}>
                            <i className={"fal fa-upload mr-2"}/>
                            { Intl("Upload") }
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(DocumentsMenu);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ItemActions} from "../../actions/ItemActions";
import "./styles.scss";
import {Link} from "react-router-dom";
import {ItemIcon} from "../../helpers/ItemIcon";
import ItemsList from "./components/ItemsList";
import SubitemsList from "./components/SubitemsList";
import {SubitemActions} from "../../actions/SubitemActions";
import Intl from "../../helpers/Intl";
import { history } from '../../helpers/History';

class ItemsMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            subitems: [],
            tab: this.props.tab || "items"
        };

        this.editItem = this.editItem.bind(this);
        this.newItem = this.newItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.switchTab = this.switchTab.bind(this);

        this.functions = {
            editItem: this.editItem,
            deleteItem: this.deleteItem
        }
    }

    componentWillMount() {
        this.props.dispatch(ItemActions.list())
        this.props.dispatch(SubitemActions.list())
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(nextProps);
        if (nextProps.items) {
            this.setState({
                items: nextProps.items
            })
        }

        if (nextProps.subitems) {
            this.setState({
                subitems: nextProps.subitems
            })
        }

        if (nextProps.removedItem) {
            this.props.dispatch(ItemActions.list())
            history.push('/items')
        }

        if (nextProps.removedSubitem) {
            history.push('/subitems')
        }
    }

    deleteItem(itemId) {
        console.log(this.state.tab)
        switch(this.state.tab) {
            case "items":
                return this.props.dispatch(ItemActions.remove(itemId))
            case "subitems":
                return this.props.dispatch(SubitemActions.remove(itemId))
            default:
                return null;
        }
    }

    editItem(itemId) {
        switch(this.state.tab) {
            case "items":
                return this.props.dispatch(ItemActions.show(itemId))
            case "subitems":
                return this.props.dispatch(SubitemActions.show(itemId))
            default:
                return null;
        }
    }

    newItem() {
        switch(this.state.tab) {
            case "items":
                this.props.dispatch(ItemActions.empty());
            case "subitems":
                this.props.dispatch(SubitemActions.empty());
            default:
                return null;
        }
    }

    switchTab(tab) {
        this.setState({
            tab: tab
        })
        switch(tab) {
            case "items":
                this.props.dispatch(ItemActions.list());
            case "subitems":
                this.props.dispatch(SubitemActions.list());
            default:
                return null;
        }
    }

    render() {
        console.log(this.state.tab)
        return (
            <div class="items-menu">
                <div className={"items-menu-actions"}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <Link to={"/" + this.state.tab + "/add"} className={"btn btn-primary btn-sm"} onClick={this.newItem}>
                                <i className={"far fa-plus mr-2"}/>
                                { Intl("Nuovo") }
                            </Link>
                        </div>
                        <div className={"col-6"}>
                            <form>
                                <div className={"form-group"}>
                                    <input type={"text"} className={"form-control form-control-sm"} placeholder={Intl("Cerca tra gli apparati")}/>
                                </div>
                            </form>
                        </div>
                        <div className={"col-2"}>
                            <Link to={"/dashboard"} className={"float-right"}>
                                <span className="fa-stack">
                                    <i className={"fas fa-circle fa-stack-2x"}/>
                                    <i className={"fal fa-arrow-left fa-stack-1x fa-inverse"}/>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={"tab-container"}>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <Link className={"nav-link " + (this.state.tab === "items" ? "active" : "")} to="/items" onClick={(e) => {
                                this.switchTab("items")
                            }}>{Intl("Apparati")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={"nav-link " + (this.state.tab === "subitems" ? "active" : "")} to="/subitems" onClick={(e) => {
                                this.switchTab("subitems")
                            }}>{Intl("Parti")}</Link>
                        </li>
                    </ul>
                    <div className={"tab-content"}>
                        { this.state.tab === "items" ? <ItemsList items={this.state.items} functions={this.functions}/> : "" }
                        { this.state.tab === "subitems" ? <SubitemsList subitems={this.state.subitems} functions={this.functions}/> : "" }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { removedItem, items } = state.Item;
    const { removedSubitem, subitems } = state.Subitem;
    return {
        removedItem,
        removedSubitem,
        subitems,
        items
    }
}

export default connect(mapStateToProps)(ItemsMenu);
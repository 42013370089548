export const ValueConverter = {
    toYearsAndMonths,
    toHoursAndMinutes,
    percentage,
    generateColorCode
}

function toYearsAndMonths(value, verbose = false) {
    let years = Math.floor(value/12);
    let months = value%12;

    if (verbose) {
        let verboseString = "";
        if (years) {
            verboseString += years + " years ";
        }

        if (months) {
            verboseString += months + " months";
        }

        return verboseString;
    }

    return years + "." + months;
}

function toHoursAndMinutes(value, verbose = false) {
    let hours = Math.floor(value/60);
    let minutes = value%60;

    if (verbose) {
        let verboseString = "";
        if (hours) {
            verboseString += hours + "h ";
        }

        if (minutes) {
            verboseString += minutes + "m";
        }

        return verboseString;
    }

    return hours + "." + minutes;
}

function percentage(value, total) {
    return (value/total)*100;
}

function generateColorCode() {
    var letters = '89ABC';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 5)];
    }
    return color;
}
import {ValidationService} from "../services/ValidationService";
import {SubitemService} from "../services/SubitemService";
import {ItemService} from "../services/ItemService";

export const ValidationActions = {
    empty,
    list,
    show,
    save,
    remove
};

function empty() {
    const validation = {
        name: "",
        description: "",
        check_period: "",
        active: false
    }

    return dispatch => {
        dispatch(success(validation));
    }

    function success(validation) { return {type: "VALIDATION_NEW", validation } };
}

function list() {
    return dispatch => {
        dispatch(request());
        ValidationService.list()
            .then(
                validations => {
                    dispatch(success(validations));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "VALIDATIONS_LIST_REQUEST"} };
    function success(validations) { return {type: "VALIDATIONS_LIST_SUCCESS", validations } };
    function failure(error) { return {type: "VALIDATIONS_LIST_FAILURE", error } };
}

function show(id) {
    return dispatch => {
        dispatch(request());
        ValidationService.show(id)
            .then(
                validation => {
                    dispatch(success(validation));
                },
                error => {
                    error.then(
                        messages => {
                            dispatch(failure(messages));
                        }
                    )
                }
            );
    }

    function request() { return { type: "VALIDATION_ITEM_REQUEST"} };
    function success(validation) { return {type: "VALIDATION_ITEM_SUCCESS", validation } };
    function failure(error) { return {type: "VALIDATION_ITEM_FAILURE", error } };
}

function save(data) {
    return dispatch => {
        dispatch(request(data));
        ValidationService.save(data)
            .then(
                validation => {
                    dispatch(success(validation));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }


    function request(data) { return { type: "VALIDATION_SAVE_REQUEST", data} };
    function success(validation) { return {type: "VALIDATION_SAVE_SUCCESS", validation } };
    function failure(error) { return {type: "VALIDATION_SAVE_FAILURE", error } };
}

function remove(id) {
    return dispatch => {
        dispatch(request());
        ValidationService.remove(id)
            .then(
                validation => {
                    dispatch(success(validation));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    }

    function request() { return { type: "VALIDATION_REMOVE_REQUEST"} };
    function success(validation) { return {type: "VALIDATION_REMOVE_SUCCESS", validation } };
    function failure(error) { return {type: "VALIDATION_REMOVE_FAILURE", error } };
}
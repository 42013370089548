import { history } from '../helpers/History';
export function ErrorHandler(response) {
    switch (response.status) {
        case 200:
            return response.json();
        case 201:
            return response.json();
        case 400:
            return Promise.reject(response.json());
        case 401:
            localStorage.removeItem('auth');
            history.push('/');
            return Promise.reject(response.json());
        case 403:
            return {
                error: response
            };
        case 404:
            return Promise.reject(response.json());
        case 500:
            return Promise.reject(response.json());
        default:
            return Promise.reject(response.json());
    }
}